import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import { IStripe } from "../../models/request-stripe";
import { environment } from "src/environments/environment";
import { NumberTypeEnum } from "../../ngGtcPayflow/enums/number-type.enum";
import { UtilClass } from "../../ngGtcPayflow/classes/Util.class";

@Injectable({
  providedIn: "root",
})
export class CheckoutStripeService {
  constructor(private httpService: HttpClient) {}

  requestPayment(request: IStripe): Observable<any> {
    const url =
      environment.ngGtcPayStripe.apiUrl + this.getUrlParameter() + "/";
    const httpHeaders: HttpHeaders = this.getRequestHeaders();
    return this.httpService
      .post(url, request, { headers: httpHeaders, responseType: "text" })
      .catch((error) => {
        return Observable.throw(error);
      });
  }

  private getRequestHeaders(): HttpHeaders {
    return new HttpHeaders({
      "Content-Type": "text/namevalue",
    });
  }
  private getUrlParameter(): string {
    try {
      const numberType: NumberTypeEnum = environment.ngGtcPayflow.numberType;
      return UtilClass.generateRandomNumberByType(numberType) + "";
    } catch (error) {
      console.error(error);
    }
  }
}
