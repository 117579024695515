// LOGS OPERADORES
export enum WebFlowAction {
  // Availability

    //Start Results Component
    SEARCH = 'SEARCH',
    PRINT_RESULTS = 'PRINT_RESULTS',
    //End Results Component

    //Start Main-Second Results Layout Component
    DETAILS = 'DETAILS',
    //End Main-Second Results Layout Component


    //Start Form Component
    INQUIRE_AVAILABILITY_START = 'INQUIRE_AVAILABILITY_START',
    INQUIRE_AVAILABILITY_SUCCESS = 'INQUIRE_AVAILABILITY_SUCCESS',
    INQUIRE_AVAILABILITY_FAILED = 'INQUIRE_AVAILABILITY_FAILED',


        // Usado para Royal
        CRM_INQUIRE_START = 'CRM_INQUIRE_START',
        CRM_INQUIRE_SUCCESS = 'CRM_INQUIRE_SUCCESS',
        CRM_INQUIRE_FAILED = 'CRM_INQUIRE_FAILED',
    //End Form Component

    //Start OnHold Component
      TRY_ADD_CABIN_ON_HOLD = 'TRY_ADD_CABIN_ON_HOLD',

      INIT_ON_HOLD = 'INIT_ON_HOLD',

      // Aqui deberia aplicar una normalizacion de nombres
      ON_HOLD_REQUEST_CODE_START = 'ON_HOLD_REQUEST_CODE_START',
      ON_HOLD_REQUEST_CODE_SUCCESS = 'ON_HOLD_REQUEST_CODE_SUCCESS',
      ON_HOLD_REQUEST_CODE_ERROR = 'ON_HOLD_REQUEST_CODE_ERROR',

      ON_HOLD_REQUEST_START = 'TRY_ADD_CABIN_ON_HOLD_START',
      ON_HOLD_REQUEST_SUCCESS = 'TRY_ADD_CABIN_ON_HOLD_SUCCESS',
      ON_HOLD_REQUEST_FAILED = 'TRY_ADD_CABIN_ON_HOLD_FAILED',
      ON_HOLD_REQUEST_ERROR = 'ON_HOLD_REQUEST_ERROR',

      //Otros , revisar data y si el nombre del log esta correcto
      ON_HOLD_DELETE_CABIN= 'ON_HOLD_DELETE_CABIN',
      ON_HOLD_PASSENGER_INFORMATION = 'ON_HOLD_PASSENGER_INFORMATION',
      ON_HOLD_SELECTED_CABIN = 'ON_HOLD_SELECTED_CABIN',
      ON_HOLD_ENTERED_CODE= 'ON_HOLD_ENTERED_CODE',

        CRM_ON_HOLD_START = 'CRM_ON_HOLD_START',
        CRM_ON_HOLD_SUCCESS ='CRM_ON_HOLD_SUCCESS',
        CRM_ON_HOLD_FAILED = 'CRM_ON_HOLD_FAILED',
        CRM_ON_HOLD_ERROR = 'CRM_ON_HOLD_ERROR',

    //End OnHold Component


  // Ecommerce

      //Start Checkout component

      ENTERED_ECOMMERCE_GALAVAIL = 'ENTERED_ECOMMERCE_GALAVAIL',

      PURCHASE = 'PURCHASE',

      PACKAGE_SELECTED_ = 'PACKAGE_SELECTED_',

      SELECTED_CABINS = 'SELECTED_CABINS',

      //End Checkout component


      //Start CheckoutSecond  component

      FILL_PASSENGER_FORM = 'FILL_PASSENGER_FORM',
      MAIN_CONTACT_PERSON_EMAIL = 'MAIN_CONTACT_PERSON_EMAIL',
      SEND_INQUIRE_START = 'SEND_INQUIRE_START',
      SEND_INQUIRE_SUCCESS = 'SEND_INQUIRE_SUCCESS',
      SEND_INQUIRE_FAILED = 'SEND_INQUIRE_FAILED',
      SEND_INQUIRE_ERROR = 'SEND_INQUIRE_ERROR',

      //End CheckoutSecond  component

      //Start CheckoutThird  component

      //podria ser reemplzado por SENDING_AUTOMATIC_LESS_72H_SUCCESS
      SENDING_AUTOMATIC_START = 'SENDING_AUTOMATIC_START',
      SENDING_AUTOMATIC_SUCCESS ='SENDING_AUTOMATIC_SUCCESS',
      SENDING_AUTOMATIC_FAILED ='SENDING_AUTOMATIC_FAILED',

      //la misma accion es usa da para mucho fines y va diferenciado unicamente por el contenido de message en la data , por ejemplo para pagos de stripe
      EXECUTING_PAYMENT = 'EXECUTING_PAYMENT',
      EXECUTING_PAYMENT_SUCCESS = 'EXECUTING_PAYMENT_SUCCESS',
      EXECUTING_PAYMENT_COMPLETED = 'EXECUTING_PAYMENT_COMPLETED',
      EXECUTING_PAYMENT_FAILED = 'EXECUTING_PAYMENT_FAILED',
      EXECUTING_PAYMENT_PAYPAL_ERROR = 'EXECUTING_PAYMENT_PAYPAL_ERROR',
      EXECUTING_PAYMENT_PAYPAL_CANCELED = 'EXECUTING_PAYMENT_PAYPAL_CANCELED',
      EXECUTING_PAYMENT_STRIPE_START = 'EXECUTING_PAYMENT_STRIPE_START',
      EXECUTING_PAYMENT_STRIPE_SUCCESS = 'EXECUTING_PAYMENT_STRIPE_SUCCESS',
      EXECUTING_PAYMENT_STRIPE_ERROR = 'EXECUTING_PAYMENT_STRIPE_ERROR',
      EXECUTING_PAYMENT_STRIPE_FAILED = 'EXECUTING_PAYMENT_STRIPE_FAILED',
      EXECUTING_PAYMENT_BLOCK_ERROR ='EXECUTING_PAYMENT_BLOCK_ERROR',

       SEND_CRM_REQUEST_START = 'SEND_CRM_REQUEST_START',
       SEND_CRM_REQUEST_FAILED = 'SEND_CRM_REQUEST_FAILED',
       SEND_CRM_REQUEST_SUCCESS = 'SEND_CRM_REQUEST_SUCCESS',

      CLICK_TERMS_CONDITIONS = 'CLICK_TERMS_CONDITIONS',


      SEND_CONFIRMATION_EMAIL='SEND_CONFIRMATION_EMAIL',

      // funciona actualmente como log de error
      ON_HOLD = 'ON_HOLD',

      //End CheckoutThird  component

      //Start PurchaseRequestService Component
      SEND_AUTOMATIC_EMAIL = 'SEND_AUTOMATIC_EMAIL',
      //End PurchaseRequestService Component

      //Start Checkout Fourth Component
      PRINT_RESERVATION_INVOICE = 'PRINT_RESERVATION_INVOICE',
      //End Checkout Fourth Component

      BOOKING_START = 'BOOKING_START',
      BOOKING_REQUEST_START = 'BOOKING_REQUEST_START',
      BOOKING_COMPLETED = 'BOOKING_COMPLETED',
      BOOKING_FAILED = 'BOOKING_FAILED',
  }
