<div id="breadits" style="text-align: left;">


    <div *ngIf="this.firstString !== undefined" [ngClass]="companyParamsService.getVisiblepago() ? 'width-slide-with-payment' : 'width-slide-without-payment'" [ngStyle]="{ 'border-bottom':  this.companyParamsService.getCompanyParams() == undefined ?('4px solid blue') : ('4px solid #'+this.companyParamsService.getMainColor()) }">
        <span (click)="onClick('')" [ngStyle]="{ 'background-color':  this.companyParamsService.getCompanyParams() == undefined ?('blue'): ('#'+this.companyParamsService.getMainColor()) }">
      1

    </span>
        <p>Tour Options</p>
    </div>
    <div *ngIf="this.firstString === undefined" [ngClass]="companyParamsService.getVisiblepago() ? 'width-slide-with-payment' : 'width-slide-without-payment'">
        <span>1</span>
        <p>Tour Options</p>
    </div>



    <div *ngIf="this.secondString !== undefined" [ngClass]="companyParamsService.getVisiblepago() ? 'width-slide-with-payment' : 'width-slide-without-payment'" [ngStyle]="{ 'border-bottom':  this.companyParamsService.getCompanyParams() == undefined ?('4px solid blue'): ('4px solid #'+this.companyParamsService.getMainColor()) }">
        <span [ngStyle]="{ 'background-color':  this.companyParamsService.getCompanyParams() == undefined ?('blue'): ('#'+this.companyParamsService.getMainColor()) }">
      2
    </span>
        <p>Passengers Info</p>
    </div>
    <div *ngIf="this.secondString === undefined" [ngClass]="companyParamsService.getVisiblepago() ? 'width-slide-with-payment' : 'width-slide-without-payment'">
        <span>2</span>
        <p>Passengers Info</p>
    </div>


    <div [ngClass]="companyParamsService.getVisiblepago() ? 'width-slide-with-payment' : 'width-slide-without-payment'" style="height: 46px;" *ngIf="companyParamsService.getVisiblepago()">

        <div *ngIf="this.thirdString !== undefined " [ngStyle]="{ 'border-bottom':  this.companyParamsService.getCompanyParams() == undefined ?('4px solid blue'): ('4px solid #'+this.companyParamsService.getMainColor()) }">
            <span [ngStyle]="{ 'background-color':  this.companyParamsService.getCompanyParams() == undefined ?('blue'): ('#'+this.companyParamsService.getMainColor()) }">
        3
      </span>
            <p>Payments options</p>
        </div>
        <div *ngIf="this.thirdString === undefined"><span>3</span>
            <p>Payments options</p>
        </div>
    </div>
    <div [ngClass]="companyParamsService.getVisiblepago() ? 'width-slide-with-payment' : 'width-slide-without-payment'" style="height: 46px;">
        <div *ngIf="this.fourthString !== undefined" [ngStyle]="{ 'border-bottom':  this.companyParamsService.getCompanyParams() == undefined ?('4px solid blue'): ('4px solid #'+this.companyParamsService.getMainColor()) }">
            <span [ngStyle]="{ 'background-color':  this.companyParamsService.getCompanyParams() == undefined ?('blue'): ('#'+this.companyParamsService.getMainColor()) }">
        {{getNumberlastPage()}}
      </span>
            <p>{{getTitlelastPage()}}</p>
        </div>
        <div *ngIf="this.fourthString === undefined"><span> {{getNumberlastPage()}}</span>
            <p>{{getTitlelastPage()}}</p>
        </div>
    </div>
</div>
<p>&nbsp;</p>
<hr>
<a href="javascript:returnOrigin()" *ngIf="this.numberPage==1 || this.numberPage==4" class="backstep">[Back to Search
  Results]</a>
<a href="javascript:void(0)" (click)="onClick()" *ngIf="this.numberPage!=1 &&  this.numberPage!=4 " class="backstep">[{{this.getNameOfBackLabel(this.numberPage)}}]</a>
<a onclick="goInquire()" target="_blank" class="assistance" style="cursor: pointer;" [ngStyle]="{ 'color':  this.companyParamsService.getCompanyParams() == undefined ?('black'): ('#'+this.companyParamsService.getSecundaryColor()) }">
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-envelope-fill" viewBox="0 0 16 16">
        <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555zM0 4.697v7.104l5.803-3.558L0 4.697zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757zm3.436-.586L16 11.801V4.697l-5.803 3.546z"/>
      </svg>
    <strong>Need assistance?</strong> Contact us</a>
<!--

-->