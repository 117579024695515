<!--
  <div class="line" style="border-bottom: 7px solid #546f7a;">
    <div class="main-wrap nav">
      <a href="https://www.empresa.com/" class="logo"></a>
      <div class="headtitle">
        <h1>Book your Galapagos Cruise</h1>
        <p><strong>Toll free:</strong> 1800 000 0000</p>
      </div>
    </div>
  </div>
-->


<div class="container ecomm-bck">
    <div class="main-wrap">
        <div class="ecomm-hd">
            <a href="results-loading.html" class="backstep">[Back to Search Results]</a>
            <img src="../../../../../assets/resource_loading/loading-logo.gif" width="118" height="40" alt="Galapagos" class="load-logo">
            <div class="load-head1">
                <div class="text w-100 loading-animate-light" style="height:20px; width:100%;"></div>
            </div><br>
            <div class="load-head2">
                <div class="text w-100 loading-animate-light" style="height:20px; width:100%;"></div>
            </div>
        </div>
        <aside class="pt1">
            <a href="#" target="_blank"><img src="../../../../../assets/resource_loading/loading-boat.gif" width="371" height="190" alt="galapagos-boat"></a>
        </aside>
        <div class="intro-ecomm load-intro">
            <div>
                <div class="text w-100 loading-animate-light" style="width:100%;"></div>
            </div>
            <div>
                <div class="text w-100 loading-animate-dark" style="width:100%; height: 100%;"></div>
            </div>
            <div>
                <div class="text w-100 loading-animate-dark" style="width:100%;"></div>
            </div>
        </div>
        <div id="calendar">
            <aside class="pt1">
                <h3>Dates &amp; Itinerary

                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-caret-right-fill" viewBox="0 0 16 16">
          <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"/>
        </svg>
                </h3>
            </aside>
            <div class="modules-ecomm">
                <div class="headmod">
                    <div class="loader-1">
                        <div class="text w-100 loading-animate-dark" style="width:100%; height: 100%;"></div>
                    </div>
                </div>
                <div class="mod-content active" style="    display: flex;
                flex-direction: column;">
                    <div class="loader-2">
                        <div class="text w-100 loading-animate-dark" style="width:100%; height: 100%;"></div>
                    </div>
                    
                    <div class="row-loader" style="width: 100%;">
                        <table class="tab-itinerary">
                            <tbody>
                                <tr>
                                    <td width="30" rowspan="2" class="iti-day">
                                        <div class="loader-3">
                                            <div class="text w-100 loading-animate-dark" style="width:100%; height: 100%;"></div>
                                        </div>
                                    </td>
                                    <td width="30" class="iti-time">
                                        <div class="loader-4">
                                            <div class="text w-100 loading-animate-light" style="width:100%; height: 100%;"></div>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="loader-5">
                                            <div class="text w-100 loading-animate-dark" style="width:100%; height: 100%;"></div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="iti-time">
                                        <div class="loader-4">
                                            <div class="text w-100 loading-animate-light" style="width:100%; height: 100%;"></div>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="loader-6">
                                            <div class="text w-100 loading-animate-dark" style="width:100%; height: 100%;"></div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td rowspan="2" class="iti-day">
                                        <div class="loader-3">
                                            <div class="text w-100 loading-animate-dark" style="width:100%; height: 100%;"></div>
                                        </div>
                                    </td>
                                    <td class="iti-time">
                                        <div class="loader-4">
                                            <div class="text w-100 loading-animate-light" style="width:100%; height: 100%;"></div>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="loader-5">
                                            <div class="text w-100 loading-animate-dark" style="width:100%; height: 100%;"></div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="iti-time">
                                        <div class="loader-4">
                                            <div class="text w-100 loading-animate-light" style="width:100%; height: 100%;"></div>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="loader-6">
                                            <div class="text w-100 loading-animate-dark" style="width:100%; height: 100%;"></div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td rowspan="2" class="iti-day">
                                        <div class="loader-3">
                                            <div class="text w-100 loading-animate-dark" style="width:100%; height: 100%;"></div>
                                        </div>
                                    </td>
                                    <td class="iti-time">
                                        <div class="loader-4">
                                            <div class="text w-100 loading-animate-light" style="width:100%; height: 100%;"></div>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="loader-5">
                                            <div class="text w-100 loading-animate-dark" style="width:100%; height: 100%;"></div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="iti-time">
                                        <div class="loader-4">
                                            <div class="text w-100 loading-animate-light" style="width:100%; height: 100%;"></div>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="loader-6">
                                            <div class="text w-100 loading-animate-dark" style="width:100%; height: 100%;"></div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td rowspan="2" class="iti-day">
                                        <div class="loader-3">
                                            <div class="text w-100 loading-animate-dark" style="width:100%; height: 100%;"></div>
                                        </div>
                                    </td>
                                    <td class="iti-time">
                                        <div class="loader-4">
                                            <div class="text w-100 loading-animate-light" style="width:100%; height: 100%;"></div>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="loader-5">
                                            <div class="text w-100 loading-animate-dark" style="width:100%; height: 100%;"></div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="iti-time">
                                        <div class="loader-4">
                                            <div class="text w-100 loading-animate-light" style="width:100%; height: 100%;"></div>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="loader-6">
                                            <div class="text w-100 loading-animate-dark" style="width:100%; height: 100%;"></div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td rowspan="2" class="iti-day">
                                        <div class="loader-3">
                                            <div class="text w-100 loading-animate-dark" style="width:100%; height: 100%;"></div>
                                        </div>
                                    </td>
                                    <td class="iti-time">
                                        <div class="loader-4">
                                            <div class="text w-100 loading-animate-light" style="width:100%; height: 100%;"></div>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="loader-5">
                                            <div class="text w-100 loading-animate-dark" style="width:100%; height: 100%;"></div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="iti-time">
                                        <div class="loader-4">
                                            <div class="text w-100 loading-animate-light" style="width:100%; height: 100%;"></div>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="loader-6">
                                            <div class="text w-100 loading-animate-dark" style="width:100%; height: 100%;"></div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td rowspan="2" class="iti-day">
                                        <div class="loader-3">
                                            <div class="text w-100 loading-animate-dark" style="width:100%; height: 100%;"></div>
                                        </div>
                                    </td>
                                    <td class="iti-time">
                                        <div class="loader-4">
                                            <div class="text w-100 loading-animate-light" style="width:100%; height: 100%;"></div>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="loader-5">
                                            <div class="text w-100 loading-animate-dark" style="width:100%; height: 100%;"></div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="iti-time">
                                        <div class="loader-4">
                                            <div class="text w-100 loading-animate-light" style="width:100%; height: 100%;"></div>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="loader-6">
                                            <div class="text w-100 loading-animate-dark" style="width:100%; height: 100%;"></div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td rowspan="2" class="iti-day">
                                        <div class="loader-3">
                                            <div class="text w-100 loading-animate-dark" style="width:100%; height: 100%;"></div>
                                        </div>
                                    </td>
                                    <td class="iti-time">
                                        <div class="loader-4">
                                            <div class="text w-100 loading-animate-light" style="width:100%; height: 100%;"></div>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="loader-5">
                                            <div class="text w-100 loading-animate-dark" style="width:100%; height: 100%;"></div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="iti-time">
                                        <div class="loader-4">
                                            <div class="text w-100 loading-animate-light" style="width:100%; height: 100%;"></div>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="loader-6">
                                            <div class="text w-100 loading-animate-dark" style="width:100%; height: 100%;"></div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="iti-day">
                                        <div class="loader-3">
                                            <div class="text w-100 loading-animate-dark" style="width:100%; height: 100%;"></div>
                                        </div>
                                    </td>
                                    <td class="iti-time">
                                        <div class="loader-4">
                                            <div class="text w-100 loading-animate-light" style="width:100%; height: 100%;"></div>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="loader-5">
                                            <div class="text w-100 loading-animate-dark" style="width:100%; height: 100%;"></div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div id="default">
                            <div class="text w-100 loading-animate-light" style="width:100%; height: 100%;"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="packages" class="opacit">
            <aside class="pt1">
                <h3>Package Selection
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-caret-right-fill" viewBox="0 0 16 16">
          <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"/>
        </svg>
                </h3>
            </aside>
            <div class="modules-ecomm">
                <div class="headmod">
                    <div class="loader-1">
                        <div class="text w-100 loading-animate-green" style="width:100%; height: 100%;"></div>
                    </div>
                </div>
            </div>
        </div>
        <div id="cabins" class="opacit">
            <aside class="pt1">
                <h3>Cabin Selection
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-caret-right-fill" viewBox="0 0 16 16">
          <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"/>
        </svg>
                </h3>
            </aside>
            <div class="modules-ecomm">
                <div class="headmod">
                    <div class="loader-1">
                        <div class="text w-100 loading-animate-green" style="width:100%; height: 100%;"></div>
                    </div>
                </div>
            </div>
        </div>
        <div id="passengers" class="opacit">
            <aside class="pt1">
                <h3>Number of Passengers
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-caret-right-fill" viewBox="0 0 16 16">
          <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"/>
        </svg>
                </h3>
            </aside>
            <div class="modules-ecomm">
                <div class="headmod">
                    <div class="loader-1">
                        <div class="text w-100 loading-animate-green" style="width:100%; height: 100%;"></div>
                    </div>
                </div>
            </div>
        </div>
        <div id="breadits">
            <div onclick="location.href='checkout-pt1.html';" class="active"><span>1</span>
                <p>Tour Options</p>
            </div>
            <div onclick="location.href='checkout-pt2.html';"><span>2</span>
                <p>Passengers Info</p>
            </div>
            <div onclick="location.href='checkout-pt4.html';"><span>4</span>
                <p>Check-out</p>
            </div>
        </div>
        <p>&nbsp;</p>
        <hr>
        <a href="results.html" class="backstep">[Back to Search Results]</a>
        <a target="_blank" class="assistance">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-envelope-fill" viewBox="0 0 16 16">
                <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555zM0 4.697v7.104l5.803-3.558L0 4.697zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757zm3.436-.586L16 11.801V4.697l-5.803 3.546z"/>
              </svg>
            <strong>Need assistance?</strong> Contact us</a>
    </div>
</div>

<!--footer-->
<div class="container">

    <hr>

    <div class="post-footer">
        <div class="wrap2">
            <a href="https://galavail.com/galavail-web/" class="galavail">Powered by GalAvail</a>
            <p>2019 © Galapagos Islands, Ecuador. &nbsp; <a href="http://www.galavail.com/">Galapagos</a> &nbsp;•&nbsp; <a href="https://www.galavail.com/pdf/privacy-policy.pdf" target="_blank">Privacy Policy</a> &nbsp;•&nbsp; <a>
                info@galavail.com</a></p>
        </div>
    </div>

</div>