<div class="ecomm-hd">
    <a href="javascript:returnOrigin()" *ngIf="this.isFirstPage || this.numberPage==4" class="backstep">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" [ngStyle]="{ 'color':  this.companyParamsService.getCompanyParams() == undefined ?('white'): ('#'+this.companyParamsService.getMainColor()) }" class="bi bi-arrow-return-left"
            viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M14.5 1.5a.5.5 0 0 1 .5.5v4.8a2.5 2.5 0 0 1-2.5 2.5H2.707l3.347 3.346a.5.5 0 0 1-.708.708l-4.2-4.2a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 8.3H12.5A1.5 1.5 0 0 0 14 6.8V2a.5.5 0 0 1 .5-.5z"/>
</svg> [Back to Search Results]
    </a>
    <a href="javascript:void(0)" (click)="goBack()" *ngIf="!this.isFirstPage && this.numberPage!=4" class="backstep">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" [ngStyle]="{ 'color':  this.companyParamsService.getCompanyParams() == undefined ?('white'): ('#'+this.companyParamsService.getMainColor()) }" class="bi bi-arrow-return-left"
            viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M14.5 1.5a.5.5 0 0 1 .5.5v4.8a2.5 2.5 0 0 1-2.5 2.5H2.707l3.347 3.346a.5.5 0 0 1-.708.708l-4.2-4.2a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 8.3H12.5A1.5 1.5 0 0 0 14 6.8V2a.5.5 0 0 1 .5-.5z"/>
</svg> [{{this.getNameOfBackLabel(this.numberPage)}}]
    </a>

    <img *ngIf="this.isFirstPage" [src]="this.urlLogoCruise" width="118" height="28" alt="" [ngStyle]="{ 'border-left':  this.companyParamsService.getCompanyParams() == undefined ?('3px solid #a30000'): ('3px solid #'+this.companyParamsService.getMainColor()) }">
    <div *ngIf="!this.position">
        <strong *ngIf="this.cruise !== undefined">
      {{this.cruise.availability[this.cruise.iterIndex].length}} Cruise from US$ {{priceWithAirFare | number:'3.0-0'}}
      <u *ngIf="this.hastPromo " style="text-decoration: line-through">(US$ {{wasPriceWithAirFare | number:'3.0-0'}})</u>
      p/person
    </strong>
        <br> {{this.cruise.availability[this.cruise.iterIndex].promotionLabel}}
        <!--
  <div *ngIf="this.cruise.availability[this.cruise.iterIndex].promotionType!==257 && !this.isCalipso">
    + free hotel night &amp; 2 transfers*
  </div>
  <div *ngIf="this.cruise.availability[this.cruise.iterIndex].promotionType===257 || this.isCalipso ">
    ({{promotion}})
  </div>
-->

        <li style="margin-right: 148px;font-style: italic;font-size: 12px;" *ngFor="let aServices of this.cruise.availability[this.cruise.iterIndex].aditionalServices">
            {{aServices}}*
        </li>
    </div>
    <div *ngIf="this.position && this.cruise !== undefined">
        <strong>
      {{this.cruise.availability[this.cruise.iterIndex].length}}-Cruise {{cruiseStartDate}} • Itinerary:
      {{startEndDates}} ({{startEndDatesType}})
    </strong>
        <br> {{this.cruise.name}} {{this.cruise.type}}
    </div>
</div>
