import { Injectable } from "@angular/core";
import { MenuHeader } from "src/app/core/components/header/header.component";
import { PriceRateDescriptor } from "src/app/enums/price-rate-descriptor.enum";
import { PaypalConfiguration } from "src/app/models/paypal/paypal-configuration";
import { PriceRateService } from "src/app/reserve/services/price-rate/price-rate.service";
import { environment } from "src/environments/environment";
import { isNullOrUndefined } from "util";
import { PaymentMethod } from "../../models/payment-method";
import { Company } from "./../../models/company";
import { Script, ScriptJs, HeaderActions } from "../../models/company";
import { ScriptNamesEnum } from "../../enums/script-names-enum";
import { PaymentMethodTypeEnum } from "../../enums/payment-methods-type.enum-";

@Injectable({
  providedIn: "root",
})
export class CompanyParamsService {
  company: Company;
  dateToday = new Date();
  constructor(private priceRateService: PriceRateService) {}

  setCompany(company, paramsUrl) {
    this.company = company;
    this.priceRateService.addPriceRate({
      codigo: 1,
      nombre: PriceRateDescriptor.AirFare,
      valor: isNullOrUndefined(company.rateAirTicketAduld)
        ? 0
        : company.rateAirTicketAduld,
    });
    this.priceRateService.addPriceRate({
      codigo: 2,
      nombre: PriceRateDescriptor.ChildrenAirFare,
      valor: isNullOrUndefined(company.rateAirTicketChild)
        ? 0
        : company.rateAirTicketChild,
    });
    this.generateKeyCompany(paramsUrl.companyName);
  }

  public generateKeyCompany(companyPath) {
    const encodeDate = window.btoa(this.getFormatedDateToday());
    const encodeCompany = window.btoa(encodeDate + "," + companyPath);

    this.company.keyCompany = encodeCompany;
  }

  getFormatedDateToday() {
    let formatedDateToday: string;
    const tempMonth: number = this.dateToday.getMonth() + 1;
    const tempDay: number = this.dateToday.getDate();

    let formatMonth: string;
    let formatDay: string;

    if (tempMonth > 0 && tempMonth < 10) {
      formatMonth = "0" + tempMonth;
    } else {
      formatMonth = tempMonth.toString();
    }
    if (tempDay > 0 && tempDay < 10) {
      formatDay = "0" + tempDay;
    } else {
      formatDay = tempDay.toString();
    }

    formatedDateToday =
      this.dateToday.getFullYear().toString() + formatMonth + formatDay;

    return formatedDateToday;
  }

  getCompanyParams(): Company {
    try {
      return this.company;
    } catch (error) {
      console.error(error);
    }
  }

  getCode(): string {
    try {
      return this.company.code;
    } catch (error) {
      console.error(error);
    }
  }

  getName(): string {
    try {
      return this.company.name;
    } catch (error) {
      console.error(error);
    }
  }

  getPhone(): string {
    try {
      return this.company.phone;
    } catch (error) {
      console.error(error);
    }
  }

  getInfoEmail(): string {
    try {
      return this.company.infoEmail;
    } catch (error) {
      console.error(error);
    }
  }

  getPrivacy(): string {
    try {
      return this.company.privacyUrl;
    } catch (error) {
      console.error(error);
    }
  }
  getEmail(): string {
    try {
      return this.company.email;
    } catch (error) {
      console.error(error);
    }
  }

  getFooterColor(): string {
    try {
      return this.company.footerColor;
    } catch (error) {
      console.error(error);
    }
  }

  getHeaderColor(): string {
    try {
      return this.company.headercolor;
    } catch (error) {
      console.error(error);
    }
  }

  getMainColor(): string {
    try {
      return this.company.mainColor;
    } catch (error) {
      console.error(error);
    }
  }

  getSecundaryColor(): string {
    try {
      return this.company.secondaryColor;
    } catch (error) {
      console.error(error);
    }
  }

  getLogo(): string {
    try {
      return environment.app_resources.companyLogos + this.company.logo;
    } catch (error) {
      console.error(error);
    }
  }

  getWebSite(): string {
    try {
      return this.company.webSite;
    } catch (error) {
      console.error(error);
    }
  }

  getVisibleEcommerce(): boolean {
    try {
      return this.company.visibleEcommerce;
    } catch (error) {
      console.error(error);
    }
  }

  getVisiblepago(): boolean {
    try {
      return this.company.visiblePago;
    } catch (error) {
      console.error(error);
    }
  }

  getTermsConditions(): string {
    try {
      return this.company.privacyUrl;
    } catch (error) {
      console.error(error);
    }
  }
  getPercentPartialPayment() {
    try {
      if (!this.company){
        return 0 ;
      }
      return this.company.percentPartialPayment;
    } catch (error) {
      console.error(error);
    }
  }

  getStatusPartialPayment() {
    try {
      return this.company.statusPartialPayment;
    } catch (error) {
      return false;
    }
  }

  getPaymentMethods(): PaymentMethod[] {
    try {
      return this.company.paymentMethods;
    } catch (error) {
      return [];
    }
  }

  getListMenu(): MenuHeader[] {
    try {
      return this.company.listadoMenu;
    } catch (error) {
      return [];
    }
  }

  getPayPalInfo(): PaypalConfiguration {
    try {
      const indexPaypal = this.company.paymentMethods.findIndex(
        (item) =>
          Number(item.type) === Number(PaymentMethodTypeEnum.PAYPAL_CHECKOUT)
      );
      if (indexPaypal >= 0) {
        return this.company.paymentMethods[indexPaypal].objectConfiguration;
      }
    } catch (error) {}
  }
  getHeaderActions(): HeaderActions {
    try {
      return this.company.headerActions;
    } catch (error) {
      return undefined;
    }
  }
  getScripts(): Script[] {
    let scripts: ScriptJs[] = [];
    if (this.getHeaderActions().scripts)
      scripts = this.getHeaderActions().scripts.filter(
        (searchItem) => searchItem.name === ScriptNamesEnum.FOR_INITIAL_PAGE
      );

    return scripts.map((item) => {
      return { srcLinks: item.url };
    });
  }

  getBodyScripts(): Script[] {
    let scripts: ScriptJs[] = [];
    if (this.getHeaderActions().scripts)
      scripts = this.getHeaderActions().scripts.filter(
        (searchItem) => searchItem.name === ScriptNamesEnum.FOR_BODY_PAGE
      );
    // scripts = [
    //   { name: "noscirpt", url: "assets/js/google-tag-manager-rg-noscript.js" },
    // ];
    return scripts.map((item) => {
      return { srcLinks: item.url };
    });
  }
  getScriptsForThankYouPage(): Script[] {
    let scripts: ScriptJs[] = [];
    if (this.getHeaderActions().scripts)
      scripts = this.getHeaderActions().scripts.filter(
        (searchItem) => searchItem.name === ScriptNamesEnum.FOR_THANK_YOU_PAGE
      );

    return scripts.map((item) => {
      return { srcLinks: item.url };
    });
  }

  setPercentPartialPayment(percentPartialPayment: number ){
    this.company.percentPartialPayment = percentPartialPayment;
  }

  //TODO: GETTERS PARA MES Y AÑO DE FIND COMPANY
  getDateRange(): string{
    try {
      return this.company.fechasBusquedaDefault;
    } catch (error) {
      console.error(error);
    }
  }
}
