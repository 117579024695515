<div class="line" [ngStyle]="{ 'border-bottom':  this.companyParamsService.getCompanyParams() == undefined ?('blue'):
('7px solid #'+this.companyParamsService.getMainColor()) }" *ngIf="this.companyParamsService!=undefined && this.companyParamsService.getCompanyParams()!=undefined">
    <div class="main-wrap nav">
        <a *ngIf="!this.companyParamsService.getWebSite()" class="logo">
            <img src="../../../../assets/img/logo.jpg" width="227" height="66" alt="">
        </a>
        <a *ngIf="this.companyParamsService.getWebSite()" href="https://{{ companyParamsService.getWebSite() }}" class="logo">
            <img [src]=this.companyParamsService.getLogo() height="66" alt="Logo">
        </a>
        <div class="headtitle">
            <h1>Book your Cruise Online</h1>
            <div class="menurow">

                <p><strong>Call Us:</strong> {{ this.companyParamsService.getPhone() }}
                </p>
            </div>
        </div>
    </div>
</div>

<app-loading-header *ngIf="this.companyParamsService.getCompanyParams() == undefined"></app-loading-header>
