<table [class]="this.className">
    <tr *ngIf=" this.isIncludeTableHeader === true ">
        <th colspan='5' class='boxin' scope='col'><img src="assets/images/search-pages/icons/itinerary.png" width="25"
                height="25">{{ this.tourLengthShortName }}-Day Cruise with {{ this.cruiseName }}</th>
    </tr>
    <tbody *ngFor='let iter of itineraries; let i = index'
        [ngStyle]="{ 'background':  !this.isPair(i)  ?('white'): ('#f5f5f5') }">
        <tr *ngFor='let activity of iter.morningActivities; let i = index; let length = count'>
            <td class="iti-day" *ngIf=" i === 0 "
                [rowSpan]=" iter.morningActivities.length + iter.afternoonActivities.length " style="width: 40px !important;">
                <span>{{ iter.diaSemana.substring( 0, 3 ).toUpperCase() }}</span>
            </td>
            <td *ngIf=" i === 0 && activity.period!='' " class="iti-time" [rowSpan]=" length ">{{ activity.period }}</td>
            <td>
                <span *ngIf="activity.optional">
                    Or <br>
                </span> {{ activity.isla }}
                <span *ngIf=" activity.nombre !== null && activity.nombre !== undefined && activity.nombre !== '' ">
                    <span
                        *ngIf=" activity.isla !== null && activity.isla !== undefined && activity.isla !== '' ">:</span>
                    {{ activity.nombre }}
                </span>
            </td>
        </tr>
        <tr *ngFor='let activity of iter.afternoonActivities; let i = index; let length = count'>
            <td class="iti-day" *ngIf=" i === 0 && iter.morningActivities.length === 0 "
                [rowSpan]=" iter.afternoonActivities.length " style="width: 40px !important;"><span>{{ iter.diaSemana.substring( 0, 3 ).trim() }}</span></td>
            <td *ngIf=" i === 0 && activity.period!='' " class="iti-time" [rowSpan]=" length ">{{ activity.period }}</td>
            <td>
                <span *ngIf="activity.optional">
                    Or <br>
                </span> {{ activity.isla }}
                <span *ngIf=" activity.nombre !== null && activity.nombre !== undefined && activity.nombre !== '' ">
                    <span
                        *ngIf=" activity.isla !== null && activity.isla !== undefined && activity.isla !== '' ">:</span>
                    {{ activity.nombre }}
                </span>
            </td>
        </tr>
    </tbody>
</table>