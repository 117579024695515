import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-error-payment',
  templateUrl: './error-payment.component.html',
  styleUrls: ['./error-payment.component.css']
})
export class ErrorPaymentComponent {
  @Input() public errorHeader: string = '';
  @Input() public errorText: string = '';
  @Input() public errorFoot: string = '';
  @Input() public visible = false;
}
