import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})

export class UtilDate {

    formatStringToDate(dateString: string): Date {
        return new Date(Number.parseInt(dateString.slice(4, 8)), Number.parseInt(dateString.slice(2, 4)) - 1, Number.parseInt(dateString.slice(0, 2)));
    }

    formatDate(date: Date): string {
        let dayString = date.getDate() < 10 ? '0' : '';
        let monthString = date.getMonth() < 9 ? '0' : '';
        let dateString: string = dayString + date.getDate().toString() + monthString + (date.getMonth() + 1).toString() + date.getFullYear().toString();
        return dateString;
    }
}