import { Injectable } from '@angular/core';
import { Month } from 'src/app/models/month';

@Injectable()
export class MonthService {

  private months: Month[] = [];

  constructor() {
    this.fillMonths();
  }

  private fillMonths(): void {
    this.addMonth({ codigo: 1, valor: 'January', estado: 1, nombreCorto: 'Jan' });
    this.addMonth({ codigo: 2, valor: 'February', estado: 1, nombreCorto: 'Feb' });
    this.addMonth({ codigo: 3, valor: 'March', estado: 1, nombreCorto: 'Mar' });
    this.addMonth({ codigo: 4, valor: 'April', estado: 1, nombreCorto: 'Apr' });
    this.addMonth({ codigo: 5, valor: 'May', estado: 1, nombreCorto: 'May' });
    this.addMonth({ codigo: 6, valor: 'June', estado: 1, nombreCorto: 'Jun' });
    this.addMonth({ codigo: 7, valor: 'July', estado: 1, nombreCorto: 'Jul' });
    this.addMonth({ codigo: 8, valor: 'August', estado: 1, nombreCorto: 'Aug' });
    this.addMonth({ codigo: 9, valor: 'September', estado: 1, nombreCorto: 'Sep' });
    this.addMonth({ codigo: 10, valor: 'October', estado: 1, nombreCorto: 'Oct' });
    this.addMonth({ codigo: 11, valor: 'November', estado: 1, nombreCorto: 'Nov' });
    this.addMonth({ codigo: 12, valor: 'December', estado: 1, nombreCorto: 'Dec' });
  }

  private addMonth(month: Month): void {
    this.getMonths().push(month);
  }

  public getMonths(): Month[] {
    return this.months;
  }

  private setMonths(months: Month[]): void {
    this.months = months;
  }

  public getMonthByShortName(shortname: string): string {
    let longname = "";
    this.months.forEach((month) => {
      if(shortname==month.nombreCorto){
        longname=month.valor;
      }
    })
    return longname;
  }


}
