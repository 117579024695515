import { Component, OnInit } from '@angular/core';
import { CompanyParamsService } from 'src/app/services/companyParams/company-params.service';
import { GalavailServiceEcommer } from "clientwsGalavail";

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.css']
})
export class ErrorComponent implements OnInit {
  public counter = 10;
  constructor(
    public companyParamsService: CompanyParamsService,
    private galavailServiceEcommer: GalavailServiceEcommer
  ) { }

  ngOnInit() {
    const intervalId = setInterval(() => {
      this.counter = this.counter - 1;
      console.log(this.counter)
      if (this.counter < 1) {
        // window.location.href = 'https://galavail.com/galavail-web/';
      }
    }, 1000)
    this.getDateRangeFromCompany();
  }

  getDateRangeFromCompany(){
    const companyDateRange = this.companyParamsService.getDateRange().split('-');
    let companyDateFrom = companyDateRange.length != 1 ? companyDateRange[0] : '';
    let companyDateTo =  companyDateRange.length != 1 ? companyDateRange[1] : '';
    this.galavailServiceEcommer.setLocalStorage('companyDateFrom',companyDateFrom);
    this.galavailServiceEcommer.setLocalStorage('companyDateTo',companyDateTo);

  }


  reloadPage() {

  }

  backPage() {

  }

}
