<div class="container" *ngIf="this.companyParamsService!=undefined && this.companyParamsService.getCompanyParams()!=undefined" style="display: block" [ngStyle]="{ 'background-color': '#'+this.companyParamsService.getFooterColor() }">
    <!-- end main -->
    <!-- start post footer -->
    <div class="post-footer">
        <div class="wrap2">
            <a href="https://galavail.com/galavail-web/" class="galavail" [ngClass]="isTooDark(this.companyParamsService.getFooterColor()) ? 'darkMode' : 'lightMode'" style="color: #ececec !important;"></a>
            <p style="color: #ececec; font-size: 12px;
      font-family: Arial, Helvetica, sans-serif;
      text-decoration: none;
      color: #ececec;
      /*text-shadow: 0 1px 0 #0e0e0e;*/
      line-height: 1.5em;" [ngClass]="isTooDark(this.companyParamsService.getFooterColor()) ? 'darkMode' : 'lightMode'">Copyright {{ todayDate }} ©
                <a href="https://galavail.com/" target="_blank" style="color: #FFFFFF !important;" [ngClass]="isTooDark(this.companyParamsService.getFooterColor()) ? 'darkMode' : 'lightMode'">Galavail.com</a> &nbsp;All Rights Reserved. &nbsp;&bull;&nbsp;
                <a [href]="'https://'+this.configPrivacyUrl()" style="color: #FFFFFF !important;" [ngClass]="isTooDark(this.companyParamsService.getFooterColor()) ? 'darkMode' : 'lightMode'" (onclick)="this.configPrivacyUrl()">{{this.companyParamsService.getInfoEmail()}}</a>
            </p>
        </div>
    </div>
    <!-- end post footer -->
</div>
