import { Injectable } from '@angular/core';
import { ResponseOnHold } from 'src/app/enums/response-on-hold.enum';
import { PurchaseRequestService } from 'src/app/reserve/services/purchase-request/purchase-request.service';
import { BookingConfirmationResponse } from 'src/app/models/purchase/booking-confirmation-response';
import { NgWebflowService } from "ngweflow";
import { WebFlowAction } from "src/app/enums/web-flow-action.enum";
import { ReservationClass } from 'src/app/classes/reservation.class';
import { RoyalRequestService } from 'src/app/services/royal-request/royal-request.service';


export enum AuditStepRoyalForm {
  CRM_START = 'CRMSTART',
  CRM_SUCCESS = 'CRMSUCCESS',
  CRM_FAILED = 'CRMFAILED',
}
@Injectable({
  providedIn: 'root'
})
export class CommonService {
  private reservationClass = new ReservationClass();

  constructor(private purchaseRequestService: PurchaseRequestService,
              private ngWebflowService: NgWebflowService,
              private royalRequestService: RoyalRequestService
  ) { }

    /**
     * Bloquear la salida
     * @returns True si es exitosa, False si no es exitosa
     */
    blockDeparture = async (): Promise<boolean> => {
        const blockDepartureResponse: any =
        await this.purchaseRequestService.requestBlockCabinOnHold(this.purchaseRequestService.getPercentUsed()).toPromise();
        const isblockDeparture: boolean = this.purchaseRequestService.setBlockDeparture(blockDepartureResponse);
        const isBlockDepartureSuccess = isblockDeparture === true && this.purchaseRequestService.isRequestBlockDepartureSuccess();
        return isBlockDepartureSuccess;
    }

    /**
     * Enviar Confirmacion de reserva - notificaciones
     * @returns True si la confirmacion es exitosa, False si no es exitosa
     */
    requestBookingConfirmation = async (): Promise<boolean> => {
    try {
        await this.purchaseRequestService.getRequestSendConfirmEmail()
        return new Promise<boolean>((resolve, reject) => {
          this.purchaseRequestService.requestBookingConfirmation().subscribe(
            (bookingConfirmationResponse: BookingConfirmationResponse) => {
              if (
                this.purchaseRequestService.isRequestBookingConfirmationSuccess(bookingConfirmationResponse)
              ) {
                resolve(true); // Redireccionar a pagina thank you en el front
              } else {
                resolve(false); // ResponseOnHold.RESERVED_ERROR;
              }
            },
            (error: any) => {
              console.error(error);
              resolve(false);
            },
            () => {}
          );
        });
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  /**
   *
   * @param errorType Tipo de error 0: Error en el bloqueo de la cabina, 1: Otro Error
   */
  notificationError = (errorType: ResponseOnHold) => {
    const AUTOMATIC_NOTIFICATION = true;
    const isPaymentError = errorType === ResponseOnHold.PAYMENT_ERROR ? true : false;
    this.purchaseRequestService.requestInquireConfirm(AUTOMATIC_NOTIFICATION, isPaymentError).subscribe((resp) => {
    });
  }

/**
 * Request RoyalForm y auditoria
 * @param isVoerTime
 */
  async requestRoyalForm(reservationClass: ReservationClass) {
      this.auditRoyalForm(AuditStepRoyalForm.CRM_START, reservationClass.formReservation);
      this.royalRequestService.requestPost(reservationClass.formReservation).subscribe(
        (response) => this.auditRoyalForm(AuditStepRoyalForm.CRM_SUCCESS, reservationClass.formReservation),
        (error) => this.auditRoyalForm(AuditStepRoyalForm.CRM_FAILED, error)
      );
  }

/**
 * Auditoria de RoyalForm
 * @param step Paso de la auditoria
 * @param data Datos de la auditoria
 */
  auditRoyalForm =(step : AuditStepRoyalForm, data?: any) => {
    switch(step){
      case AuditStepRoyalForm.CRM_START:
        this.ngWebflowService.addWebFlowAction(WebFlowAction.SEND_CRM_REQUEST_START, data);
        break;
      case AuditStepRoyalForm.CRM_FAILED:
        this.ngWebflowService.addWebFlowAction(WebFlowAction.SEND_CRM_REQUEST_FAILED, data);
        break;
      case AuditStepRoyalForm.CRM_SUCCESS:
        this.ngWebflowService.addWebFlowAction(WebFlowAction.SEND_CRM_REQUEST_SUCCESS, { result: "OK-fromPaymentOptions", json: data });
        break;
    }
  }

}
