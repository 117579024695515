<div id="pt1">
    <aside class="pt1">
        <span *ngIf="ischarterOrGroupsOnly()" style="font-size: 12px;
    position: absolute;
    padding: 2px;
    color: #fff;
    background-color: rgb(246, 178, 51);" [ngStyle]="{ 'background-color': '#'+mainColor }">
            {{getTextToCharterOrGroupsOnly()}}
          </span>
        <img [src]="this.boatImageResource" width="361" height="180" alt="">

        <div *ngIf="asideBoolean == true">
            <h4 class="tourdet" [ngStyle]="{ 'color': '#'+mainColor }">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle-fill" viewBox="0 0 16 16">
                  <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"/>
                </svg> Tour Details
                <img [src]="getUrlLogoCruise() " style="width: 7rem; float: right;" alt="cruise.name">
            </h4>

            <table width="100%">
                <tbody>
                    <tr>
                        <th colspan="3" scope="col">Accommodation</th>
                    </tr>
                </tbody>
                <tbody>
                    <div *ngFor="let numberCabins of this.cabinsNumber; let i = index">
                        <tr>
                            <td rowspan="2" valign="top" style="vertical-align: top !important">
                                <h5 style="margin-top: 0">{{ i + 1 }}st Cabin</h5>
                            </td>
                            <td>Type:</td>
                            <td>{{ this.purchaseCabinInformation[ i ] }} Cabin</td>
                        </tr>
                        <tr>
                            <td>Bed:</td>
                            <td>{{ this.purchaseCabinInformationBed[ i ] }}</td>
                        </tr>
                    </div>
                </tbody>
            </table>
            <ul>
                <li><strong [ngStyle]="{ 'color': '#'+mainColor }">Date:</strong>{{cruiseStartDate}}</li>
                <li><strong [ngStyle]="{ 'color': '#'+mainColor }">Itinerary:</strong>{{startEndDates}} ({{startEndDatesType}})</li>
            </ul>
            <h4 style="margin-bottom: 5px;" class="price" [ngStyle]="{ 'color': '#'+mainColor }">

                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" style="	padding-right: 5px;" fill="currentColor" class="bi bi-coin" viewBox="0 0 16 16">
                <path d="M5.5 9.511c.076.954.83 1.697 2.182 1.785V12h.6v-.709c1.4-.098 2.218-.846 2.218-1.932 0-.987-.626-1.496-1.745-1.76l-.473-.112V5.57c.6.068.982.396 1.074.85h1.052c-.076-.919-.864-1.638-2.126-1.716V4h-.6v.719c-1.195.117-2.01.836-2.01 1.853 0 .9.606 1.472 1.613 1.707l.397.098v2.034c-.615-.093-1.022-.43-1.114-.9H5.5zm2.177-2.166c-.59-.137-.91-.416-.91-.836 0-.47.345-.822.915-.925v1.76h-.005zm.692 1.193c.717.166 1.048.435 1.048.91 0 .542-.412.914-1.135.982V8.518l.087.02z"/>
                <path fill-rule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                <path fill-rule="evenodd" d="M8 13.5a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zm0 .5A6 6 0 1 0 8 2a6 6 0 0 0 0 12z"/>
              </svg> Pricing Details</h4>
            <p *ngIf="this.purchaseRequestService.isIncludeAirFare()"><strong>Option A:</strong> Super Savings Package
            </p>
            <p *ngIf="!this.purchaseRequestService.isIncludeAirFare()"><strong>Option B:</strong> Cruise Only</p>
            <table width="100%" *ngIf="this.cruise">
                <tbody>
                    <tr>
                        <th colspan="2" scope="col">Adults: {{this.adults}} • Children&nbsp;<em>(age {{this.cruise.minimumAgeChild}}
                to {{this.cruise.maximumAgeChild}}): </em>{{this.children}}
                        </th>
                    </tr>
                    <!-- <ng-template ngFor let-itemPassengerArray [ngForOf]="this.purchaseCabinPassengers" let-i="index">
            <tr *ngFor="let itemPassenger of this.itemPassengerArray; let iPassenger = index">
              <th>Passenger ({{itemPassenger.type}}) {{iPassenger+1}} :</th>
              <td>US$ {{itemPassenger.price}}</td>
            </tr>
          </ng-template> -->
                    <tr *ngFor="let purchaseCabinPassenger of this.getPurchaseCabinPassengers(); let i = index">
                        <td width="38%" class="bck-grey bigger">Passenger{{ i + 1 }}({{purchaseCabinPassenger.type}}):</td>
                        <td width="62%" class="bck-grey bigger"><strong>US$ {{ purchaseCabinPassenger.price | number:'3.0-0' }}</strong></td>
                    </tr>
                    <tr *ngIf="this.isPurchaseIncludeAirTickets() && !this.isIncludeAirFare()">
                        <td>Air Tickets</td>
                        <td><strong>US$ {{ this.getGalapagosAirTicketsTotal()| number:'3.0-0' }}</strong></td>
                    </tr>

                    <tr>
                        <th>{{getInvoiceText()}}*:</th>
                        <td style="background-color: #fff; color: #289079; font-size: 18px; font-weight: bold">
                            US$ {{ this.getPurchaseWithOutDiscount()| number:'3.0-0' }}</td>
                    </tr>
                    <tr *ngIf=" this.getPurchaseRequestStatus() ===  purchaseRequestStatusType.PASSENGER_COMPLETED && isWithThirtyPercent() ">

                        <th> Initial deposit ({{this.companyParamsService.getPercentPartialPayment()}}%) to be paid*:</th>
                        <td style="background-color: #fff; color: #289079; font-size: 18px; font-weight: bold; vertical-align: middle;">
                            US$ {{ this.getPurchaseTotalPrice()| number:'3.0-0' }}</td>
                    </tr>
                    <tr *ngIf=" this.getPurchaseRequestStatus() >=  purchaseRequestStatusType.PAYMENT_COMPLETED && isWithThirtyPercent() ">

                        <th> {{getTextTotalPaid()}}:</th>
                        <td style="background-color: #fff; color: #289079; font-size: 18px; font-weight: bold; vertical-align: middle;">
                            US$ {{ this.getPurchaseTotalPrice()| number:'3.0-0' }}</td>
                    </tr>
                </tbody>
            </table>
            <p class="notes">*Includes taxes and fees</p>
            <div *ngIf="!unActiveTourIncludes">
                <p style="text-decoration: underline">Tour Includes:</p>
                <ul>
                    <!--
            <li *ngIf="this.cruise.availability[this.cruise.iterIndex].promotionType===PROMO_RAC">
              {{this.cruise.availability[this.cruise.iterIndex].length}}-Galapagos Cruise </li>
          -->
                    <li *ngIf="this.cruise.availability[this.cruise.iterIndex].promotiopromotionTypenCode!==PROMO_RAC">
                        {{this.cruise.availability[this.cruise.iterIndex].length}}- Itinerary ( {{!this.cruise.availability[this.cruise.iterIndex].charterOnly ? this.cruise.availability[this.cruise.iterIndex].promotionLabel : this.cruise.availability[this.cruise.iterIndex].charterPromotionLabel}})
                    </li>
                    <div *ngIf="this.purchaseRequestService.getAditionalServices()?.length || hasIncludes()">
                        <li>
                            Cruise Flight Tickets*</li>

                        <li *ngFor="let aServices of this.purchaseRequestService.getAditionalServices()">
                            <!--
                <strong style="color: #a30000;">FREE</strong>
              -->
                            <p [innerHTML]="hasTextFree(aServices)">**</p>
                        </li>
                    </div>

                </ul>
                <p *ngIf="this.purchaseRequestService.isIncludeAirFare() || this.isPurchaseIncludeAirTickets()" style="margin-left: 15px;margin-bottom: 0px;">
                    <span _ngcontent-c13="" style="font-size: 11px;">* Flights are subject to availability</span> <br>
                </p>
                <p style="margin-left: 15px;" *ngIf="this.purchaseRequestService.getAditionalServices().length>0">
                    <span _ngcontent-c13="" style="font-size: 11px;">
            ** Free services apply only when the flights are on the same dates of the
            cruise.
          </span>
                </p>

            </div>

        </div>
    </aside>
</div>
