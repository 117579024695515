import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit, Input } from '@angular/core';
import { CruiseService } from '../../services/cruise/cruise.service';
import { MonthService } from 'src/app/core/services/month/month.service';
import { Location } from '@angular/common';
import { CompanyParamsService } from 'src/app/services/companyParams/company-params.service';
import { environment } from 'src/environments/environment';
import { GalavailServiceEcommer } from 'clientwsGalavail';
import { UtilDate } from 'src/app/shared/util/util-date';

@Component({
  selector: 'app-up-slide',
  templateUrl: './up-slide.component.html',
  styleUrls: ['./up-slide.component.css']
})
export class UpSlideComponent implements OnInit {

  @Input() cruiseStartDate: string;
  @Input() startEndDates: string;
  @Input() startEndDatesType: string;
  @Input() priceWithAirFare: number;
  @Input() wasPriceWithAirFare: number;
  @Input() hastPromo: boolean;
  @Input() cruise: any;
  @Input() promotion: string;
  @Input() position: boolean;
  @Input() cruiseItineraryDuration: string;
  @Input() numberPage: number;
  @Input() urlLogoCruise: string;
  @Input() isFirstPage: boolean;
  isCalipso: boolean;
  mainColor: string;
  isProduction: boolean = environment.production;
  constructor(
    private location: Location,
    private cruiseService: CruiseService,
    private router: Router,
    public companyParamsService: CompanyParamsService,
    private monthService: MonthService,
    private galavailServiceEcommer: GalavailServiceEcommer,
    private utilDate: UtilDate,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.mainColor = this.companyParamsService.getMainColor();
    this.isCalipso = this.cruiseService.isCalipso(this.cruise);
    this.getMonthYearFromCompany();
  }

  getMonthYearFromCompany() {
    let key = decodeURIComponent(this.location.path().split('/')[4]);
    let companyDateRange = this.companyParamsService.getDateRange().split('-');
    let auxStartDate = new Date(this.galavailServiceEcommer.getDataofLocalStorage('startDate-'+key));
    let auxEndDate = new Date(auxStartDate.getFullYear(), auxStartDate.getMonth()+1,auxStartDate.getDate());
    let companyDateFrom = companyDateRange.length != 1 ? companyDateRange[0] :this.utilDate.formatDate(auxStartDate);
    let companyDateTo = companyDateRange.length != 1 ? companyDateRange[1] : this.utilDate.formatDate(auxEndDate);
    localStorage.setItem('companyDateFrom', companyDateFrom);
    localStorage.setItem('companyDateTo', companyDateTo);
    localStorage.setItem('originHost', environment.pathUrlOrigin);
  }

  goBack() {
    console.log('goBack')
    this.location.back();

    //this.router.navigate([this.urlParamsService.createurlForNextPage('1')]);
  }
  getNameOfBackLabel(numberPage) {
    switch (numberPage) {
      case 4:
        return 'Back to search results'
        break;
      case 3:
        return 'Back to Passengers Info'
        break;
      case 2:
        return 'Back to Tour Options'
        break;
      default:
        return '';
    }
  }
  getTextToCharterOrGroupsOnly(): string {
    if (this.cruise.availability[0].charterOnly) {
      return "CHARTER ONLY";
    } else {
      if (this.cruise.availability[0].groupsOnly) {
        return "GROUPS ONLY";
      }
    }
  }
  ischarterOrGroupsOnly() {
    return this.cruise.availability[0].charterOnly || this.cruise.availability[0].groupsOnly;
  }
}
