<div id="infopax">
    <app-up-slide [cruiseStartDate]="this.cruiseStartDate" [startEndDates]="this.startEndDates" [position]="this.position" [startEndDatesType]="startEndDatesType" [priceWithAirFare]="priceWithAirFare" [numberPage]="2" [urlLogoCruise]="this.createUrlLogoCruise()"
        [promotion]="this.promotion" [cruise]="this.cruise" [cruiseItineraryDuration]="this.cruiseItineraryDuration">

    </app-up-slide>
    <br>
    <app-tour-summary [asideBoolean]=true [unActiveTourIncludes]=false [boatImageResource]="this.boatImagesResource" [cruiseInput]="cruise">
    </app-tour-summary>
    <div class="modules-ecomm" id="startCheckoutSecond">
        <div class="headmod" [ngStyle]="{ 'background-color':  this.companyParamsService.getCompanyParams() == undefined ?('blue'): ('#'+this.companyParamsService.getMainColor()) }">
            Passenger Information</div>
        <div class="mod-content active">
            <form [formGroup]="purchasePassengerForm" novalidate>
                <div formArrayName="purchasePassengers">
                    <div [formArrayName]="i" *ngFor="let cabin of this.getPurchasePassengerFormControls(); let i = index;">
                        <h3><strong>{{i+1}}st Cabin ({{ this.purchaseCabinInformation[ i ] }})</strong></h3>
                        <br>
                        <div [formGroupName]="o" *ngFor="let passengers of cabin.controls; let o = index;">
                            <h3><strong>Passenger {{o + 1}}
                  ({{ passengers.get('passengerType').value }}):{{this.purchasePassengerForm.value.purchasePassengers[i][o].firstName}}
                  {{this.purchasePassengerForm.value.purchasePassengers[i][o].lastName}}</strong></h3>
                            <div class="form-grp">
                                <div [attr.class]="passengers.get('firstName').touched && passengers.get('firstName').invalid ? 'has-error traveler-data' : 'traveler-data'">
                                    <label [attr.for]=" 'firstName_' + i" class="control-label">First Name *:</label>
                                    <input [attr.id]=" 'firstName_' + i" type="text" formControlName="firstName" id="firstName" galPassengerFormTabIndex [galFormNumber]="1" [galCabinIndex]="i" [galPassengerIndex]="o" [galTabIndex]="1" required minlength="{{ this.namesMinLength }}" class="form-control"
                                        [attr.autofocus]=" ( i === 0 ) && ( o === 0 ) " style="padding-bottom: 13px;">
                                    <small class="help-block" *ngIf="this.passengers.get('firstName').touched && this.passengers.get('firstName').invalid">First
                    Name is Required</small>
                                </div>
                            </div>
                            <div class="form-grp">
                                <div [attr.class]="passengers.get('lastName').touched && passengers.get('lastName').invalid ? 'has-error traveler-data' : 'traveler-data'">
                                    <label [attr.for]=" 'lastName_' + i " class="control-label">Last Name *:</label>
                                    <input type="text" formControlName="lastName" [attr.id]=" 'lastName_' + i " required id="lastName" class="form-control" galPassengerFormTabIndex [galFormNumber]="1" [galCabinIndex]="i" [galPassengerIndex]="o" [galTabIndex]="2" minlength="{{ this.namesMinLength }}"
                                        style="padding-bottom: 13px;">
                                    <small class="help-block" *ngIf="passengers.get('lastName').touched && passengers.get('lastName').invalid">Last
                    Name is Required</small>
                                </div>
                            </div>
                            <div class="form-grp" style="margin-top: 3px;">
                                <div [attr.class]=" ( passengers.get('birthMonth').touched && passengers.get('birthMonth').invalid )
              || ( passengers.get('birthday').touched && passengers.get('birthday').invalid )
              || ( passengers.get('birthYear').touched && passengers.get('birthYear').invalid )
              || (
              passengers.get('birthMonth').touched )
              && ( passengers.get('birthday').touched )
              && ( passengers.get('birthYear').touched )
              && ( ( passengers.hasError('birthdateError') || passengers.hasError('notAllowedBirtdate') ||  passengers.hasError('notChild') )
              )
              ? 'has-error' : null">
                                    <label [attr.for]=" 'birthMonth_' + i" class="control-label">Date of Birth *:</label>
                                </div>
                                <table width="100%">
                                    <tbody>
                                        <tr>
                                            <td [attr.class]=" ( passengers.get('birthYear').touched && passengers.get('birthYear').invalid ) || (
                                                                          passengers.get('birthMonth').touched )
                                                                          && ( passengers.get('birthday').touched )
                                                                          && ( passengers.get('birthYear').touched )
                                                                          && ( ( passengers.hasError('birthdateError') || passengers.hasError('notAllowedBirtdate') ||  passengers.hasError('notChild') )
                                                                          ) ? 'has-error' : null">
                                                <select [attr.id]=" 'birthYear_' + i" formControlName="birthYear" galPassengerFormTabIndex [galFormNumber]="1" [galCabinIndex]="i" [galPassengerIndex]="o" [galTabIndex]="3" required class="form-control" style="min-width:60px">
                          <option [ngValue]="null">Year</option>
                          <option *ngFor="let year of this.years" [value]="year">{{ year }}</option>
                        </select>
                                                <small class="help-block" *ngIf="(passengers.get('birthMonth').touched && passengers.get('birthMonth').invalid )
                        || ( passengers.get('birthday').touched && passengers.get('birthday').invalid )
                        || ( passengers.get('birthYear').touched && passengers.get('birthYear').invalid )
                        || ( passengers.get('birthMonth').touched )
                        && ( passengers.get('birthday').touched )
                        && ( passengers.get('birthYear').touched )
                        && ( ( passengers.hasError('birthdateError')
                        || passengers.hasError('notAllowedBirtdate')
                        ||  passengers.hasError('notChild') ))">Invalid year.</small>
                                            </td>
                                            <td [attr.class]=" ( passengers.get('birthMonth').touched && passengers.get('birthMonth').invalid ) || (
                      passengers.get('birthMonth').touched )
                      && ( passengers.get('birthday').touched )
                      && ( passengers.get('birthYear').touched )
                      && ( ( passengers.hasError('birthdateError') || passengers.hasError('notAllowedBirtdate') ||  passengers.hasError('notChild') )
                      )  ? 'has-error' : null">
                                                <select [attr.id]=" 'birthMonth_' + i" formControlName="birthMonth" galPassengerFormTabIndex [galFormNumber]="1" [galCabinIndex]="i" [galPassengerIndex]="o" [galTabIndex]="4" required class="form-control" style="min-width:70px">
                          <option [ngValue]="null">Month</option>
                          <option *ngFor=" let month of this.months " [value]="month.codigo">{{ month.nombreCorto }}
                          </option>
                        </select>
                                                <small class="help-block" *ngIf="(passengers.get('birthMonth').touched && passengers.get('birthMonth').invalid )
                        || ( passengers.get('birthday').touched && passengers.get('birthday').invalid )
                        || ( passengers.get('birthYear').touched && passengers.get('birthYear').invalid )
                        || ( passengers.get('birthMonth').touched )
                        && ( passengers.get('birthday').touched )
                        && ( passengers.get('birthYear').touched )
                        && ( ( passengers.hasError('birthdateError')
                        || passengers.hasError('notAllowedBirtdate')
                        ||  passengers.hasError('notChild') ))">Invalid Month.</small>
                                            </td>
                                            <td [attr.class]=" ( passengers.get('birthday').touched && passengers.get('birthday').invalid ) || (
                      passengers.get('birthMonth').touched )
                      && ( passengers.get('birthday').touched )
                      && ( passengers.get('birthYear').touched )
                      && ( ( passengers.hasError('birthdateError') || passengers.hasError('notAllowedBirtdate') ||  passengers.hasError('notChild') )
                      ) ? 'has-error' : null">
                                                <select [attr.id]=" 'birthday_' + i" formControlName="birthday" galPassengerFormTabIndex [galFormNumber]="1" [galCabinIndex]="i" [galPassengerIndex]="o" [galTabIndex]="5" required class="form-control" style="min-width:55px">
                          <option [ngValue]="null">Day</option>
                          <option *ngFor="let day of this.getMonthDays( i, o )" [value]="day.value">{{ day.name }}
                          </option>
                        </select>
                                                <small class="help-block" *ngIf="(passengers.get('birthMonth').touched && passengers.get('birthMonth').invalid )
                        || ( passengers.get('birthday').touched && passengers.get('birthday').invalid )
                        || ( passengers.get('birthYear').touched && passengers.get('birthYear').invalid )
                        || ( passengers.get('birthMonth').touched )
                        && ( passengers.get('birthday').touched )
                        && ( passengers.get('birthYear').touched )
                        && ( ( passengers.hasError('birthdateError')
                        || passengers.hasError('notAllowedBirtdate')
                        ||  passengers.hasError('notChild') ))">Invalid Day.</small>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="form-grp">
                                <div [attr.class]=" passengers.get('gender').touched && passengers.get('gender').invalid ? 'has-error traveler-data' : 'traveler-data'">
                                    <label [attr.for]=" 'gender_' + i " class="control-label">Gender *:</label>
                                    <select formControlName="gender" required [attr.id]=" 'gender_' + i " required galPassengerFormTabIndex [galFormNumber]="1" [galCabinIndex]="i" [galPassengerIndex]="o" [galTabIndex]="6" class="form-control">
                    <option [ngValue]="null">Please select</option>
                    <option [value]="genders.FEMALE.valueOf()">{{ this.genders.FEMALE.valueOf() }}</option>
                    <option [value]="genders.MALE.valueOf()">{{ this.genders.MALE.valueOf() }}</option>
                  </select>
                                    <small class="help-block" *ngIf="passengers.get('gender').touched && passengers.get('gender').invalid">Gender is
                    Required</small>
                                </div>
                            </div>
                            <div class="form-grp">
                                <div [attr.class]=" passengers.get('country').touched && passengers.get('country').invalid ? 'has-error traveler-data' : 'traveler-data'">
                                    <label [attr.for]=" 'country_' + i " class="control-label">Country *:</label>
                                    <select formControlName="country" [attr.id]=" 'country_' + i " galPassengerFormTabIndex [galFormNumber]="1" [galCabinIndex]="i" [galPassengerIndex]="o" [galTabIndex]="7" required class="form-control">
                    <option [ngValue]="null">Please select</option>
                    <option *ngFor="let country of this.getCountries()" [value]="country.name">{{ country.name }}
                    </option>
                  </select>
                                    <small class="help-block" *ngIf="passengers.get('country').touched && passengers.get('country').invalid">Country is
                    Required</small>
                                </div>
                            </div>
                            <div class="form-grp">
                                <div [attr.class]="passengers.get('passport').touched && passengers.get('passport').invalid ? 'has-error traveler-data' : ' traveler-data'">
                                    <label [attr.for]=" 'passport_' + i " class="control-label">Passport #*: <a
                      (click)="this.toggleShowPassportNumberRequiredModal()" role="button" class="whypass"
                      style="font-size:12px; font-style:italic">Why?</a></label>
                                    <input type="text" formControlName="passport" [attr.id]=" 'passport_' + i " required galPassengerFormTabIndex [galFormNumber]="1" [galCabinIndex]="i" [galPassengerIndex]="o" [galTabIndex]="8" class="form-control">
                                    <small class="help-block" *ngIf="passengers.get('passport').touched && passengers.get('passport').invalid">Passport is
                    Required</small>
                                </div>
                            </div>
                            <div style="border-top: 1px solid #fff;" *ngIf=" (passengers.get('birthMonth').touched )
              && ( passengers.get('birthday').touched )
              && ( passengers.get('birthYear').touched )
              && ( ( passengers.hasError('birthdateError') || passengers.hasError('notAllowedBirtdate') ||  passengers.hasError('notChild') )
              ) ">
                                <div class="alert alert-danger" role="alert" *ngIf="passengers.hasError('birthdateError') || passengers.hasError('notAllowedBirtdate')">
                                    Please review the passenger's date of birth.. Passengers must be at least {{ this.minAge }} years old.
                                </div>
                                <div class="alert alert-danger" role="alert" *ngIf="passengers.hasError('notChild')">
                                    According to the birthdate you selected, this passenger does not qualify as a child. Children must be up to {{ this.maxChildAge }} years old.
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr class="dotted">
                </div>
                <div formGroupName="purchaseContactPersonForm">
                    <h3>Who is the main contact person? </h3>
                    <div class="form-grp">
                        <div [attr.class]="this.purchasePassengerForm.get('purchaseContactPersonForm').get('purchasePassenger').touched && this.purchasePassengerForm.get('purchaseContactPersonForm').get('purchasePassenger').dirty && this.purchasePassengerForm.get('purchaseContactPersonForm').get('purchasePassenger').invalid ? 'has-error' : null">
                            <label for="purchasePassenger" class="control-label">Contact Person: </label>
                            <select formControlName="purchasePassenger" (change)="this.setIsOtherContactPerson( $event.target.value )" id="purchasePassenger" required class="form-control" tabindex="20001">
                <option
                  *ngFor="let purchaseContactPassengersListElement of this.purchaseContactPassengersListElements; let contactPassengerIndex = index; "
                  [value]="contactPassengerIndex">
                  {{
                  purchaseContactPassengersListElement.passengerIndex | showPassengerIndexInPassengerForm:purchaseContactPassengersListElement.cabinIndex | showCabinPassengerName:this.purchasePassengerForm.value.purchasePassengers[ purchaseContactPassengersListElement.cabinIndex ][ purchaseContactPassengersListElement.passengerIndex ].firstName:this.purchasePassengerForm.value.purchasePassengers[ purchaseContactPassengersListElement.cabinIndex ][ purchaseContactPassengersListElement.passengerIndex ].lastName:false  }}
                </option>
                <option value="{{ this.isOtherContactPersonValue }}">Other</option>
              </select>
                        </div>
                    </div>
                    <div class="form-grp">
                        <div style="margin-bottom: 0px;" [attr.class]="this.purchasePassengerForm.get('purchaseContactPersonForm').get('email').touched && this.purchasePassengerForm.get('purchaseContactPersonForm').get('email').invalid ? 'has-error' : null">
                            <label for="email" class="control-label">E-mail *:</label>
                        </div>
                        <div [attr.class]="this.purchasePassengerForm.get('purchaseContactPersonForm').get('email').touched && this.purchasePassengerForm.get('purchaseContactPersonForm').get('email').invalid ? 'has-error' : null">
                            <input type="text" formControlName="email" required id="email" class="form-control" tabindex="20002" style="padding-bottom: 13px;" (focusout)="this.addLogEmail()">
                            <small class="help-block" *ngIf="this.purchasePassengerForm.get('purchaseContactPersonForm').get('email').touched && this.purchasePassengerForm.get('purchaseContactPersonForm').get('email').invalid">Enter
                a valid E-mail address</small>
                        </div>
                    </div>
                    <div class="form-grp">
                        <div [attr.class]="this.purchasePassengerForm.get('purchaseContactPersonForm').get('phone').touched && this.purchasePassengerForm.get('purchaseContactPersonForm').get('phone').invalid ? 'has-error' : null">
                            <label for="phone" class="control-label">Telephone *:</label>
                        </div>
                        <div [attr.class]="this.purchasePassengerForm.get('purchaseContactPersonForm').get('phone').touched
          && this.purchasePassengerForm.get('purchaseContactPersonForm').get('phone').invalid ? 'has-error' : null">
                            <input type="text" formControlName="phone" required id="phone" class="form-control" tabindex="20003" style="padding-bottom: 13px;">
                            <small class="help-block" *ngIf="this.purchasePassengerForm.get('purchaseContactPersonForm').get('phone').touched
              && this.purchasePassengerForm.get('purchaseContactPersonForm').get('phone').invalid">Enter a valid
                Phone Number</small>
                        </div>
                    </div>
                    <div class="form-grp">
                        <div [attr.class]="this.purchasePassengerForm.get('purchaseContactPersonForm').get('agency').touched">
                            <label for="agency" class="control-label">Agency Name(if applicable):</label>
                        </div>
                        <div [attr.class]="this.purchasePassengerForm.get('purchaseContactPersonForm').get('agency').touched
           && this.purchasePassengerForm.get('purchaseContactPersonForm').get('agency').invalid ? 'has-error' : null">
                            <input type="text" formControlName="agency" id="agency" class="form-control" tabindex="20003" style="padding-bottom: 13px;">

                        </div>
                    </div>


                    <div class="form-grp" style="width: 96%;">
                        <div [attr.class]="this.purchasePassengerForm.get('purchaseContactPersonForm').get('agency').touched">
                            <label for="comments" class="control-label">Comments:</label>
                        </div>
                        <div [attr.class]="this.purchasePassengerForm.get('purchaseContactPersonForm').get('comments').touched
            && this.purchasePassengerForm.get('purchaseContactPersonForm').get('comments').invalid ? 'has-error' : null">
                            <input type="text" formControlName="comments" id="comments" class="form-control" tabindex="20004" style="padding-bottom: 13px;">

                        </div>
                    </div>

                    <div class="form-grp">
                        <div [attr.class]="this.purchasePassengerForm.get('purchaseContactPersonForm').get('howFindUs').touched">
                            <label for="howFindUs" class="control-label">How did you find us? (optional):</label>
                        </div>

                        <div [attr.class]="this.purchasePassengerForm.get('purchaseContactPersonForm').get('howFindUs').touched">
                            <select formControlName="howFindUs" class="form-control ">
                            <option [ngValue]="null ">Please select</option>
                            <option *ngFor="let howFindUs of howFindUsList " [value]="howFindUs ">{{ howFindUs }}
                            </option>
                          </select>

                        </div>
                    </div>
                    <div class="form-grp " *ngIf="this.purchasePassengerForm.get('purchaseContactPersonForm').get('howFindUs').value==='Other' ">
                        <div [attr.class]="this.purchasePassengerForm.get( 'purchaseContactPersonForm').get( 'valueOther').touched ">
                            <label for="valueOther " class="control-label ">Other:</label>
                        </div>
                        <div [attr.class]="this.purchasePassengerForm.get('purchaseContactPersonForm').get('valueOther').touched
            && this.purchasePassengerForm.get('purchaseContactPersonForm').get('valueOther').invalid ? 'has-error' : null">
                            <input type="text" formControlName="valueOther" id="valueOther" class="form-control" tabindex="20008" style="padding-bottom: 13px;">

                        </div>
                    </div>

                    <div>

                    </div>
                    <div *ngIf="this.isOtherContactPerson ">
                        <div class="form-grp ">
                            <div [attr.class]="this.purchasePassengerForm.get( 'purchaseContactPersonForm').get( 'firstName').touched && this.purchasePassengerForm.get( 'purchaseContactPersonForm').get( 'firstName').invalid ? 'has-error' : null ">
                                <label for="firstname " class="control-label ">First Name:</label>
                            </div>
                            <div [attr.class]="this.purchasePassengerForm.get( 'purchaseContactPersonForm').get( 'firstName').touched && this.purchasePassengerForm.get( 'purchaseContactPersonForm').get( 'firstName').invalid ? 'has-error' : null ">
                                <input type="text " formControlName="firstName " minlength="{{ this.namesMinLength }} " id="firstname " class="form-control " tabindex="20004 " style="padding-bottom: 13px; ">
                                <small class="help-block " *ngIf="this.purchasePassengerForm.get( 'purchaseContactPersonForm').get( 'firstName').touched && this.purchasePassengerForm.get( 'purchaseContactPersonForm').get( 'firstName').invalid ">First
                  Name is Required</small>
                            </div>
                        </div>
                        <div class="form-grp ">
                            <div [attr.class]="this.purchasePassengerForm.get( 'purchaseContactPersonForm').get( 'lastName').touched && this.purchasePassengerForm.get( 'purchaseContactPersonForm').get( 'lastName').invalid ? 'has-error' : null ">
                                <label for="lastname " class="control-label ">Last Name:</label>
                            </div>
                            <div [attr.class]="this.purchasePassengerForm.get( 'purchaseContactPersonForm').get( 'lastName').touched && this.purchasePassengerForm.get( 'purchaseContactPersonForm').get( 'lastName').invalid ? 'has-error' : null ">
                                <input type="text " formControlName="lastName " minlength="{{ this.namesMinLength }} " id="lastname " class="form-control " tabindex="20005 " style="padding-bottom: 13px; ">
                                <small class="help-block " *ngIf="this.purchasePassengerForm.get( 'purchaseContactPersonForm').get( 'lastName').touched && this.purchasePassengerForm.get( 'purchaseContactPersonForm').get( 'lastName').invalid ">Last
                  Name is Required</small>
                            </div>
                        </div>
                    </div>
                    <hr>
                </div>
                <div class="alert alert-danger " role="alert " *ngIf=" this.isShowErrorsSummary===true && this.purchasePassengerForm.invalid===true ">
                    Please review the form to continue.
                </div>
            </form>

            <a (click)="this.reset() " href="javascript:void(0) " class="reset ">[Reset options]</a>


            <a class="lngnext " href="javascript:void(0) " (click)="confirm( this.purchasePassengerForm.value.purchasePassengers, this.purchasePassengerForm.value.purchaseContactPersonForm ) ">
        {{companyParamsService.getVisiblepago()?"Next ": "Request Booking "}}
      </a>

            <!-- <a class="lngnext " (click)="nextThirdPart( this.purchasePassengerForm.value.purchasePassengers, this.purchasePassengerForm.value.purchaseContactPersonForm) ">Ignore and continue to Third Component</a> -->
        </div>
    </div>
    <app-end-slide [secondString]="this.activeEndSlide " [numberPage]="2 "></app-end-slide>
</div>

<gal-modal [visible]="this.showPassportNumberRequiredModal " [modalMinHeight]=" '100px' " [modalWidth]=" '30vw' " [modalTop]=" '40%' " (visibleChange)="this.showPassportNumberRequiredModal=false ">
    <table class="add-promo " style="margin-top:0px ">
        <tbody>
            <tr>
                <th style="text-align:left "><img src="assets/images/icons/big-edit.png ">Why is Passport Number required?</th>
            </tr>
            <tr class="add-data ">
                <td style="padding:10px; text-align: justify;">
                    <p>Immigration authorities of the destination country/departure port use the passport number to verify the traveler's eligibility for entry, including visa requirements and legal permissions.</p>
                </td>
            </tr>
        </tbody>
    </table>
</gal-modal>
