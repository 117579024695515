<div class="note-confirm confirmed">
    <p>Thank you for choosing us! <br> {{getThanYouText()}}
    </p>
</div>

<table class="table" style="margin-bottom: 40px;">
    <tbody>
        <tr>
            <td style=" width: 50%; "><strong>Cruise:</strong> {{ this.cruiseLongName }} </td>
            <td *ngIf="!this.purchaseRequestService.getPaymentMethod().transfer"><strong>Payment Code:</strong> {{ this.bookingTrackCode }}</td>
            <td *ngIf="this.purchaseRequestService.getPaymentMethod().transfer"><strong>Payment Method:</strong> Transfer</td>

        </tr>
        <tr>
            <td><strong>{{getInvoiceText()}}*: </strong>
                <span class="bigger-price">US$ {{
          this.totalWithoutThirtyPercent.toLocaleString('en', {maximumFractionDigits:2}) }}</span>
                <br>
                <small class="mininote">*Includes
          taxes and fees</small></td>
            <td><strong>{{getTextTotalPaid()}}: </strong><span class="bigger-price">US$ {{ this.totalPrice.toLocaleString('en',
          {maximumFractionDigits:2}) }}</span></td>
        </tr>
        <tr>
            <td><strong>Departure Date:</strong> {{ this.cruiseWrapper.getStartAndEndDataSummary() }}</td>
            <td><strong>Itinerary:</strong> ({{ this.cruiseItineraryDuration }})</td>
        </tr>

    </tbody>
</table>