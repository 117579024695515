import { CompanyParamsService } from './../../../services/companyParams/company-params.service';
import { NgWebflowService } from 'ngweflow';
import { PurchaseRequestStatus } from './../../../enums/purchase-request-status.enum';
import { CountryService } from 'src/app/core/services/country/country.service';
import { MonthDay } from 'src/app/models/month-day';
import { Gender } from 'src/app/enums/gender.enums';
import { Month } from 'src/app/models/month';
import { Component, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, FormArray, Validators, FormControl, RequiredValidator } from '@angular/forms';
import { Country } from 'src/app/models/country';
import { PurchaseRequestService } from '../../services/purchase-request/purchase-request.service';
import { MonthService } from 'src/app/core/services/month/month.service';
import { PurchaseCabin } from 'src/app/models/purchase-cabin';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { isNullOrUndefined } from 'util';
import { PassengerType } from 'src/app/enums/passenger-type.enum';
import { PurchaseCabinPassenger } from 'src/app/models/purchase-cabin-passenger';
import { PurchaseContactPerson } from 'src/app/ngGtcPayflow/models/purchase-contact-person';
import { PurchasePassengerInformation } from 'src/app/ngGtcPayflow/models/purchase-passenger-information';
import { CruiseService } from '../../services/cruise/cruise.service';
import * as $ from 'jquery';
import { UrlParamsService } from 'src/app/services/url-params.service';
import { environment } from 'src/environments/environment';
import { BookingConfirmationResponse } from 'src/app/models/purchase/booking-confirmation-response';
import { RoyalRequestService } from 'src/app/services/royal-request/royal-request.service';
import { PromoService } from '../../services/promo/promo.service';
import { Availability } from 'src/app/models/availability';
import { CodeProcesscrmEnum } from '../../../enums/code-process-crm.enum';
import { WebFlowAction } from 'src/app/enums/web-flow-action.enum';

interface PurchaseContactPassengerListElement { cabinIndex: number; passengerIndex: number; }

@Component({
  selector: "app-checkout-second",
  templateUrl: "./checkout-second.component.html",
  styleUrls: ["./checkout-second.component.css"],
})
export class CheckoutSecondComponent implements OnInit {
  public activeEndSlide: string;
  public isOtherContactPerson = false;
  public isOtherContactPersonValue = -1;
  public namesMinLength = 2;
  public months: Month[] = [];
  public genders: typeof Gender = Gender;
  public countries: Country[] = [];
  public years: number[] = [];
  public days: MonthDay[] = [];
  private numberOfPassengers: number;
  public purchasePassengerForm: FormGroup;
  public purchaseCabinInformation: string[];
  public purchaseContactPassengersListElements: PurchaseContactPassengerListElement[];
  public minAge: number;
  public maxChildAge: number;
  public isShowErrorsSummary = false;
  public isAutoPaymentAccepted: boolean;
  public showPassportNumberRequiredModal: boolean;
  // private static GENDER_DEFAULT_VALUE: string = 'undefined';
  testFormArrays: FormArray[] = [];
  cabinsNumber: PurchaseCabin[];
  monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  howFindUsList: string[] = [
    "Internet search",
    "Recommendation from friend / family",
    "Social media channels",
    "Other",
  ];
  public cruise: any;
  public startEndDates: string;
  public startEndDatesType: string;
  public promotion: string;
  public priceWithAirFare: number;
  public itineraryIndex: number;
  public cruiseStartDate: string;
  public boatImagesResource: string;
  public position: boolean;
  public cruiseItineraryDuration: string;
  public availavilitySelected: Availability;
  geoIp = "";
  urlElementsArray: string[] = [];

  constructor(
    private fb: FormBuilder,
    private monthService: MonthService,
    private countryService: CountryService,
    private router: Router,
    public purchaseRequestService: PurchaseRequestService,
    private cruiseService: CruiseService,
    private ngWebflowService: NgWebflowService,
    public companyParamsService: CompanyParamsService,
    private urlParamsService: UrlParamsService,
    private royalRequestService: RoyalRequestService,
    private promoService: PromoService
  ) {
    $(document).ready(function () {
      $("html, body").animate(
        {
          scrollTop: $("#startCheckoutSecond").offset().top,
        },
        1000
      );
    });
  }

  ngOnInit() {
    if (!isNullOrUndefined(this.purchaseRequestService.purchase.cruise)) {
      this.activeEndSlide = "active";
      this.royalRequestService.getGeoIpDirection().subscribe((ip) => {
        this.geoIp = ip;
      });
      this.numberOfPassengers =
        this.purchaseRequestService.getNumberOfPassengers();
      this.months = this.monthService.getMonths();
      this.years = this.generateYears();
      this.countries = this.countryService.getCountries();
      this.purchasePassengerForm = this.createPassengerForms();
      this.purchaseCabinInformation = this.getPurchaseCabinInformation();
      this.purchaseContactPassengersListElements =
        this.getPurchaseContactPassengersListElements();
      this.minAge = this.purchaseRequestService
        .getCruiseWrapper()
        .getEdadMinima();
      this.maxChildAge = this.purchaseRequestService
        .getCruiseWrapper()
        .getEdadNino();
      this.isAutoPaymentAccepted =
        this.purchaseRequestService.isAutoPaymentAccepted();
      this.showPassportNumberRequiredModal = false;

      const selecteIndex = 0;
      this.cruise = this.purchaseRequestService.purchase.cruise;
      this.cruiseStartDate =
        this.cruise.availability[this.cruise.iterIndex].date;
      this.availavilitySelected =
        this.cruise.availability[this.cruise.iterIndex];
      this.startEndDates =
        this.cruise.availability[this.cruise.iterIndex].startEndDates;
      this.startEndDatesType =
        this.cruise.availability[this.cruise.iterIndex].name;
      this.promotion =
        this.cruise.availability[this.cruise.iterIndex].promotion;

      this.itineraryIndex = selecteIndex;
      this.priceWithAirFare = this.cruiseService.getCruisePriceinNewFormat(
        this.cruise,
        this.itineraryIndex,
        true
      );
      this.cabinsNumber = this.purchaseRequestService.purchase.cabins;
      this.initializeBoatImageResource();
      this.position = true;
      this.cruiseItineraryDuration = this.purchaseRequestService
        .getCruiseWrapper()
        .getCruiseItineraryDuration();
    } else {
      window.location.href = window.location.href.substring(
        0,
        window.location.href.length - 1
      );
    }
  }

  createUrlLogoCruise() {
    return environment.app_resources.logoCruise + this.cruise.logo;
  }
  public getMonthDays(cabinIndex: number, passengerIndex: number): MonthDay[] {
    try {
      const birthMonth: number = this.purchasePassengerForm
        .get("purchasePassengers")
        .get(cabinIndex.toString())
        .get(passengerIndex.toString())
        .get("birthMonth").value as number;
      const birthYear: number = this.purchasePassengerForm
        .get("purchasePassengers")
        .get(cabinIndex.toString())
        .get(passengerIndex.toString())
        .get("birthYear").value as number;
      if (isNullOrUndefined(birthMonth) || isNullOrUndefined(birthYear)) {
        return [];
      }
      return UtilsService.generateMonthDays(birthMonth, birthYear);
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  private getPurchaseContactPassengersListElements(): PurchaseContactPassengerListElement[] {
    try {
      const purchaseContactPassengerListElements: PurchaseContactPassengerListElement[] =
        [];
      this.purchaseRequestService.purchase.cabins.forEach(
        (purchaseCabin: PurchaseCabin, cabinIndex: number) => {
          purchaseCabin.purchaseCabinPasssengers.forEach(
            (
              purchaseCabinPassenger: PurchaseCabinPassenger,
              passengerIndex: number
            ) => {
              if (purchaseCabinPassenger.type === PassengerType.ADULT) {
                purchaseContactPassengerListElements.push({
                  cabinIndex: Number(cabinIndex),
                  passengerIndex: Number(passengerIndex),
                });
              }
            }
          );
        }
      );
      return purchaseContactPassengerListElements;
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  private getPurchaseCabinInformation(): string[] {
    try {
      return this.purchaseRequestService
        .getPurchaseCabinObjectSummary()
        .map(
          (purchaseCabinObjectSummary: {
            cabinaItinerarioNombre: string;
            camaCabinaNombre: string;
          }) => {
            return (
              purchaseCabinObjectSummary.cabinaItinerarioNombre +
              " / " +
              purchaseCabinObjectSummary.camaCabinaNombre
            );
          }
        );
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  private createPassengerForms(): FormGroup {
    try {
      const formArrays: FormArray[] = [];
      this.purchaseRequestService.purchase.cabins.forEach(
        (purchaseCabin: PurchaseCabin) => {
          const formArrayForCabin: FormArray =
            this.createFormArrayForCabin(purchaseCabin);
          formArrays.push(formArrayForCabin);
        }
      );
      this.purchasePassengerForm = this.fb.group({
        purchasePassengers: new FormArray(formArrays),
        purchaseContactPersonForm: this.createContactForm(),
      });
      return this.purchasePassengerForm;
    } catch (error) {
      console.error(error);
      return null;
    }
  }
  get agencyValue() {
    return this.purchasePassengerForm
      .get("purchaseContactPersonForm")
      .get("agency").value;
  }

  get commentsValue() {
    return this.purchasePassengerForm
      .get("purchaseContactPersonForm")
      .get("comments").value;
  }
  get emailValue() {
    return this.purchasePassengerForm
      .get("purchaseContactPersonForm")
      .get("email").value;
  }

  get phoneValue() {
    return this.purchasePassengerForm
      .get("purchaseContactPersonForm")
      .get("phone").value;
  }
  get firstNameValue() {
    return this.purchasePassengerForm
      .get("purchaseContactPersonForm")
      .get("purchasePassenger").value;
  }

  private createFormArrayForCabin(purchaseCabin: PurchaseCabin): FormArray {
    try {
      const cabinFormGroup: FormGroup[] = [];
      const numberOfAdultsArray: number[] =
        UtilsService.getArrayOfNumbersByRange(0, purchaseCabin.adults - 1);
      const numberOfChildrenArray: number[] =
        UtilsService.getArrayOfNumbersByRange(0, purchaseCabin.children - 1);

      numberOfAdultsArray.forEach(() => {
        cabinFormGroup.push(this.createPassagerForm(PassengerType.ADULT));
      });

      numberOfChildrenArray.forEach(() => {
        cabinFormGroup.push(this.createPassagerForm(PassengerType.CHILD));
      });
      return new FormArray(cabinFormGroup);
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  private createPassagerForm(passengerType: PassengerType): FormGroup {
    try {
      return this.fb.group(
        {
          firstName: [
            null,
            [Validators.required, Validators.minLength(this.namesMinLength)],
          ],
          lastName: [
            null,
            [Validators.required, Validators.minLength(this.namesMinLength)],
          ],
          gender: [null, Validators.required],
          country: [null, Validators.required],
          passport: [null, Validators.required],
          birthday: [null, Validators.required],
          birthMonth: [null, Validators.required],
          birthYear: [null, Validators.required],
          passengerType: [passengerType, Validators.required],
        },
        { validator: this.birthDateValidator.bind(this) }
      );
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  private createContactForm(): FormGroup {
    try {
      let contactFormGroup: FormGroup;
      const purchaseContactPerson = {
        firstName: null as string,
        lastName: null as string,
        email: [null as string, [Validators.required, Validators.email]],
        phone: [null as string, Validators.required],
        agency: "",
        comments: "",
        howFindUs: "",
        valueOther: "",
        purchasePassenger: [0 as number, Validators.required],
      };
      contactFormGroup = this.fb.group(purchaseContactPerson);
      return contactFormGroup;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  private birthDateValidator(passengerForm: FormGroup): {
    notAllowedBirtdate?: boolean;
    notChild?: boolean;
    birthdateError?: boolean;
  } {
    try {
      if (
        isNullOrUndefined(passengerForm.get("birthYear").value) ||
        isNullOrUndefined(passengerForm.get("birthMonth").value) ||
        isNullOrUndefined(passengerForm.get("birthday").value)
      ) {
        return { birthdateError: true };
      }

      const selectedBirthDate: Date = UtilsService.getDate(
        passengerForm.get("birthYear").value,
        passengerForm.get("birthMonth").value,
        passengerForm.get("birthday").value
      );
      const childMinDate = this.purchaseRequestService
        .getCruiseWrapper()
        .getMinBirthDateForChild();
      const childMaxDate = this.purchaseRequestService
        .getCruiseWrapper()
        .getMaxBirthDateForChild();

      if (
        isNullOrUndefined(selectedBirthDate) ||
        isNullOrUndefined(childMinDate) ||
        isNullOrUndefined(childMaxDate)
      ) {
        return { birthdateError: true };
      }
      if (selectedBirthDate > childMinDate) {
        return { notAllowedBirtdate: true };
      } else if (
        passengerForm.get("passengerType").value === PassengerType.CHILD &&
        (selectedBirthDate > childMinDate || selectedBirthDate < childMaxDate)
      ) {
        return { notChild: true };
      }
      return null;
    } catch (error) {
      console.error(error);
      return { birthdateError: true };
    }
  }

  public goBack(): void {
    try {
      this.purchaseRequestService.purchaseRequestStatus =
        PurchaseRequestStatus.INITIAL;
    } catch (error) {
      console.error(error);
    }
  }

  public confirm(
    purchasePassengersInformation,
    purchaseContactPerson: {
      firstName: string;
      lastName: string;
      email: string;
      phone: string;
      agency: string;
      comments: string;
      purchasePassenger: number;
      howFindUs: string;
      valueOther: string;
    }
  ): void {
    try {
      if (this.purchasePassengerForm.invalid === true) {
        this.validateAllFormFields(this.purchasePassengerForm);
        this.testFormArrays = this.getPurchasePassengerFormControls();
        /**
         * implementacion de validacion de forms para los pasajeros
         */
        this.testFormArrays.forEach((element) => {
          const elementFormArray = element as FormArray;
          elementFormArray.controls.forEach((formgroupPassengers) => {
            const formgroupPasen: FormGroup = formgroupPassengers as FormGroup;
            this.validateAllFormFields(formgroupPasen);
          });
        });

        this.isShowErrorsSummary = true;
        return;
      }

      if (isNullOrUndefined(purchaseContactPerson.firstName)) {
        const row =
          this.purchaseContactPassengersListElements[
            purchaseContactPerson.purchasePassenger
          ].cabinIndex;
        const column =
          this.purchaseContactPassengersListElements[
            purchaseContactPerson.purchasePassenger
          ].passengerIndex;
        purchaseContactPerson.firstName =
          purchasePassengersInformation[Number(row)][Number(column)].firstName;
        purchaseContactPerson.lastName =
          purchasePassengersInformation[Number(row)][Number(column)].lastName;
      }
      this.purchaseRequestService.addPurchasePassengersInformation(
        purchasePassengersInformation
      );
      this.purchaseRequestService.addPurchaseContectPerson(
        this.validateContactPerson(
          purchaseContactPerson,
          purchasePassengersInformation
        )
      );
      this.purchaseRequestService.purchaseRequestStatus =
        PurchaseRequestStatus.PASSENGER_COMPLETED;
      const dataPassenger = {
        passengers: purchasePassengersInformation,
        contactPerson: purchaseContactPerson,
      };
      this.ngWebflowService.addWebFlowAction(
        WebFlowAction.FILL_PASSENGER_FORM,
        dataPassenger
      );
      this.ngWebflowService.addWebFlowAction(
               WebFlowAction.BOOKING_START,
               {message: 'booking start'},
      );
      if (this.companyParamsService.getVisiblepago()) {
        this.router.navigate([this.urlParamsService.createurlForNextPage("3")]);
      } else {
        this.requestBookingConfirmation();
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  private requestBookingConfirmation(): void {
    try {
      this.purchaseRequestService.requestInquireConfirm().subscribe(
        (bookingConfirmationResponse: BookingConfirmationResponse) => {
          if (
            this.purchaseRequestService.isRequestBookingConfirmationSuccess(
              bookingConfirmationResponse
            )
          ) {
            this.purchaseRequestService.purchaseRequestStatus =
              PurchaseRequestStatus.BOOCKING_CONFIRMATION_SENT;
            this.router.navigate([
              this.urlParamsService.createurlForNextPage("4"),
            ]);
          }
        },
        (error: any) => {
          this.ngWebflowService.addWebFlowAction(WebFlowAction.SEND_INQUIRE_FAILED, {
            result: "failed",
            response: error,
            body: {
              ...this.purchaseRequestService.preparePurchaseObjectToRequestBookingConfirmation(),
            },
          });
          console.error(error);
        },
        () => {
          this.ngWebflowService.addWebFlowAction(WebFlowAction.SEND_INQUIRE_SUCCESS, {
            result: "ok",
          });
        }
      );
    } catch (error) {
      console.log("BookTourComponent.requestbookingConfirm()");
      console.error(error);
      this.ngWebflowService.addWebFlowAction(WebFlowAction.SEND_INQUIRE_ERROR, {result: "error", response: error});
    }
  }

  public getPromoName(itineraryIndex: number): string {
    try {
      return (
        this.promoService.getPromoText(
          this.cruise.availability[itineraryIndex].promotionType,
          this.cruise.availability[itineraryIndex].promotionValue
        ) || ""
      );
    } catch (error) {
      console.log("ResultsComponent.getPromoName()");
      console.error(error);
      return null;
    }
  }

  getStringFormDatailRoyal(promoText: string): string {
    let product = this.royalRequestService.getNameBoatToRoyal(this.cruise.name);
    let resp = "none" + "/" + product + "/" + promoText + "/";
    this.urlElementsArray.push("none");
    this.urlElementsArray.push(product);
    this.urlElementsArray.push(promoText);
    // let resp = (services === "" ? "none" : services.substring(0, services.length - 1)) + "/" + product + "/" + promoText + "/";
    return resp;
  }

  // controla si el control es valido caso contrario lo pinta este metodo sirve para cualquier form

  public validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  public getCountries(): Country[] {
    try {
      return this.countryService.getCountries();
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  nextThirdPart() {
    this.router.navigate([this.urlParamsService.createurlForNextPage("3")]);
  }

  public isFormValid(): boolean {
    try {
      this.purchasePassengerForm
        .get("purchaseContactPersonForm")
        .get("purchasePassenger")
        .updateValueAndValidity();
      return this.purchasePassengerForm.valid;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  private validateContactPerson(
    purchaseContactPersonForm: {
      firstName: string;
      lastName: string;
      email: string;
      phone: string;
      agency: string;
      comments: string;
      howFindUs: string;
      valueOther: string;
      purchasePassenger: number;
    },
    purchasePassengersInformation: PurchasePassengerInformation[][]
  ): PurchaseContactPerson {
    try {

      const purchaseContactPerson: PurchaseContactPerson =
        this.createPurchaseContactPerson(purchaseContactPersonForm);
      if (purchaseContactPersonForm.purchasePassenger > -1) {
        const purchaseContactPassengerListElement: PurchaseContactPassengerListElement =
          this.purchaseContactPassengersListElements[
            purchaseContactPersonForm.purchasePassenger
          ];
        purchaseContactPerson.purchasePassengerInformation =
          purchasePassengersInformation[
            purchaseContactPassengerListElement.cabinIndex
          ][purchaseContactPassengerListElement.passengerIndex];
        purchaseContactPerson.firstName =
          purchaseContactPerson.purchasePassengerInformation.firstName;
        purchaseContactPerson.lastName =
          purchaseContactPerson.purchasePassengerInformation.lastName;
      }
      return purchaseContactPerson;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  createPurchaseContactPerson(purchaseContactPersonForm: {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    agency: string;
    comments: string;
    howFindUs: string,
    valueOther: string,
    purchasePassenger: number;
  }): PurchaseContactPerson {
    let purchaseContactPerson: PurchaseContactPerson;
    purchaseContactPerson = {
      firstName: purchaseContactPersonForm.firstName,
      lastName: purchaseContactPersonForm.lastName,
      phone: purchaseContactPersonForm.phone,
      agency: this.getCorrectValue(purchaseContactPersonForm.agency.trim()),
      remarks:this.getCorrectValue( purchaseContactPersonForm.comments.trim()),
      email: purchaseContactPersonForm.email,
      howFindUs: this.getCorrectValue(purchaseContactPersonForm.howFindUs),
      valueOther: this.getCorrectValue(purchaseContactPersonForm.valueOther),
      purchasePassengerInformation: null,
    };
    return purchaseContactPerson;
  }

  getCorrectValue(text: any) {
    return text && text !== "" ? text : "Not specific";
  }
  public trackByFn(index: any, item: any) {
    return index;
  }

  public setIsOtherContactPerson(selectedValue: any): void {
    try {
      this.isOtherContactPerson =
        Number(selectedValue) === this.isOtherContactPersonValue;
      if (Number(selectedValue) === this.isOtherContactPersonValue) {
        this.purchasePassengerForm
          .get("purchaseContactPersonForm")
          .get("firstName")
          .setValidators([
            Validators.required,
            Validators.minLength(this.namesMinLength),
          ]);
        this.purchasePassengerForm
          .get("purchaseContactPersonForm")
          .get("lastName")
          .setValidators([
            Validators.required,
            Validators.minLength(this.namesMinLength),
          ]);

        this.purchasePassengerForm
          .get("purchaseContactPersonForm")
          .get("firstName")
          .updateValueAndValidity();
        this.purchasePassengerForm
          .get("purchaseContactPersonForm")
          .get("lastName")
          .updateValueAndValidity();
      } else {
        this.purchasePassengerForm
          .get("purchaseContactPersonForm")
          .get("firstName")
          .setValidators(null);
        this.purchasePassengerForm
          .get("purchaseContactPersonForm")
          .get("lastName")
          .setValidators(null);

        this.purchasePassengerForm
          .get("purchaseContactPersonForm")
          .get("firstName")
          .updateValueAndValidity();
        this.purchasePassengerForm
          .get("purchaseContactPersonForm")
          .get("lastName")
          .updateValueAndValidity();
      }
    } catch (error) {
      console.error(error);
    }
  }

  public generateYears(): number[] {
    try {
      const years: number[] = [];
      const date = new Date();
      const startAt = date.getFullYear() - 100;

      const endAt =
        date.getFullYear() -
        this.purchaseRequestService.purchase.cruise.minimumAgeChild;
      // const endAt = date.getFullYear() - 10;

      for (let i = startAt; i <= endAt; i++) {
        years.push(i);
      }
      return years.sort((a, b) => b - a);
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  public getPurchasePassengerFormControls(): FormArray[] {
    try {
      return (
        this.purchasePassengerForm.controls.purchasePassengers as FormArray
      ).controls as FormArray[];
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  public toggleShowPassportNumberRequiredModal(): void {
    try {
      this.showPassportNumberRequiredModal =
        !this.showPassportNumberRequiredModal;
    } catch (error) {
      console.error(error);
    }
  }

  private initializeBoatImageResource(): void {
    this.boatImagesResource =
      environment.app_resources.logoCruise + this.cruise.image;
  }
  reset() {
    this.createPassengerForms();
    $(document).ready(function () {
      $("html, body").animate(
        {
          scrollTop: $("#startCheckoutSecond").offset().top,
        },
        1000
      );
    });
  }

     addLogEmail() {
      this.ngWebflowService.addWebFlowAction(
        WebFlowAction.MAIN_CONTACT_PERSON_EMAIL,
        { contactPersonEmail: this.purchasePassengerForm.value.purchaseContactPersonForm.email},
        true
        );
    }
}
