<div class="page">
  <div class="main">
    <h1>Server Error</h1>
    <div class="error-code">404 - {{this.counter}}</div>
    <h2>Page Not Found</h2>
    <p class="lead">This page either doesn't exist, or it moved somewhere else.</p>
    <hr>

    <p>That's what you can do</p>
    <div class="help-actions">
      <a href="javascript:returnOrigin()">Search Result Page</a>
    </div>
  </div>
</div>
<br>
<br>
<br>
