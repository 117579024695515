import { NgModule } from '@angular/core';
import { AfterViewInit, Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { GalavailServiceEcommer } from "clientwsGalavail";
import * as $ from "jquery";
import { NgWebflowService, ServerDateService } from "ngweflow";
import { Availability } from "src/app/models/availability";
import { PaymentMethod } from "src/app/models/payment-method";
import { PaypalPaymentResponse } from "src/app/models/paypal/paypal-payment-response";
import { Purchase } from "src/app/models/purchase";
import { BookingConfirmationResponse } from "src/app/models/purchase/booking-confirmation-response";
import { UnblockDeparture } from "src/app/models/purchase/unblock-departure";
import { TransactionResponseInterface } from "src/app/ngGtcPayflow/classes/transaction-response.interface";
import { RoyalRequestService } from "src/app/services/royal-request/royal-request.service";
import { UtilsService } from "src/app/shared/services/utils.service";
import { isNullOrUndefined } from "util";
import { environment } from "../../../../environments/environment";
import { CodeProcesscrmEnum } from "../../../enums/code-process-crm.enum";
import { PaymentMethodTransfer } from "../../../enums/payment-method-transfer.enum";
// import { ServerDateService } from '../../services/server-date/server-date.service';
import { ActionsLogsEnum } from "../../../enums/actions-logs.enum";
import { PaymentMethodTypeEnum } from "../../../enums/payment-methods-type.enum-";
import { ResponseOnHold } from "../../../enums/response-on-hold.enum";
import { PaymentsType } from "../../../models/payments-type";
import { IStripe } from "../../../models/request-stripe";
import { PaymentsTypeService } from "../../../services/payments-type/payments-type.service";
import { CheckoutStripeService } from "../../../shared/services/checkout-stripe.service";
import { ContactFormService } from "../../services/contact-form/contact-form.service";
import { CruiseService } from "../../services/cruise/cruise.service";
import { PromoService } from "../../services/promo/promo.service";
import { PurchaseRequestService } from "../../services/purchase-request/purchase-request.service";
import { PaymentType } from "./../../../enums/payment-type.enum";
import { PurchaseRequestStatus } from "./../../../enums/purchase-request-status.enum";
import { BlockDeparture } from "./../../../ngGtcPayflow/models/block-departure";
import { CompanyParamsService } from "./../../../services/companyParams/company-params.service";
import { UrlParamsService } from "./../../../services/url-params.service";
import { ReservationClass } from "src/app/classes/reservation.class";
declare let paypal: any;
import * as Cookies from "es-cookie";
import { WebFlowAction } from "src/app/enums/web-flow-action.enum";
import { PaymentOption } from '../payment-options/commons/parameters';
import { AuditStep, StripeService } from '../payment-options/services/stripe.service';


@Component({
  selector: "app-checkout-third",
  templateUrl: "./checkout-third.component.html",
  styleUrls: ["./checkout-third.component.css"],
})
export class CheckoutThirdComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  public isBlockDepartureSuccess: boolean;
  public activeEndSlide: string;
  public purchaseTotalPrice;
  public comment1: string;
  private isConsoleLog: boolean;
  public paymentType = PaymentType;
  public isPaymentComplete: boolean;
  public isPaymentCancel: boolean;
  public isPaymentError: boolean;
  public isProcessing: boolean = false;
  public cruise: any;
  public startEndDates: string;
  public startEndDatesType: string;
  public promotion: string;
  public priceWithAirFare: number;
  public itineraryIndex: number;
  public cruiseStartDate: string;
  public boatImagesResource: string;
  public isRequestingBlockDeparture: boolean;
  public position: boolean;
  public cruiseItineraryDuration: string;
  public isAutoPaymentAccepted: boolean;
  public minDepartureTimeToEnableAutoPayment: number;
  public termsCondition: boolean;
  public hasThirtyPercent: boolean;
  public valueTerms = "none";
  isSelectedThirtyPercent = false;
  allValuePrice: number;
  onlyThirtyPrice: number;
  paymentMethod: any;
  termsConditionPaytransfer: boolean;
  flagClick = false;
  availavilitySelected: Availability;
  geoIp = "";
  urlElementsArray: string[] = [];
  isProcessingStripe = false;
  successStripe: boolean = false;
  failureStripe = false;
  monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  counterSuccessAutomaticEmail: number = 0;
  paymentMethods: PaymentMethod[];
  reservationClass = new ReservationClass();

  public attemptPaymentStripe = 0;
  public typeErrorPaymentStripe = ResponseOnHold.INIT_ERROR;
  public paymentOption = PaymentOption.NONE;
  public paymentOptionEnum = PaymentOption;
  public paymentMethodTypeEnum = PaymentMethodTypeEnum;
  constructor(
    private router: Router,
    public purchaseRequestService: PurchaseRequestService,
    private galavailServiceEcommer: GalavailServiceEcommer,
    private cruiseService: CruiseService,
    private ngWebflowService: NgWebflowService,
    private serverDateService: ServerDateService,
    private contactFormService: ContactFormService,
    private urlParamsService: UrlParamsService,
    public companyParamsService: CompanyParamsService,
    private promoService: PromoService,
    private royalRequestService: RoyalRequestService,
    private paymentsTypeService: PaymentsTypeService,
    private checkoutStripeService: CheckoutStripeService,
    private stripeService: StripeService,

  ) {
    this.isConsoleLog = environment.console_log;
    this.hasThirtyPercent = false;
    this.paymentMethod = {};
    this.paymentMethod.paypal = false;
    this.paymentMethod.creditCard = false;
    this.paymentMethod.transfer = true;
    this.allValuePrice = 0;
    this.onlyThirtyPrice = 0;
    $(document).ready(function () {
      $("html, body").animate(
        {
          scrollTop: $("#startCheckoutThird").offset().top,
        },
        1000
      );
    });
    this.royalRequestService.getGeoIpDirection().subscribe((ip) => {
      this.geoIp = ip;
    });
  }

  ngOnInit() {
    this.counterSuccessAutomaticEmail = 0;
    if (!isNullOrUndefined(this.purchaseRequestService.purchase.cruise)) {
      this.isOnTimeToPay();
      this.activeEndSlide = "active";
      this.isSelectedThirtyPercent = false;
      this.purchaseRequestService.setIsSelectedThirtyPerent(
        this.isSelectedThirtyPercent
      );
      const selecteIndex = 0;
      this.paymentMethods = this.companyParamsService.getPaymentMethods();
      this.paymentMethods.forEach((item, index) => {
        item.selected = false;
      });
      this.cruise = this.purchaseRequestService.purchase.cruise;
      this.availavilitySelected = this.cruise.availability[selecteIndex];
      this.purchaseTotalPrice = this.purchaseRequestService.getTotalPrice();
      this.allValuePrice = this.purchaseRequestService.getTotalPrice();
      this.onlyThirtyPrice = Math.round(
        this.allValuePrice *
          (this.companyParamsService.getPercentPartialPayment() / 100)
      );
      this.cruiseStartDate =
        this.cruise.availability[this.cruise.iterIndex].date;
      this.startEndDates =
        this.cruise.availability[this.cruise.iterIndex].startEndDates;
      this.startEndDatesType =
        this.cruise.availability[this.cruise.iterIndex].name;
      this.promotion =
        this.cruise.availability[this.cruise.iterIndex].promotion;
      this.itineraryIndex = selecteIndex;
      this.priceWithAirFare = this.cruiseService.getCruisePriceinNewFormat(
        this.cruise,
        this.itineraryIndex,
        true
      );
      this.purchaseRequestService.setPaymentMethod(this.paymentMethod);
      this.initializeBoatImageResource();
      this.position = true;
      this.cruiseItineraryDuration = this.purchaseRequestService
        .getCruiseWrapper()
        .getCruiseItineraryDuration();
      this.isAutoPaymentAccepted =
        this.purchaseRequestService.isAutoPaymentAccepted();

      this.minDepartureTimeToEnableAutoPayment =
        environment.PURCHASE.MIN_TIME_TO_ENABLE_AUTOPAYMENT;
    } else {
      window.location.href = window.location.href.substring(
        0,
        window.location.href.length - 1
      );
    }
  }
  ngAfterViewInit(): void {
    if (this.isAutoPaymentAccepted === true) {
      this.renderPaypalButton("paypal-button-payment", false, "paypal");

      // this.renderPaypalButton('paypal-button-funding-buttons', true, 'buynow');
    }
  }
  ngOnDestroy(): void {
    /*
    this.isSelectedThirtyPercent = false;
    this.purchaseRequestService.setIsSelectedThirtyPerent(
      this.isSelectedThirtyPercent
    );
    this.purchaseTotalPrice = this.purchaseRequestService.getTotalPrice();*/
  }
  createUrlLogoCruise() {
    return environment.app_resources.logoCruise + this.cruise.logo;
  }
  nextFourthPart() {
    this.router.navigate([this.urlParamsService.createurlForNextPage("4")]);
  }
  onErrorCreditCardPayment(event) {}
  alertTerms() {
    alert("Please accept terms and conditions");
  }

  /** Este metodo esta comentado debido a q sirve unicamente para probar el servicio de bloque*/
  public async blockCabinNewjson(test?) {
    this.isProcessing = true;
    try {
      await this.setTransactionCode();
    } catch (error) {
      return new Error("err");
    }
    this.purchaseRequestService
      .requestBlockCabinOnHold(this.purchaseRequestService.getPercentUsed())
      .subscribe(
        (blockDepartureResponse: BlockDeparture) => {
          const isblockDeparture: boolean =
            this.purchaseRequestService.setBlockDeparture(
              blockDepartureResponse
            );
          this.isBlockDepartureSuccess =
            isblockDeparture === true &&
            this.purchaseRequestService.isRequestBlockDepartureSuccess();
          if (this.isBlockDepartureSuccess === false) {
            this.cabinWasReservedOrHttpError(ResponseOnHold.RESERVED_ERROR);
            this.isBlockDepartureFailed();
          } else {
            //this.requestPostSpaces();
            this.isPaymentComplete = true;
            this.isPaymentCancel = false;
            this.isPaymentError = false;
            this.purchaseRequestService.purchaseRequestStatus =
              PurchaseRequestStatus.PAYMENT_COMPLETED;
            this.addlog(
              WebFlowAction.EXECUTING_PAYMENT_SUCCESS,
              //"completed",
              "ok",
              );
            this.requestBookingConfirmation();
          }
          this.isRequestingBlockDeparture = false;
        },
        (error) => {
          this.isRequestingBlockDeparture = false;
          this.isBlockDepartureSuccess = false;
          this.addlog(
            WebFlowAction.ON_HOLD,
            //"error",
            error
            );
          console.log("PaymentOptionsComponent.blockDeparture()");
          console.error(error);
          this.isBlockDepartureFailed();
          this.cabinWasReservedOrHttpError(ResponseOnHold.HTTP_ERROR);
        },
        () => {
          this.isRequestingBlockDeparture = false;
        }
      );
  }

  async setTransactionCode() {
    try {
      const response: any = await this.purchaseRequestService
        .requestStatusAvailability(this.purchaseRequestService.getPercentUsed())
        .toPromise();

      this.purchaseRequestService.setValueTransaction(response.message);
    } catch (error) {
      this.errorProcess(error);
      throw new ReferenceError("falied code transaction");
    }
  }

  errorProcess(error: any) {
    this.isRequestingBlockDeparture = false;
    this.isBlockDepartureSuccess = false;
    console.log("PaymentOptionsComponent.blockDeparture()");
    console.error(error);
    this.isBlockDepartureFailed();
    this.cabinWasReservedOrHttpError(ResponseOnHold.HTTP_ERROR);
  }

  async requestRoyalForm(isVoerTime: boolean) {
    if (this.companyParamsService.getName() === "Royal Galapagos") {
      this.isProcessing = true;

      this.addlog(
        WebFlowAction.SEND_CRM_REQUEST_START, this.reservationClass.formReservation );
      this.getUrlRequest(isVoerTime);
      this.royalRequestService.requestPost(this.reservationClass.formReservation).subscribe(
        (err) => {
          this.addlog(
            WebFlowAction.SEND_CRM_REQUEST_FAILED,
            //"crm",
            err
            );
          this.nextFourthPart();
        },
        () => {
          this.addlog(
            WebFlowAction.SEND_CRM_REQUEST_SUCCESS,
            //"sent",
            { result: "OK-fromPaymentOptions", json: this.reservationClass.formReservation },

          );
          this.nextFourthPart();
        }
      );
    } else {
      this.nextFourthPart();
    }
  }

  async requestRoyalFormOverTime(isOVerTime: boolean) {
    if (this.companyParamsService.getName() === "Royal Galapagos") {
      this.getUrlRequest(isOVerTime);
      this.royalRequestService.requestPost(this.reservationClass.formReservation).subscribe(
        (err) => {
          this.ngWebflowService.addWebFlowAction(WebFlowAction.SEND_CRM_REQUEST_FAILED, {
            result: err,
            json: this.reservationClass.formReservation,
          });
        },
        () => {
          this.ngWebflowService.addWebFlowAction(WebFlowAction.SEND_CRM_REQUEST_SUCCESS, {
            result: "OK-fromPaymentOptions",
            json: this.reservationClass.formReservation,
          });
        }
      );
    }
  }
  getUrlRequest(isOVerTime: boolean): string {
    let cruiseIndex = this.cruise.iterIndex;
    const startDate = new Date(this.cruise.availability[cruiseIndex].startDate);
    const endDate = new Date(this.cruise.availability[cruiseIndex].endDate);
    const promoText =
      this.getPromoName(cruiseIndex) === "0"
        ? "Regular Rate"
        : this.getPromoName(cruiseIndex);
    const newLetter = "0";

    this.urlElementsArray = [];
    let urlFinal = environment.pathCRMroyal;

    this.getStringFormDatailRoyal(promoText);

    this.getStringTravelDataRoyal(startDate, endDate, isOVerTime);
    this.getInterestDataRoyal();
    this.getContactInfoRoyal();
    this.setUtms();
    this.urlElementsArray.push(newLetter);
    this.reservationClass.formReservation.intnews = newLetter;

    this.urlElementsArray.push(this.geoIp);
    this.reservationClass.formReservation.varip = this.geoIp;

    this.setLastFourItmes(isOVerTime);
    const urlEscapedElementsArray = this.urlElementsArray.map(
      (urlElement: string) => {
        return encodeURIComponent(urlElement);
      }
    );

    urlFinal += urlEscapedElementsArray.join("/");
    return urlFinal;
  }
  setLastFourItmes(isOverTime: boolean) {
    this.urlElementsArray.push(this.isMobile());
    this.reservationClass.formReservation.device = this.isMobile();
    this.urlElementsArray.push("Not specific");
    this.urlElementsArray.push("Not specific");
    this.reservationClass.formReservation.codeType = isOverTime
      ? CodeProcesscrmEnum.RG_NP_AV
      : CodeProcesscrmEnum.RG_VECOM;
    this.urlElementsArray.push(
      isOverTime ? CodeProcesscrmEnum.RG_NP_AV : CodeProcesscrmEnum.RG_VECOM
    );
  }

  isMobile() {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return "Tablet";
    } else if (
      /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        ua
      )
    ) {
      return "Mobile";
    } else {
      return "Desktop";
    }
  }

  getStringTravelDataRoyal(
    startDate: Date,
    endDate: Date,
    isOVerTime: boolean
  ): string {
    let arrivalDate = "";
    let departureDate = "";
    let daysCruise = this.availavilitySelected.length.substring(0, 1);
    let optionCruise = this.availavilitySelected.name
      .substring(this.availavilitySelected.name.length, 1)
      .trim();
    let numberAdults = this.purchaseRequestService.getNumberOfAdults();
    let numberChild = this.purchaseRequestService.getNumberOfChildren();
    let comments =
      this.purchaseRequestService.getPurchaseContactPerson().remarks === ""
        ? "None specific"
        : this.purchaseRequestService.getPurchaseContactPerson().remarks;
    const dayString =
      startDate.getDate() < 10
        ? "0" + String(startDate.getDate())
        : String(startDate.getDate());
    const endDayString =
      endDate.getDate() < 10
        ? "0" + String(endDate.getDate())
        : String(endDate.getDate());

    arrivalDate = `${
      this.monthNames[startDate.getMonth()]
    } ${dayString}, ${startDate.getFullYear()}`;
    departureDate = `${
      this.monthNames[endDate.getMonth()]
    } ${endDayString}, ${endDate.getFullYear()}`;
    const automaticTextNotification = isOVerTime
      ? "WITHIN 72 HOURS"
      : "PAYMENT IN PROCESS";
    this.urlElementsArray.push(arrivalDate);
    this.reservationClass.formReservation.traveldate1 = arrivalDate;
    this.urlElementsArray.push(departureDate);
    this.reservationClass.formReservation.traveldate2 = departureDate;
    this.urlElementsArray.push(daysCruise);
    this.reservationClass.formReservation.daycruise = daysCruise;
    this.urlElementsArray.push(optionCruise);
    this.reservationClass.formReservation.opccruise = optionCruise;
    this.urlElementsArray.push(String(numberAdults));
    this.reservationClass.formReservation.numberadult = String(numberAdults);
    this.urlElementsArray.push(String(numberChild));
    this.reservationClass.formReservation.numberchild = String(numberChild);
    this.urlElementsArray.push(`${comments}-${automaticTextNotification}`);
    this.reservationClass.formReservation.comments = `${comments}-${automaticTextNotification}`;
    this.urlElementsArray.push("en");
    this.reservationClass.formReservation.languaje = "en";

    return `${arrivalDate}/${departureDate}/${daysCruise}/${optionCruise}/${numberAdults}/${numberChild}/${comments}/en/`;
  }

  setUtms() {
    // START SET UTMS
    this.reservationClass.formReservation.utm.utmsource =
      this.getCokieValue("source");
    this.reservationClass.formReservation.utm.utmmedium =
      this.getCokieValue("medium");
    this.reservationClass.formReservation.utm.utmcampaign =
      this.getCokieValue("campaign");
    this.reservationClass.formReservation.utm.utmterm =
      this.getCokieValue("term");
    this.reservationClass.formReservation.utm.utmadg =
      this.getCokieValue("adgroup");
    this.reservationClass.formReservation.utm.utmmtp =
      this.getCokieValue("matchtype");
    this.reservationClass.formReservation.utm.utmcon =
      this.getCokieValue("content");
    this.reservationClass.formReservation.utm.utmad = this.getCokieValue("ad");
    this.reservationClass.formReservation.utm.utmkey =
      this.getCokieValue("keyword");
      this.reservationClass.formReservation.utm.utmext =
      this.getCokieValue("extension");
      this.reservationClass.formReservation.utm.utmplace =
      this.getCokieValue("placement");
    this.reservationClass.formReservation.utm.urlorigen =
      this.getUrlOrigenCokieValue("url_origen");
    // END SET UTMS
  }

  getCokieValue(value: string) {
    return Cookies.get(value) === undefined || Cookies.get(value) === null
      ? "none"
      : Cookies.get(value);
  }

  getUrlOrigenCokieValue(value: string) {
    return Cookies.get(value) === undefined || Cookies.get(value) === null
      ? window.location.href
      : Cookies.get(value);
  }
  getContactInfoRoyal(): string {
    let fName =
      this.purchaseRequestService.getPurchaseContactPerson().firstName;
    let lName = this.purchaseRequestService.getPurchaseContactPerson().lastName;
    let email = this.purchaseRequestService.getPurchaseContactPerson().email;
    let phone =
      isNullOrUndefined(
        this.purchaseRequestService.getPurchaseContactPerson().phone
      ) || this.purchaseRequestService.getPurchaseContactPerson().phone === ""
        ? "Not specific"
        : this.purchaseRequestService.getPurchaseContactPerson().phone;
    let country =
      this.purchaseRequestService.getPurchasePassengersInformation()[0].country;
    let agency =
      this.purchaseRequestService.getPurchaseContactPerson().agency === ""
        ? "Not specific"
        : this.purchaseRequestService.getPurchaseContactPerson().agency;
    let reference = this.purchaseRequestService.getPurchaseContactPerson()
      .lastName
      ? this.purchaseRequestService.getPurchaseContactPerson().lastName
      : "Not specific";
    this.urlElementsArray.push(fName);
    this.reservationClass.formReservation.firstname = fName;
    this.urlElementsArray.push(lName);
    this.reservationClass.formReservation.lastname = lName;
    this.urlElementsArray.push(email);
    this.reservationClass.formReservation.email = email;
    this.urlElementsArray.push(phone);
    this.reservationClass.formReservation.phone = phone;
    this.urlElementsArray.push(country);
    this.reservationClass.formReservation.country = country;
    this.urlElementsArray.push(agency);
    this.reservationClass.formReservation.agencyname = agency;
    this.urlElementsArray.push(reference);
    this.reservationClass.formReservation.referencename = reference;
    this.cruise.availability[this.itineraryIndex].aditionalServices.forEach((additionalService)=>{
      this.reservationClass.formReservation.additionalServices.push(additionalService.replace("+ ",""))
    });
    this.reservationClass.formReservation.pricePerPerson = this.purchaseRequestService.getPricePerPerson().toString();


    return `${fName}/${lName}/${email}/${phone}/${country}/${agency}/${reference}/`;
  }
  getInterestDataRoyal(): string {
    let interestString = "";
    interestString = "0/0/0/0/";
    this.urlElementsArray.push("0");
    this.urlElementsArray.push("0");
    this.urlElementsArray.push("0");
    this.urlElementsArray.push("0");

    return interestString;
  }

  public getPromoName(itineraryIndex: number): string {
    try {
      return (
        this.promoService.getPromoText(
          this.cruise.availability[itineraryIndex].promotionType,
          this.cruise.availability[itineraryIndex].promotionValue
        ) || ""
      );
    } catch (error) {
      console.log("ResultsComponent.getPromoName()");
      console.error(error);
      return null;
    }
  }
  getStringFormDatailRoyal(promoText: string): string {
    let product = this.royalRequestService.getNameBoatToRoyal(this.cruise.name);
    let resp = "none" + "/" + product + "/" + promoText + "/";
    this.urlElementsArray.push("none");
    this.reservationClass.formReservation.services = "none";
    this.urlElementsArray.push(product);
    this.reservationClass.formReservation.product = product;
    this.urlElementsArray.push(promoText);
    this.reservationClass.formReservation.promotion = promoText;
    // let resp = (services === "" ? "none" : services.substring(0, services.length - 1)) + "/" + product + "/" + promoText + "/";
    return resp;
  }

  private requestBookingConfirmation(): void {
    try {
      this.isProcessing = true;
      this.purchaseRequestService.requestBookingConfirmation().subscribe(
        (bookingConfirmationResponse: BookingConfirmationResponse) => {
          if (
            this.purchaseRequestService.isRequestBookingConfirmationSuccess(
              bookingConfirmationResponse
            )
          ) {
            this.purchaseRequestService.purchaseRequestStatus =
              PurchaseRequestStatus.BOOCKING_CONFIRMATION_SENT;

            this.requestRoyalForm(false);
          }
        },
        (error: any) => {
          this.isProcessing = false;
          console.log("BookTourComponent.requestbookingConfirm()");
          console.error(error);
          this.addlog(
            WebFlowAction.SEND_CONFIRMATION_EMAIL,
            //"error",
            { ...this.purchaseRequestService.preparePurchaseBookingConfirm() },

          );
        },
        () => {
          this.isProcessing = false;
        }
      );
    } catch (error) {
      console.log("BookTourComponent.requestbookingConfirm()");
      console.error(error);
    }
  }
  selectThirtyPercent() {
    this.isSelectedThirtyPercent = !this.isSelectedThirtyPercent;
    this.purchaseRequestService.setIsSelectedThirtyPerent(
      this.isSelectedThirtyPercent
    );
    this.purchaseTotalPrice = this.purchaseRequestService.getTotalPrice();
  }

  changeTermsPayTransfer(eventValue) {
    this.termsConditionPaytransfer = eventValue;
    this.valueTerms = eventValue ? "all" : "none";
    this.ngWebflowService.addWebFlowAction(WebFlowAction.CLICK_TERMS_CONDITIONS, {
      termsConditionPaytransfer: this.termsConditionPaytransfer,
    });
  }

  private isOnTimeToPay(): void {
    try {
      this.serverDateService.requestServerDateAsString().subscribe(
        (serverDate: string) => {
          // const departureDeta: Date = new Date(this.cruiseWrapper.getCurrentItinerary()
          const departureDeta: Date = new Date(
            this.purchaseRequestService
              .getCruiseWrapper()
              .getCurrentItinerary().startDate
          );
          const msDifference =
            Number(departureDeta.getTime()) - Number(serverDate);
          const hoursDifference =
            UtilsService.millisecondsToHours(msDifference);
          const daysDifference = UtilsService.millisecondsToDays(msDifference);
          this.hasThirtyPercent =
            daysDifference < this.minDepartureTimeToEnableAutoPayment
              ? false
              : true;

          if (hoursDifference < this.minDepartureTimeToEnableAutoPayment) {
            this.isAutoPaymentAccepted = false;
            this.sendEmail();
          } else {
            this.isAutoPaymentAccepted = true;
          }
        },
        (error: any) => {
          console.error(error);
          this.isAutoPaymentAccepted = false;
        },
        () => {}
      );
    } catch (error) {
      console.error(error);
      this.isAutoPaymentAccepted = false;
    }
  }

  private sendEmail() {
    const myPurchase = this.purchaseRequestService.purchase as Purchase;
    console.log("--->sending automatic", myPurchase);
    this.ngWebflowService.addWebFlowAction(WebFlowAction.SENDING_AUTOMATIC_START, this.fillRequestDatatoInquire(myPurchase) );
    this.galavailServiceEcommer
      .sendInquireEmail(this.fillRequestDatatoInquire(myPurchase))
      .subscribe(
        (response) => {
          const result: boolean =
            this.contactFormService.isSendFormResponseSuccess(response);
          this.ngWebflowService.addWebFlowAction(WebFlowAction.SENDING_AUTOMATIC_SUCCESS, {
            result: {
              actionStatus: result,
              purchaseStatus: this.purchaseRequestService.purchaseRequestStatus,
            },
          });
          this.requestRoyalFormOverTime(true);
        },
        (errorResponse) => {
          this.ngWebflowService.addWebFlowAction(WebFlowAction.SENDING_AUTOMATIC_FAILED, {
            result: {
              actionStatus: true,
              purchaseStatus: this.purchaseRequestService.purchaseRequestStatus,
            },
          });
          console.error(errorResponse);
        }
      );
  }

  changePaymentMethod(i: number) {
    this.termsConditionPaytransfer = false;
    this.flagClick = false;
    this.paymentMethods.forEach((item, index) => {
      if (index === i) {
        item.selected = true;
      } else {
        item.selected = false;
      }
    });
    const selectedCodePayment: PaymentMethod = this.paymentMethods[i];
    if (
      selectedCodePayment.type === PaymentMethodTransfer.TYPE_TRANSFER_EC ||
      selectedCodePayment.type === PaymentMethodTransfer.TYPE_TRANSFER_US ||
      selectedCodePayment.type === PaymentMethodTransfer.DIRECT_TRANSFER
    ) {
      this.paymentMethod.transfer = true;
      this.paymentMethod.creditCard = false;
      this.paymentMethod.paypal = false;
    } else {
      this.paymentMethod.transfer = false;
      this.paymentMethod.creditCard = false;
      this.paymentMethod.paypal = false;
    }
    this.purchaseRequestService.setCodePaymentMethod(selectedCodePayment.code);
    this.purchaseRequestService.setPaymentMethod(this.paymentMethod);
  }

  fillRequestDatatoInquire(purchase: Purchase): any {
    const startDate = purchase.cruise.availability[0].startDate.replace(
      " 12:00:00 AM",
      ""
    );
    const endDate = purchase.cruise.availability[0].endDate.replace(
      " 12:00:00 AM",
      ""
    );
    const objectResquest = {
      emailReceptor:
        this.companyParamsService.getCompanyParams().emailEcommerce,
      subject: "New Request",
      inquireDTO: {
        company: this.companyParamsService.getName(),
        boat: purchase.cruise.name,
        promotion: this.promoService.getPromoText(
          purchase.cruise.availability[0].promotionType,
          purchase.cruise.availability[0].promotionValue
        ),
        startDate,
        endDate,
        days: purchase.cruise.availability[0].length,
        opcDays: purchase.cruise.availability[0].name,
        adults: this.purchaseRequestService.getNumberOfAdults(),
        childs: this.purchaseRequestService.getNumberOfChildren(),
        commnets:
          "Given that your cruise will take place within the next 72 hours",
        interestFlights: true,
        interestAmazon: true,
        interestPeru: true,
        interestEcuador: true,
        lastName: purchase.purchaseContactPerson.lastName,
        firstName: purchase.purchaseContactPerson.firstName,
        email: purchase.purchaseContactPerson.email,
        phone: purchase.purchaseContactPerson.phone,
        country: "",
        interestPromos: false,
        reference: this.purchaseRequestService.getPurchaseContactPerson()
          .lastName
          ? this.purchaseRequestService.getPurchaseContactPerson().lastName
          : "Not specific",
        howFindUs: purchase.purchaseContactPerson.howFindUs,
        valueOther: purchase.purchaseContactPerson.valueOther,
        ipSender: this.geoIp,
      },
      company: {
        logoCompany: this.companyParamsService.getCompanyParams().logo,
        nameCompany: this.companyParamsService.getName(),
        mainColorCompany: this.companyParamsService.getMainColor(),
        secondaryColorCompany: this.companyParamsService.getSecundaryColor(),
        headerColorCompany: this.companyParamsService.getHeaderColor(),
        footerColorCompany: this.companyParamsService.getFooterColor(),
        infoMailCompany: this.companyParamsService.getInfoEmail(),
        phoneCompany: this.companyParamsService.getPhone(),
        webSiteCompany: this.companyParamsService.getWebSite(),
      },
    };
    return objectResquest;
  }
  public paymentComplete(paymentResponse: any, paymentType: PaymentType): void {
    try {
      this.isProcessing = true;
      // this.requestPostSpaces();
      this.purchaseRequestService.addPaymentResponse(
        paymentResponse,
        paymentType
      );
      this.isPaymentComplete = true;
      this.isPaymentCancel = false;
      this.isPaymentError = false;

      if (paymentType === PaymentType.PAYPAL) {
        this.setBookingTrackCode(paymentResponse.id);
      } else if (paymentType === PaymentType.payflowCreditCard) {
        const PayflowTransactionResponse: TransactionResponseInterface =
          paymentResponse as TransactionResponseInterface;
        this.setBookingTrackCode(PayflowTransactionResponse.getId());
      }

      this.purchaseRequestService.purchaseRequestStatus =
        PurchaseRequestStatus.PAYMENT_COMPLETED;
      const paymentData = {
        paymentResponse: paymentResponse,
        paymentType: paymentType,
      };
      this.addlog(
        WebFlowAction.EXECUTING_PAYMENT_COMPLETED,
        //"payment_completed",
        paymentData
      );
      this.requestBookingConfirmation();
    } catch (error) {
      this.isProcessing = false;
      console.error(error);
    }
  }

  private setBookingTrackCode(bookingTrackCode: string): void {
    try {
      this.purchaseRequestService.setTrackingCode(bookingTrackCode);
    } catch (error) {
      console.error();
    }
  }
  private initPayment(): void {}

  renderPaypalButton(
    htmlElementId: string,
    includeFundingButtons: boolean,
    label: string
  ): void {
    try {
      this.isProcessing = false;
      const paypalRequestObject: any = {
        style: this.getPayPalButtonStyleObject(includeFundingButtons, label),
        env: this.companyParamsService.getPayPalInfo().apiMode,
        client: {
          sandbox: this.companyParamsService.getPayPalInfo().clientSandboxToken,
          production:
            this.companyParamsService.getPayPalInfo().clientProductionToken,
        },
        funding: this.getPaypalFundingMethodsObject(),
        commit: true,
        payment: (data, actions) => {
          this.initPayment();
          return actions.payment.create(this.getPaypalPaymentObject());
        },
        onAuthorize: (data: any, actions: any) => {
          return actions.payment
            .get()
            .then((approvedPaymentResponse: PaypalPaymentResponse) => {
              this.isRequestingBlockDeparture = true;

              this.isProcessing = true;
              this.purchaseRequestService
                .requestBlockCabinOnHold(
                  this.purchaseRequestService.getPercentUsed()
                )
                .subscribe(
                  (blockDepartureResponse: BlockDeparture) => {
                    const isblockDeparture: boolean =
                      this.purchaseRequestService.setBlockDeparture(
                        blockDepartureResponse
                      );
                    this.isBlockDepartureSuccess =
                      isblockDeparture === true &&
                      this.purchaseRequestService.isRequestBlockDepartureSuccess();
                    if (this.isBlockDepartureSuccess === false) {
                      this.isBlockDepartureFailed();
                    } else {
                      return actions.payment.execute().then(
                        (executedPaymentResponse: PaypalPaymentResponse) => {
                          this.paymentComplete(
                            executedPaymentResponse,
                            PaymentType.PAYPAL
                          );
                        },
                        (error) => {
                          this.isRequestingBlockDeparture = false;
                          this.isBlockDepartureSuccess = false;
                          console.error(error);
                          this.isBlockDepartureFailed();
                        }
                      );
                    }
                    this.isRequestingBlockDeparture = false;
                  },
                  (error) => {
                    this.isRequestingBlockDeparture = false;
                    this.isBlockDepartureSuccess = false;
                    this.isProcessing = false;
                    console.log("PaymentOptionsComponent.blockDeparture()");
                    console.error(error);
                    this.isBlockDepartureFailed();
                  },
                  () => {
                    this.isRequestingBlockDeparture = false;
                  }
                );
            });
        },
        onCancel: (data, actions) => {
          this.paymentCanceled(data, actions);
        },
        onError: (error) => {
          this.paymentError(error);
        },
      };

      if (this.isConsoleLog) {
        console.log("paymentOptions.renderPaypalButton(): Request Object");
        console.log(paypalRequestObject);
      }

      paypal.Button.render(paypalRequestObject, "#" + htmlElementId);
    } catch (error) {
      console.log("PaymentOptionsCompoent.renderPaypalButton()");
      console.error(error);
    }
  }
  isProcessingMethod(event: any) {
    this.isProcessing = true;
  }
  private paymentError(error: any) {
    try {
      this.isPaymentComplete = false;
      this.isPaymentCancel = false;
      this.isPaymentError = true;
      if (
        this.purchaseRequestService.isRequestBlockDepartureSuccess() === true
      ) {
        this.unblockDeparture();
      }
      this.ngWebflowService.addWebFlowAction(WebFlowAction.EXECUTING_PAYMENT_PAYPAL_ERROR, {
        result: "error",
      });
    } catch (error) {
      console.log("PaymentOptionsComponent.paymentError()");
      console.error(error);
    }
  }
  private paymentCanceled(data: any, actions: any): void {
    try {
      this.isPaymentComplete = false;
      this.isPaymentCancel = true;
      this.isPaymentError = false;
      if (
        this.purchaseRequestService.isRequestBlockDepartureSuccess() === true
      ) {
        this.unblockDeparture();
      }
      this.ngWebflowService.addWebFlowAction(WebFlowAction.EXECUTING_PAYMENT_PAYPAL_CANCELED, {
        result: "canceled",
      });
    } catch (error) {
      console.log("PaymentOptionsComponent.paymentCanceled()");
      console.error(error);
    }
  }
  private unblockDeparture(): void {
    try {
      this.purchaseRequestService.requestUnblockDeparture().subscribe(
        (unblockDepartureResponse: UnblockDeparture) => {
          const isUnblockDeparture: boolean =
            this.purchaseRequestService.setUnblockDeparture(
              unblockDepartureResponse
            );
          const isUnblockDepartureSuccess: boolean =
            isUnblockDeparture === true &&
            this.purchaseRequestService.isRequestUnblockDepartureSuccess();
          if (isUnblockDepartureSuccess === false) {
            this.isUnblockDepartureFailed();
          } else {
            this.isBlockDepartureSuccess = undefined;
          }
        },
        (error) => {
          console.log("PaymentOptionsComponent.unblockDeparture()");
          console.error(error);
          this.isUnblockDepartureFailed();
        },
        () => {}
      );
    } catch (error) {
      console.log("PaymentOptionsComponent.unblockDeparture()");
      console.error(error);
      this.isBlockDepartureSuccess = false;
      this.isUnblockDepartureFailed();
      throw error;
    }
  }
  private isUnblockDepartureFailed(): void {
    // TODO do something
  }

  private getPaypalFundingMethodsObject(): any {
    return {
      allowed: [
        paypal.FUNDING.CREDIT,
        paypal.FUNDING.CARD,
        paypal.FUNDING.ELV,
        paypal.FUNDING.VENMO,
        paypal.FUNDING.IDEAL,
      ],
    };
  }
  private getPayPalButtonStyleObject(
    includeFundingButtons: boolean,
    label: string
  ): {
    fundingicons: boolean;
    size: string;
    shape: string;
    label: string;
    tagline: boolean;
    layout: string;
  } {
    return {
      fundingicons: includeFundingButtons,
      size: "small",
      shape: "rect",
      label: label,
      tagline: false,
      layout: "horizontal",
    };
  }
  private getPaypalPaymentObject(): any {
    return {
      payment: {
        intent: this.companyParamsService.getPayPalInfo().requestIntent,
        transactions: [
          {
            amount: {
              total: this.purchaseTotalPrice,
              currency: this.companyParamsService.getPayPalInfo().currency,
            },
            // description: this.purchaseRequestService.getPurchaseDescription(),
            // invoice_number: '487875896735',
            // soft_descriptor: this.purchaseRequestService.getPurchaseDescriptor(),
            // custom: 'AN ID OR SOMETHING46'
            item_list: this.isSelectedThirtyPercent
              ? {
                  items:
                    this.purchaseRequestService.getPaypalItemsWithThirtyPercent(
                      this.purchaseTotalPrice
                    ),
                }
              : {
                  items: this.purchaseRequestService.getPaypalItems(),
                },
          },
        ],
        note_to_payer:
          this.purchaseRequestService.getPurchaseShortDescription(),
      },
      experience: {
        input_fields: {
          no_shipping: 1,
        },
      },
    };
  }
  isBlockDepartureFailed() {
    this.isProcessing = false;
  }

  private initializeBoatImageResource(): void {
    this.boatImagesResource =
      environment.app_resources.logoCruise + this.cruise.image;
  }
  changeTerms(eventValue) {
    this.termsCondition = eventValue;
    this.valueTerms = eventValue ? "all" : "none";
  }

  getImagesByType(code: number): PaymentsType[] {
    return this.paymentsTypeService.getPaymentsByType(code);
  }

  //its use the enum to control the names web flow actions
  addlog( titleAction: WebFlowAction, data: any) {
    this.ngWebflowService.addWebFlowAction(titleAction, {
      message: data,
    });
  }

  getStripePayment(): any {
    try {
      const index = this.companyParamsService.company.paymentMethods.findIndex(
        (item) =>
          Number(item.type) === Number(PaymentMethodTypeEnum.STRIPE_CHECKOUT)
      );

      return index || index === 0
        ? this.companyParamsService.company.paymentMethods[index]
        : undefined;
    } catch (error) {}
  }

  cabinWasReservedOrHttpError(event: any) {
    if (event === ResponseOnHold.RESERVED_ERROR) {
      this.purchaseRequestService.setTypeErrorOnHold(false);
      this.requestBookingConfirmationAutomatic(false);
    } else {
      this.purchaseRequestService.setTypeErrorOnHold(true);
      this.requestBookingConfirmationAutomatic(true);
    }
    this.ngWebflowService.addWebFlowAction(WebFlowAction.BOOKING_FAILED, {
                  message: 'booking failed' }
    );
    this.isProcessing = false;
  }

  requestBookingConfirmationAutomatic(isPaymentError: boolean) {
    const AUTOMATIC_NOTIFICATION = true;
    if (this.counterSuccessAutomaticEmail === 0) {
      this.purchaseRequestService
        .requestInquireConfirm(AUTOMATIC_NOTIFICATION, isPaymentError)
        .subscribe((resp) => {
          this.counterSuccessAutomaticEmail++;
        });
    }
  }


  /** Todo lo migrado Stripe **/

  executeWithStripe() {
    this.paymentOption = PaymentOption.STRIPE;
    const paymentMethodStripe:PaymentMethod | undefined = this.stripeService.paymentMethodStripe();
    if (!paymentMethodStripe) {
      return;
    }
    const paymentHandler = (<any>window).StripeCheckout.configure({
      key: paymentMethodStripe.objectConfiguration.stripeKey,
      locale: 'auto',
      token: function (stripeToken: any) {
        paymentstripe(stripeToken);
      },
    });
    this.getUrlRequest(false);
    const paymentstripe = async (stripeToken: any) => {
      this.stripeService.auditPaymentStripe(AuditStep.START);
      const request: IStripe = this.stripeService.paymentstripe(paymentMethodStripe,stripeToken,this.purchaseTotalPrice, this.purchaseRequestService.getDescriptionStripe());
      this.stripeService.processPaymentstripe(request, this.reservationClass,this.companyParamsService.getName()).then((resultProcess) => {
      if(resultProcess){
            const { error, type, data  } = resultProcess;
            if(error) {
              if(type === ResponseOnHold.PAYMENT_ERROR) {
                this.stripeService.auditPaymentStripe(AuditStep.ERROR, data);
                this.typeErrorPaymentStripe = ResponseOnHold.PAYMENT_ERROR;
                this.attemptPaymentStripe ++;
              } else if(type === ResponseOnHold.RESERVED_ERROR) {
                this.stripeService.auditPaymentStripe(AuditStep.ERROR_BLOCK, data);
                this.typeErrorPaymentStripe = ResponseOnHold.RESERVED_ERROR;
              }
            } else {
              this.attemptPaymentStripe = 0;
              this.stripeService.auditPaymentStripe(AuditStep.PAYMENT, data);
              this.getUrlRequest(false);
              this.nextFourthPart();
            }
      }
      this.paymentOption = PaymentOption.NONE;
      });
    }

    paymentHandler.open({
      name: 'CHECKOUT',
      description: 'Payment method',
      amount: this.purchaseTotalPrice * 100,
      closed: function () {
        closeAction();
      },
    });

    const closeAction = () => {

    };
  }


/**
 * Metodo que valida si se muestra el mensaje de error de bloqueo de cabina
 * @returns true si se muestra el mensaje de error de bloqueo de cabina
 */
  isVisibleMessageErrorBlockDeparture() {
    if(this.isAutoPaymentAccepted === true && this.isBlockDepartureSuccess !== undefined && this.isBlockDepartureSuccess === false) return true;
    if(this.isAutoPaymentAccepted === true && this.stripeService.viewMessageErrorReserved(this.typeErrorPaymentStripe)) return true;
    return false;
  }


}
