import { CabinOrdinalNamePipe } from "./../../pipes/cabin-ordinal-name.pipe";
import { Company } from "./../../../models/company";
import { CompanyParamsService } from "./../../../services/companyParams/company-params.service";
import { PaypalPaymentItem } from "./../../../models/paypal/paypal-payment-item";
import { PromoCode } from "./../../../enums/promo-code.enum";
import { BookingConfirmationResponse } from "./../../../models/purchase/booking-confirmation-response";
import { PaymentType } from "./../../../enums/payment-type.enum";
import { environment } from "src/environments/environment";
import { AirTicketService } from "./../air-ticket/air-ticket.service";
import { TipoPaqueteEcommerceEnum } from "./../../../enums/type-package-ecommerce.enum";
import { BlockDeparture } from "./../../../models/purchase/block-departure";
import { CabinaItinerarioMenorWrapper } from "src/app/classes/cabina-itinerario-menor-wrapper";
import { PurchaseContactPerson } from "src/app/models/purchase-contact-person";
import { PurchasePayment } from "./../../../models/purchase-payment";
import { PurchaseCabin } from "./../../../models/purchase-cabin";
import { Cruise } from "./../../../models/cruise";
import { PurchaseCabinPassenger } from "src/app/models/purchase-cabin-passenger";
import { PurchaseAirTicketDetail } from "./../../../models/purchase/purchase-air-ticket-detail";
import { PaymentMethod, Purchase } from "./../../../models/purchase";
import { PurchaseRequestStatus } from "src/app/enums/purchase-request-status.enum";
import { CruiseWrapper } from "./../../../classes/cruise-wrapper";
import { Inject, Injectable, isDevMode } from "@angular/core";
import { isNullOrUndefined } from "util";
import { Observable } from "rxjs";
import { Headers, Http } from "@angular/http";
import { TimerObservable } from "rxjs/observable/TimerObservable";
import { HttpHeaders } from "@angular/common/http";
import { SlimLoadingBarService } from "ng2-slim-loading-bar";
import { GalavailRestService } from "clientwsGalavail";
import { PurchasePassengerInformation } from "src/app/models/purchase-passenger-information";
import { UnblockDeparture } from "src/app/models/purchase/unblock-departure";
import { PromoService } from "../promo/promo.service";
import { PurchaseAirTicket } from "src/app/models/purchase/purchase-air-ticket";
import { NgWebflowService } from "ngweflow";
import { StatusRequest } from "../../../enums/status-request.enum";
import { WebFlowAction } from "src/app/enums/web-flow-action.enum";

@Injectable()
export class PurchaseRequestService {
  // tslint:disable-next-line: variable-name
  private _cruiseWrapper: CruiseWrapper;
  // tslint:disable-next-line:variable-name
  private _purchase: Purchase;
  // tslint:disable-next-line: variable-name
  private _purchaseRequestStatus: PurchaseRequestStatus;
  private numberOfFormCabinsDivisionFactor = 2;
  private readonly BLOCK_DEPARTURE_RESPONSE_FAILED = 0;
  private readonly UNBLOCK_DEPARTURE_RESPONSE_FAILED = 0;
  private observablePurchase: Observable<Purchase>;
  private readonly PURCHASE_EXPIRATION_TIME: number;
  private numberRequestAdults: number;
  private numberRequestChildren: number;
  private numberRequestPassengers: number;
  private companyParams: Company;
  private isOptionB: boolean;
  private isHtttpErrorOnHold: boolean = false;
  private MESSAGE_ERROR_GALAVAIL =
    "THIS IS AN AUTOMATIC GENERATED MESSAGE, YOUR ONLINE HOLD COULDN'T GET PROCESSED";
  private MESSAGE_RESERVED_ERROR =
    "THIS IS AN AUTOMATIC GENERATED MESSAGE, THE CABIN IS NO LONGER AVAILABLE";

  blockCabinRequest: any;
  private transaction: number = 0;
  public static getPurchaseCabinTypeSummary(): string {
    return "-";
  }

  public static getPurchaseAirTicketDetailsAsArray(
    purchaseAirTicketDetails: PurchaseAirTicketDetail[][]
  ): PurchaseAirTicketDetail[] {
    try {
      const resultPurchaseAirTicketDetailsArray: PurchaseAirTicketDetail[] = [];
      purchaseAirTicketDetails.forEach(
        (purchaseAirTicketDetailsArray: PurchaseAirTicketDetail[]) => {
          purchaseAirTicketDetailsArray.forEach(
            (purchaseAirTicketDetail: PurchaseAirTicketDetail) => {
              resultPurchaseAirTicketDetailsArray.push(purchaseAirTicketDetail);
            }
          );
        }
      );
      return resultPurchaseAirTicketDetailsArray;
    } catch (error) {
      console.log("PurchaseCabinService.getPurchaseAirTicketDetailsAsArray()");
      console.error(error);
      return [];
    }
  }

  public static calculateTotalAirTicketPrice(
    purchaseAirTicketDetails: PurchaseAirTicketDetail[]
  ): number {
    try {
      let totalAirTicketPrice = 0;
      purchaseAirTicketDetails.forEach(
        (purchaseAirTicketDetail: PurchaseAirTicketDetail) => {
          totalAirTicketPrice += purchaseAirTicketDetail.price;
        }
      );
      return totalAirTicketPrice;
    } catch (error) {
      console.log("PurchaseRequestService.calculateTotalAirTicketPrice()");
      console.error(error);
    }
  }

  public static calculateTotalCabinPrice(
    purchaseCabinPassengers: PurchaseCabinPassenger[]
  ): number {
    try {
      let totalPrice = 0;
      purchaseCabinPassengers.forEach(
        (purchaseCabinPassenger: PurchaseCabinPassenger) => {
          totalPrice += purchaseCabinPassenger.price;
        }
      );
      return totalPrice;
    } catch (error) {
      console.log("PurchaseRequestService.calculateTotalCabinPrice()");
      console.error(error);
      throw error;
    }
  }

  public estimateTotalPrice(
    isOptionB: boolean,
    purchaseCabinPassengers: PurchaseCabinPassenger[],
    includeGalapagosAirTickets: boolean,
    purchaseAirTicketDetails: PurchaseAirTicketDetail[]
  ): number {
    try {
      this.isOptionB = isOptionB;
      let totalPrice: number = PurchaseRequestService.calculateTotalCabinPrice(
        purchaseCabinPassengers
      );
      if (includeGalapagosAirTickets === true && isOptionB) {
        totalPrice += PurchaseRequestService.calculateTotalAirTicketPrice(
          purchaseAirTicketDetails
        );
      }
      return this.purchase.partialPayment
        ? Math.round(
            totalPrice *
              (this.companyParamsService.getPercentPartialPayment() / 100)
          )
        : totalPrice;
    } catch (error) {
      console.log("PurchaseRequestService.calculatePurchaseTotalPrice()");
      console.error(error);
      throw error;
    }
  }

  private calculatePurchaseTotalPrice(): number {
    try {
      let purchaseAirTicketDetailsAsArray: PurchaseAirTicketDetail[] = [];
      if (
        this.isIncludeGalapagosAirTickets() &&
        !isNullOrUndefined(this.purchase.purchaseAirTicket)
      ) {
        purchaseAirTicketDetailsAsArray =
          PurchaseRequestService.getPurchaseAirTicketDetailsAsArray(
            this.purchase.purchaseAirTicket.purchaseAirTicketDetails
          );
      }

      return this.estimateTotalPrice(
        !this.isIncludeAirFare(),
        this.getPurchaseCabinPassengers(),
        this.isIncludeGalapagosAirTickets(),
        purchaseAirTicketDetailsAsArray
      );
    } catch (error) {
      console.log("PurchaseRequestService.calculatePurchaseTotalPrice()");
      console.error(error);
    }
  }

  private calculateTotalCabinPrice(): number {
    try {
      const purchaseCabinPassengers: PurchaseCabinPassenger[] =
        this.getPurchaseCabinPassengers();
      return PurchaseRequestService.calculateTotalCabinPrice(
        purchaseCabinPassengers
      );
    } catch (error) {
      console.log("PurchaseRequestService.calculateTotalCabinPrice()");
      console.error(error);
    }
  }

  constructor(
    private http: Http,
    private airTicketService: AirTicketService,
    private ngProgress: SlimLoadingBarService,
    private promoService: PromoService,
    private companyParamsService: CompanyParamsService,
    private galavailRestService: GalavailRestService,
    private ngWebflowService: NgWebflowService,

  ) {
    this.PURCHASE_EXPIRATION_TIME = environment.PURCHASE.search_expiration_time;
    this.initPurchaseRequest();
  }

  private initPurchaseRequest(): void {
    try {
      this.setPurchase({
        purchaseRequestStatus: PurchaseRequestStatus.INITIAL,
        featureServices: [],
        partialPayment: false,
        paymentMethod: {
          transfer: false,
          creditCard: false,
          paypal: true,
        },
        percentPartialPayment:
          this.companyParamsService.getPercentPartialPayment(),
           errorPayment: '',
        includesFreeTransfers: false,
      });
      this.observablePurchase = new Observable((observer) => {
        observer.next(this._purchase);
      });
    } catch (error) {
      console.log("PurchaseRequestService.initPurchaseRequest()");
      console.error(error);
    }
  }

  public restartPurchaseRequest(): void {
    try {
      this.initPurchaseRequest();
    } catch (error) {
      console.log("PurchaseRequestService.restartPurchaseRequest()");
      console.error(error);
    }
  }

  public getObservablePurchase(): Observable<Purchase> {
    return this.observablePurchase;
  }

  private requestPurchaseRemainigTime(): Observable<number> {
    try {
      return TimerObservable.create(this.PURCHASE_EXPIRATION_TIME * 1000, 1000)
        .map((spentTime: number) => {
          return this.PURCHASE_EXPIRATION_TIME - spentTime;
        })
        .catch((error: any) => {
          console.log("PurchaseRequestService.purchaseRequestTimer()");
          console.error(error);
          return Observable.throw(error);
        });
    } catch (error) {
      console.log("PurchaseRequestService.purchaseRequestTimer()");
      console.error(error);
      return Observable.throw(error);
    }
  }

  public isRemainingTimeForPurchaseRequest(): Observable<boolean> {
    try {
      return Observable.interval(this.PURCHASE_EXPIRATION_TIME * 1000)
        .take(1)
        .map(() => {
          return false;
        })
        .catch((error: any) => {
          console.log(
            "PurchaseRequestService.isRemainingTimeForPurchaseRequest()"
          );
          console.error(error);
          return Observable.throw(error);
        });
    } catch (error) {
      console.log("PurchaseRequestService.isRemainingTimeForPurchaseRequest()");
      console.error(error);
      return Observable.throw(error);
    }
  }

  public getCruiseWrapper(): CruiseWrapper {
    return this._cruiseWrapper;
  }
  setIsSelectedThirtyPerent(value: boolean) {
    this.purchase.partialPayment = value;
  }

  public setCruiseWrapper(cruiseWrapper: CruiseWrapper): void {
    this._cruiseWrapper = cruiseWrapper;
  }

  public getPaypalItemsWithThirtyPercent(value: number): PaypalPaymentItem[] {
    try {
      const resultPaypalPaymentItem: PaypalPaymentItem[] = [];
      resultPaypalPaymentItem.push({
        sku: `(Booking reference: ${this.createUrlCodeParamForBlockDeparture()})`,
        name: `First Deposit ${this.companyParamsService.getPercentPartialPayment()}% `,
        description: ``,
        quantity: "1",
        price: value,
        currency: environment.PAYPAL_EXPRESS_CHECKOUT_CONFIG.CURRENCY,
      });

      return resultPaypalPaymentItem;
    } catch (error) {
      console.log("PurchaseRequestService.getPaypalItems()");
      console.error(error);
      return [];
    }
  }

  public setNumberRequestAdults(cuantity: number) {
    this.numberRequestAdults = cuantity;
  }

  public getNumberRequestAdults() {
    return this.numberRequestAdults;
  }

  public setNumberRequestChildren(cuantity: number) {
    this.numberRequestChildren = cuantity;
  }

  public getNumberRequestChildren() {
    return this.numberRequestChildren;
  }

  public setNumberRequestPassengers(cuantity: number) {
    this.numberRequestPassengers = cuantity;
  }

  public getNumberRequestPassengers() {
    return this.numberRequestPassengers;
  }

  public addCruise(cruise: Cruise) {
    this.purchase.cruise = cruise;
    this.setCruiseWrapper(new CruiseWrapper(this.purchase.cruise));
  }

  get purchase(): Purchase {
    return this._purchase;
  }

  private setPurchase(purchase: Purchase) {
    this._purchase = purchase;
    this.purchase.cabins = [];
    this.purchase.pricePerPerson = 0;
  }

  get purchaseRequestStatus(): PurchaseRequestStatus {
    return this._purchaseRequestStatus;
  }

  set purchaseRequestStatus(value: PurchaseRequestStatus) {
    this._purchaseRequestStatus = value;
  }

  public addCabins(purchaseCabins: PurchaseCabin[]): void {
    this.purchase.cabins = purchaseCabins;
  }

  public addPaymentToPurchaseRequestObject(
    purchasePayment: PurchasePayment
  ): boolean {
    try {
      this.purchase.payment = purchasePayment;
      return !isNullOrUndefined(this.purchase.payment);
    } catch (error) {
      console.log("PurchaseRequestService.addPayment()");
      console.error(error);
      return false;
    }
  }
  setPaymentMethod(paymentMethod: PaymentMethod) {
    this.purchase.paymentMethod = paymentMethod;
  }

  setErrorPayment(error: string) {
    if (this.purchase) {
      this.purchase.errorPayment = error;
    }
  }

  getErrorPayment() {
    if (this.purchase) {
       return this.purchase.errorPayment
    }
    return '';
  }

  getPaymentMethod(): PaymentMethod {
    return this.purchase.paymentMethod;
  }
  public addPaymentResponse(
    paymentResponse: any,
    paymentType: PaymentType
  ): boolean {
    try {
      const purchasePayment: PurchasePayment = {
        // tslint:disable-next-line:object-literal-shorthand
        paymentType: paymentType,
        PaypalPaymentReseponse:
          paymentType === PaymentType.PAYPAL
            ? paymentResponse
            : { create_time: paymentResponse.getTransactionTime() },
        paypalPayflowResponse:
          paymentType === PaymentType.payflowCreditCard ? paymentResponse : {},
      };
      this.addPaymentToPurchaseRequestObject(purchasePayment);
    } catch (error) {
      console.log("PurchaseRequestService.addPaypalPayment()");
      console.error(error);
      return false;
    }
  }

  /***
   * This is used to determine the maximum number of available cabins that are going to be showon to
   * the user.
   * @returns {number[]} Number of avalilable cabins
   */
  public getNumberOfAvailableCabins(): number {
    return Math.round(
      this.getNumberOfPassengers() / this.numberOfFormCabinsDivisionFactor
    );
  }

  public getNumberOfCabinPassengers(): number {
    try {
      let totalPassengers = 0;
      if (!this.purchase.cabins) {
        return totalPassengers;
      }
      this.purchase.cabins.forEach(
        (cabin) => (totalPassengers += cabin.adults + cabin.children)
      );
      return totalPassengers;
    } catch (error) {
      console.log("PurchaseRequestService.getNumberOfCabinPassengers()");
      console.error(error);
      return 0;
    }
  }

  public getNumberOfPassengers(): number {
    return this.purchase.adults + this.purchase.children;
  }

  public getNumberOfAdults(): number {
    return this.purchase.adults;
  }

  public getNumberOfChildren(): number {
    return this.purchase.children;
  }

  public addPurchasePassengersInformation(
    purchasePassengersInformation: PurchasePassengerInformation[][]
  ): boolean {
    try {
      purchasePassengersInformation.forEach(
        (cabin: PurchasePassengerInformation[], cabinIndex: number) => {
          cabin.forEach(
            (
              purchasePassengerInformation: PurchasePassengerInformation,
              purchasePassengerIndex: number
            ) => {
              this.getPurchaseCabins()[cabinIndex].purchaseCabinPasssengers[
                purchasePassengerIndex
              ].purchasePassengerInformation = purchasePassengerInformation;
            }
          );
        }
      );
      return true;
    } catch (error) {
      console.log("PurchaseRequestService.addPurchasePassengersInformation()");
      console.error(error);
      return false;
    }
  }
  public addPurchaseContectPerson(
    purchaseContactPerson: PurchaseContactPerson
  ): void {
    this.purchase.purchaseContactPerson = purchaseContactPerson;
  }

  public addPurchaseContactPerson(
    purchaseContactPerson: PurchaseContactPerson
  ): void {
    this.purchase.purchaseContactPerson = purchaseContactPerson;
  }

  public getPurchaseCabinTypeSummary(): string {
    const purchaseCabins: PurchaseCabin[] = this.getPurchaseCabins();
    const cabinTypesValues: string[] = [];
    let purchaseCabinTypeSummary = "";

    if (!purchaseCabins || purchaseCabins.length === 0) {
      return null;
    } else if (purchaseCabins.length === 1) {
      return "a " + purchaseCabins[0].cabinaItinerarioMenor.type + " cabin";
    }

    purchaseCabins.forEach((purchaseCabin) => {
      cabinTypesValues.push(purchaseCabin.cabinaItinerarioMenor.type);
    });

    let nonRepeateCabinTypesValues: string[] = [];
    // tslint:disable-next-line:only-arrow-functions
    nonRepeateCabinTypesValues = cabinTypesValues.filter(function (
      elem,
      index,
      self
    ) {
      return index === self.indexOf(elem);
    });

    for (let i = 0; i < nonRepeateCabinTypesValues.length; i++) {
      purchaseCabinTypeSummary += nonRepeateCabinTypesValues[i];
      if (i < nonRepeateCabinTypesValues.length - 2) {
        purchaseCabinTypeSummary += ", ";
      } else if (i === nonRepeateCabinTypesValues.length - 2) {
        purchaseCabinTypeSummary += " and ";
      }
    }
    return purchaseCabinTypeSummary + " cabins";
  }
  getIsSelectedThirtyPerent(): boolean {
    return this.purchase.partialPayment;
  }

  public getPurchaseCabinObjectSummary(): {
    cabinaItinerarioNombre: string;
    camaCabinaNombre: string;
  }[] {
    try {
      const result: {
        cabinaItinerarioNombre: string;
        camaCabinaNombre: string;
      }[] = [];
      this.purchase.cabins.forEach((purchaseCabin: PurchaseCabin) => {
        const cabinaItinerarioWrapper: CabinaItinerarioMenorWrapper =
          new CabinaItinerarioMenorWrapper(purchaseCabin.cabinaItinerarioMenor);
        result.push({
          cabinaItinerarioNombre: cabinaItinerarioWrapper.getCabinaFullName(),
          camaCabinaNombre: purchaseCabin.camaCabinaList.description,
        });
      });
      return result;
    } catch (error) {
      console.log("PurchaseRequestService.getPurchaseCabinObjectSummary()");
      console.error(error);
      return [];
    }
  }

  public getPurchaseCabins(): PurchaseCabin[] {
    return this.purchase.cabins;
  }

  public setPurchaseTotalPrice(): void {
    this.purchase.totalPrice = this.calculatePurchaseTotalPrice();
  }

  public getPurchaseTotalPrice(): number {
    try {
      this.purchase.totalPrice = this.calculatePurchaseTotalPrice();
      return this.purchase.totalPrice;
    } catch (error) {
      console.log("PurchaseRequestService.getTotalPrice()");
      console.error(error);
      // tslint:disable-next-line:new-parens
      throw new Error();
    }
  }
  public setAditionalServices(aditionalServices: string[]): void {
    this.purchase.aditionalServices = aditionalServices;
  }
  public getAditionalServices(): string[] {
    return this.purchase.aditionalServices;
  }

  public getTotalCabinPrice(): number {
    try {
      this.setTotalCabinPrice();
      return this.purchase.totalCabinPrice;
    } catch (error) {
      console.log("PurchaseRequestService.getTotalCabinPrice()");
      console.error(error);
    }
  }

  public setTotalCabinPrice(): void {
    try {
      this.purchase.totalCabinPrice = this.calculateTotalCabinPrice();
    } catch (error) {
      console.log("PurchaseRequestService.setTotalCabinPrice()");
      console.error(error);
    }
  }

  public setTrackingCode(bookingTrackCode: string): void {
    if (!this.getBookingTrackCode()) {
      if (isNullOrUndefined(bookingTrackCode)) {
        this.purchase.bookingTrackCode = Math.round(
          Math.random() * 100000000000 + 1
        ).toString();
      } else {
        this.purchase.bookingTrackCode = bookingTrackCode;
      }
    }
  }

  public getPercentUsed() {
    return this.purchase.selectedPercent;
  }

  public setBlockDeparture(blockDeparture: BlockDeparture): boolean {
    try {
      if (isNullOrUndefined(blockDeparture)) {
        return false;
      }
      this.purchase.blockDeparture = blockDeparture;
      return true;
    } catch (error) {
      console.log("PurchaseRequestService.setBlockDeparture");
      console.error(error);
      return false;
    }
  }

  public getBlockDeparture(): BlockDeparture {
    try {
      return this.purchase.blockDeparture;
    } catch (error) {
      console.log("PurchaserequestService.getBlockDeparture()");
      console.error(error);
      return null;
    }
  }

  public setUnblockDeparture(unblockDeparture: UnblockDeparture): boolean {
    try {
      if (isNullOrUndefined(unblockDeparture)) {
        return false;
      }
      this.purchase.unblockDeparture = unblockDeparture;
      return true;
    } catch (error) {
      console.log("PurchaseRequestService.setUnblockDeparture");
      console.error(error);
      return false;
    }
  }

  public getUnblockDeparture(): UnblockDeparture {
    try {
      return this.purchase.unblockDeparture;
    } catch (error) {
      console.log("PurchaserequestService.getUnblockDeparture()");
      console.error(error);
      return null;
    }
  }

  public getBookingTrackCode(): string {
    return this.purchase.bookingTrackCode;
  }

  public getPurchasePassengersInformation(): PurchasePassengerInformation[] {
    const passengersInformation: PurchasePassengerInformation[] = [];
    try {
      const purchaseCabinPassengers: PurchaseCabinPassenger[] =
        this.getPurchaseCabinPassengers();
      if (
        isNullOrUndefined(purchaseCabinPassengers) ||
        purchaseCabinPassengers.length === 0
      ) {
        return [];
      }
      purchaseCabinPassengers.forEach(
        (purchaseCabinPassenger: PurchaseCabinPassenger) => {
          const purchasePassengerInformation: PurchasePassengerInformation =
            purchaseCabinPassenger.purchasePassengerInformation;
          if (!isNullOrUndefined(purchasePassengerInformation)) {
            passengersInformation.push(purchasePassengerInformation);
          }
        }
      );
      return passengersInformation;
    } catch (error) {
      console.log("PurchaseRequestService.getPassengers()");
      console.error(error);
      return [];
    }
  }

  public getPurchaseContactPerson(): PurchaseContactPerson {
    return this.purchase.purchaseContactPerson;
  }

  public getPurchaseCabinPassengers(): PurchaseCabinPassenger[] {
    const purchaseCabinPassengers: PurchaseCabinPassenger[] = [];
    try {
      this.getPurchaseCabins().forEach((purchaseCabin: PurchaseCabin) => {
        purchaseCabin.purchaseCabinPasssengers.forEach(
          (purchaseCabinPassenger: PurchaseCabinPassenger) => {
            purchaseCabinPassengers.push(purchaseCabinPassenger);
          }
        );
      });
      return purchaseCabinPassengers;
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  public getTotalPrice(): number {
    try {
      this.purchase.totalPrice = this.calculatePurchaseTotalPrice();
      return this.purchase.totalPrice;
    } catch (error) {
      console.log("PurchaseRequestService.getTotalPrice()");
      console.error(error);
      // tslint:disable-next-line: new-parens
      throw new Error();
    }
  }

  public getPricePerPerson(): number {
    return this.purchase.pricePerPerson;
  }

  public setPricePerPerson(ppp:number){
    this.purchase.pricePerPerson = ppp
  }

  public getPurchaseAdults(): number {
    return this.purchase.adults;
  }

  public getPurchaseChildren(): number {
    return this.purchase.children;
  }

  public isIncludesFreeTransfers(): boolean {
    return this.purchase.includesFreeTransfers;
  }

  setIncludesFreeTransfers(value: boolean) {
    this.purchase.includesFreeTransfers = value;
  }

  public isIncludeAirFare(): boolean {
    return this.purchase.includeAirFare;
  }

  public setIncludeAirFare(includeAirFare: boolean): void {
    this.purchase.includeAirFare = includeAirFare;
  }
  public setChildren(children: number): void {
    this.purchase.children = children;
  }
  public setAdults(adults: number): void {
    this.purchase.adults = adults;
  }
  public setIncludeGalapagosAirTickets(
    includesGalapagosAirTickets: boolean
  ): void {
    this.purchase.includesGalapagosAirTickets = includesGalapagosAirTickets;
  }

  public isIncludeGalapagosAirTickets(): boolean {
    try {
      return this.purchase.includesGalapagosAirTickets;
    } catch (error) {
      console.log("PurchaseRequestService.isIncludeGalapagosAirTickets()");
      console.error(error);
    }
  }
  public getTotalPriceWithoutDiscount(): number {
    try {
      this.purchase.totalPrice =
        this.calculatePurchaseTotalPriceWithoutThirtyPercent();
      return this.purchase.totalPrice;
    } catch (error) {
      console.log("PurchaseRequestService.getTotalPrice()");
      console.error(error);
      throw new Error();
    }
  }
  private calculatePurchaseTotalPriceWithoutThirtyPercent(): number {
    try {
      let purchaseAirTicketDetailsAsArray: PurchaseAirTicketDetail[] = [];
      if (
        this.isIncludeGalapagosAirTickets() &&
        !isNullOrUndefined(this.purchase.purchaseAirTicket)
      ) {
        purchaseAirTicketDetailsAsArray =
          PurchaseRequestService.getPurchaseAirTicketDetailsAsArray(
            this.purchase.purchaseAirTicket.purchaseAirTicketDetails
          );
      }

      return this.estimateTotalPriceWithoutThirtyPercent(
        this.getPurchaseCabinPassengers(),
        this.isIncludeGalapagosAirTickets(),
        purchaseAirTicketDetailsAsArray
      );
    } catch (error) {
      console.log("PurchaseRequestService.calculatePurchaseTotalPrice()");
      console.error(error);
    }
  }
  public estimateTotalPriceWithoutThirtyPercent(
    purchaseCabinPassengers: PurchaseCabinPassenger[],
    includeGalapagosAirTickets: boolean,
    purchaseAirTicketDetails: PurchaseAirTicketDetail[]
  ): number {
    try {
      let totalPrice: number = PurchaseRequestService.calculateTotalCabinPrice(
        purchaseCabinPassengers
      );
      if (includeGalapagosAirTickets === true) {
        totalPrice += PurchaseRequestService.calculateTotalAirTicketPrice(
          purchaseAirTicketDetails
        );
      }
      return totalPrice;
    } catch (error) {
      console.log("PurchaseRequestService.calculatePurchaseTotalPrice()");
      console.error(error);
      throw error;
    }
  }

  public setPurchaseAirTicket(): void {
    try {
      if (
        isNullOrUndefined(this.isIncludeGalapagosAirTickets()) ||
        this.isIncludeGalapagosAirTickets() === false ||
        isNullOrUndefined(this.getPurchaseCabins())
      ) {
        this.purchase.purchaseAirTicket = null;
        return;
      }

      const purchaseAirTicketDetails: PurchaseAirTicketDetail[][] =
        this.createPurchaseAirTicketDetails();
      const purchaseAirTicketDetailsAsArray: PurchaseAirTicketDetail[] =
        PurchaseRequestService.getPurchaseAirTicketDetailsAsArray(
          purchaseAirTicketDetails
        );
      const totalPrice: number =
        PurchaseRequestService.calculateTotalAirTicketPrice(
          purchaseAirTicketDetailsAsArray
        );

      if (purchaseAirTicketDetails.length < 1) {
        this.purchase.purchaseAirTicket = null;
      }

      this.purchase.purchaseAirTicket = {
        purchaseAirTicketDetails,
        totalPurchaseAirTicketPrice: totalPrice,
      };
    } catch (error) {
      console.log("PurchaseRequestService.setPurchaseAirTicket");
      console.error(error);
    }
  }

  private createPurchaseAirTicketDetails(): PurchaseAirTicketDetail[][] {
    try {
      const purchaseAirTicketDetails: PurchaseAirTicketDetail[][] = [];

      this.getPurchaseCabins().forEach((purchaseCabin: PurchaseCabin) => {
        const purchaseAirTicketDetailsArray: PurchaseAirTicketDetail[] = [];
        purchaseCabin.purchaseCabinPasssengers.forEach(
          (purchaseCabinPassenger: PurchaseCabinPassenger) => {
            const price = this.airTicketService.getAirTicketPrice(
              purchaseCabinPassenger.type
            );
            // tslint:disable-next-line:object-literal-shorthand
            const purchaseCabinAirTicket: PurchaseAirTicketDetail = {
              price: price,
              type: purchaseCabinPassenger.type,
            };
            purchaseAirTicketDetailsArray.push(purchaseCabinAirTicket);
          }
        );
        purchaseAirTicketDetails.push(purchaseAirTicketDetailsArray);
      });
      return purchaseAirTicketDetails;
    } catch (error) {
      console.log("PurchaseRequestService.createPurchaseAirTicketDetails()");
      console.error(error);
      return [];
    }
  }

  public getPurchaseAirTicket(): PurchaseAirTicket {
    try {
      return this.purchase.purchaseAirTicket;
    } catch (error) {
      console.log("PurchaseRequestService.getPurchaseAirTicket()");
      console.error(error);
      return null;
    }
  }

  public getCruise(): Cruise {
    try {
      return this.purchase.cruise;
    } catch (error) {
      console.log("PurchaseRequestService.getCruise()");
      console.error(error);
      return null;
    }
  }

  public requestBookingConfirmation(): Observable<any> {
    const body = this.preparePurchaseBookingConfirm();
    try {
      this.ngWebflowService.addWebFlowAction(WebFlowAction.SEND_CONFIRMATION_EMAIL, {
        result: "success",
        request: body,
      });
      const httpHeaders = new HttpHeaders({
        "Content-Type": "application/json",
      });

      this.ngProgress.start();

      return this.galavailRestService
        .request<any>(
          "",
          "requestG_bookingConfirm",
          {
            headers: httpHeaders,
          },
          JSON.stringify(body)
        )
        .do(() => this.ngProgress.complete())
        .catch((error) => {
          this.ngProgress.complete();
          return this.handleError(error);
        });
    } catch (error) {
      if (isDevMode()) {
        console.log("requestbookingConfirm()");
        console.error(error);
      }
      return this.handleError(error);
    }
  }
  // it has token

  public requestInquireConfirm(
    automaticNotification?: boolean, isPaymentError?: boolean | false
  ): Observable<any> {
    const body = this.preparePurchaseObjectToRequestBookingConfirmation(
      automaticNotification, isPaymentError
    );
    this.ngWebflowService.addWebFlowAction(WebFlowAction.SEND_AUTOMATIC_EMAIL, {
      message: "init",
      request: body,
    });
    try {
      const httpHeaders = new HttpHeaders({
        "Content-Type": "application/json",
      });

      this.ngProgress.start();

      return this.galavailRestService
        .request<any>(
          "",
          "requestG_sendInquireRequestEmail",
          {
            headers: httpHeaders,
          },
          JSON.stringify(body)
        )
        .do(() => {
          this.ngProgress.complete()
          this.ngWebflowService.addWebFlowAction(WebFlowAction.SEND_AUTOMATIC_EMAIL, {
            message: "success",
          });
        })
        .catch((error) => {
          this.ngProgress.complete();
          this.ngWebflowService.addWebFlowAction(WebFlowAction.SEND_AUTOMATIC_EMAIL, {
            message: "error",
            error: error,
          });
          return this.handleError(error);
        });
    } catch (error) {
      if (isDevMode()) {
        console.log("requestbookingConfirm()");
        console.error(error);
      }
      return this.handleError(error);
    }
  }
  private reduceCruise(preparedPurchaseObject: any): any {
    const cruise: any = {
      name: this.purchase.cruise.name,
      type: this.purchase.cruise.type,
      image: this.purchase.cruise.image,
      departure: this.reduceAvailability(preparedPurchaseObject),
      itinerary: preparedPurchaseObject.cruise.itineraries[0],
      keyFeatures: preparedPurchaseObject.cruise.keyFeatures,
      category: this.purchase.cruise.category,
    };
    return cruise;
  }
  private reduceAvailability(preparedPurchaseObject: any): any {
    const availability: any = {
      date: preparedPurchaseObject.cruise.availability[0].date,
      startDate: preparedPurchaseObject.cruise.availability[0].startDate,
      endDate: preparedPurchaseObject.cruise.availability[0].endDate,
      promotion: preparedPurchaseObject.cruise.availability[0].promotion,
      promotionCode:
        preparedPurchaseObject.cruise.availability[0].promotionCode,
      name: preparedPurchaseObject.cruise.availability[0].name,
      length: preparedPurchaseObject.cruise.availability[0].length,
    };
    return availability;
  }
  private reduceCabin(cabins: any): any {
    const res: any[] = [];
    Object(cabins).forEach((item) => {
      const cabin: any = item;
      const toAdd: any = {
        adults: cabin.adults,
        children: cabin.children,
        deck: cabin.cabinaItinerarioMenor.deck,
        type: cabin.cabinaItinerarioMenor.type,
        acommodation: cabin.camaCabinaList.description,
        purchaseCabinPassengers: cabin.purchaseCabinPasssengers,
      };
      res.push(toAdd);
    });
    return res;
  }

  public preparePurchaseObjectToRequestBookingConfirmation(
    automaticNotification?: boolean , paymentErrorNotification?: boolean | false
  ): any {
    try {
      const preparedPurchaseObject: any = JSON.parse(
        JSON.stringify(this.purchase)
      );
      preparedPurchaseObject.cruise.availability =
        preparedPurchaseObject.cruise.availability.filter(
          (availability: any, index: number) => {
            return index === preparedPurchaseObject.cruise.iterIndex;
          }
        );

      // setted itineraries selected
      const nameItinerarie =
        preparedPurchaseObject.cruise.availability[0].itinerary;

      preparedPurchaseObject.cruise.itineraries =
        preparedPurchaseObject.cruise.itineraries.filter(
          (itineraries: any, index: number) => {
            return nameItinerarie === itineraries.code;
          }
        );

      const promoId: number =
        preparedPurchaseObject.cruise.availability.promotionType;

      if (promoId === PromoCode.PROMO_NET) {
        const valorEtiquetaPromo: string =
          this.promoService.getPromoText(promoId);
        preparedPurchaseObject.cruise.availability.promotion =
          valorEtiquetaPromo;
      }

      if (
        preparedPurchaseObject.includesGalapagosAirTickets === false &&
        isNullOrUndefined(preparedPurchaseObject.purchaseAirTicket)
      ) {
        preparedPurchaseObject.purchaseAirTicket = {
          totalPurchaseAirTicketPrice: 0,
        };
      }

      const stars = preparedPurchaseObject.cruise.categoryII as string;
      preparedPurchaseObject.cruise.categoryII = stars.lastIndexOf("★") + 1;
      const valueAirTickets = this.purchase.purchaseAirTicket
        ? this.purchase.purchaseAirTicket.totalPurchaseAirTicketPrice
        : 0;
      const mynewobjecJson: any = {
        cruise: this.reduceCruise(preparedPurchaseObject),
        includeAirFare: preparedPurchaseObject.includeAirFare,
        includesFreeTransfers: preparedPurchaseObject.includesFreeTransfers,
        includesGalapagosAirTickets:
        preparedPurchaseObject.includesGalapagosAirTickets,
        totalCabinPrice: preparedPurchaseObject.totalCabinPrice,
        totalPrice: preparedPurchaseObject.totalPrice,
        codePaymenMethod: preparedPurchaseObject.codePaymenMethod,
        errorPayment: preparedPurchaseObject.errorPayment === '' ? null : preparedPurchaseObject.errorPayment,
        cabins: this.reduceCabin(preparedPurchaseObject.cabins),
        purchaseContactPerson: preparedPurchaseObject.purchaseContactPerson,
        reference: this.createUrlCodeParamForBlockDeparture(),
        totalAirTickets: valueAirTickets,
        company: this.setCompanyparams(),
        transaction: paymentErrorNotification === false ? this.getTransactionDetails(automaticNotification || false): null, // si es null debe tonar el valor de error payment en la notificacion

        aditionalServices: isNullOrUndefined(
          preparedPurchaseObject.aditionalServices
        )
          ? []
          : preparedPurchaseObject.aditionalServices,
      };
      return mynewobjecJson;
    } catch (error) {
      console.log(
        "PurchaseRequestService.preparePurchaseObjectToRequestBookingConfirmation()"
      );
      console.error(error);
      return null;
    }
  }

  getTransactionDetails(automaticNotification: boolean) {
    return {
      id: this.ngWebflowService.objectPersistenceId,
      status: automaticNotification
        ? StatusRequest.FAILED
        : StatusRequest.SUCCESS,
      message: automaticNotification ? this.getCommentError() : "",
    };
  }

  changeCommentPurchasteContact(purchaseContactPerson: PurchaseContactPerson) {
    const modifiedpurchaseContactPerson = {
      ...purchaseContactPerson,
      comments: this.getCommentError() + " -- " + purchaseContactPerson.remarks,
    };
    return modifiedpurchaseContactPerson;
  }

  getCommentError(): string {
    return this.isHtttpErrorOnHold
      ? this.MESSAGE_ERROR_GALAVAIL
      : this.MESSAGE_RESERVED_ERROR;
  }

  public preparePurchaseBookingConfirm(): any {
    try {
      const preparedPurchaseObject: any = JSON.parse(
        JSON.stringify(this.purchase)
      );
      preparedPurchaseObject.cruise.availability =
        preparedPurchaseObject.cruise.availability.filter(
          (availability: any, index: number) => {
            return index === preparedPurchaseObject.cruise.iterIndex;
          }
        );

      // setted itineraries selected
      const nameItinerarie =
        preparedPurchaseObject.cruise.availability[0].itinerary;

      preparedPurchaseObject.cruise.itineraries =
        preparedPurchaseObject.cruise.itineraries.filter(
          (itineraries: any, index: number) => {
            return nameItinerarie === itineraries.code;
          }
        );

      const promoId: number =
        preparedPurchaseObject.cruise.availability[0].promotionCode;

      if (promoId === PromoCode.PROMO_NET) {
        const valorEtiquetaPromo: string =
          this.promoService.getPromoText(promoId);
        preparedPurchaseObject.cruise.availability[0].promotion =
          valorEtiquetaPromo;
      }

      if (
        preparedPurchaseObject.includesGalapagosAirTickets === false &&
        isNullOrUndefined(preparedPurchaseObject.purchaseAirTicket)
      ) {
        preparedPurchaseObject.purchaseAirTicket = {
          totalPurchaseAirTicketPrice: 0,
        };
      }

      const stars = preparedPurchaseObject.cruise.categoryII as string;
      preparedPurchaseObject.cruise.categoryII = stars.lastIndexOf("★") + 1;
      const valueAirTickets = this.purchase.purchaseAirTicket
        ? this.purchase.purchaseAirTicket.totalPurchaseAirTicketPrice
        : 0;
      console.log("data", preparedPurchaseObject);
      const mynewobjecJson: any = {
        cruise: this.reduceCruise(preparedPurchaseObject),
        includeAirFare: preparedPurchaseObject.includeAirFare,
        includesFreeTransfers: preparedPurchaseObject.includesFreeTransfers,
        includesGalapagosAirTickets:
          preparedPurchaseObject.includesGalapagosAirTickets,
        totalCabinPrice: preparedPurchaseObject.totalCabinPrice,
        totalPrice: preparedPurchaseObject.totalPrice,
        subTotal: preparedPurchaseObject.subTotalPrice,
        partialPayment: preparedPurchaseObject.partialPayment,
        paymentMethod: preparedPurchaseObject.paymentMethod,
        cabins: this.reduceCabin(preparedPurchaseObject.cabins),
        purchaseContactPerson: preparedPurchaseObject.purchaseContactPerson,
        reference: this.createUrlCodeParamForBlockDeparture(),
        totalAirTickets: valueAirTickets,
        company: this.setCompanyparams(),
        aditionalServices: isNullOrUndefined(
          preparedPurchaseObject.aditionalServices
        )
          ? []
          : preparedPurchaseObject.aditionalServices,
        featureServices: isNullOrUndefined(
          preparedPurchaseObject.featureServices
        )
          ? []
          : preparedPurchaseObject.featureServices,
        codePaymenMethod: preparedPurchaseObject.codePaymenMethod,
      };
      console.log("sendConfirmation", mynewobjecJson);
      return mynewobjecJson;
    } catch (error) {
      console.log(
        "PurchaseRequestService.preparePurchaseObjectToRequestBookingConfirmation()"
      );
      console.error(error);
      return null;
    }
  }
  setCompanyparams(): any {
    try {
      this.companyParams = this.companyParamsService.getCompanyParams();
      return {
        logoCompany: this.companyParams.logo,
        nameCompany: this.companyParams.name,
        mainColorCompany: this.companyParams.mainColor,
        secondaryColorCompany: this.companyParams.secondaryColor,
        headerColorCompany: this.companyParams.headercolor,
        footerColorCompany: this.companyParams.footerColor,
        infoMailCompany: this.companyParams.infoEmail,
        phoneCompany: this.companyParams.phone,
        webSiteCompany: this.companyParams.webSite,
        ecommerceMailCompany: this.companyParams.emailEcommerce,
        keyCompany: this.companyParams.keyCompany,
      };
    } catch (error) {
      return "";
    }
  }
  public isRequestBookingConfirmationSuccess(
    bookingConfirmationResponse: BookingConfirmationResponse
  ): boolean {
    try {
      if (isNullOrUndefined(bookingConfirmationResponse)) {
        return false;
      }
      return bookingConfirmationResponse.status;
    } catch (error) {
      console.log(
        "PurchaseRequestService.isRequestBookingConfirmationSuccess()"
      );
      console.error(error);
      return false;
    }
  }

  public requestSaveToDatabase(): void {
    try {
      const databaseTableName: string =
        environment.firebase_table_names.purchase_requests;
      // this.firebaseService.pushWithCustomKey(databaseTableName, this.purchase, this.getBookingTrackCode());
    } catch (error) {
      console.log("PurchaseRequestService.requestSaveToDatabase()");
      console.error(error);
    }
  }

  public requestUnblockDeparture(): Observable<UnblockDeparture> {
    try {
      if (this.isRequestBlockDepartureSuccess() === false) {
        Observable.throw(new Error());
      }
      const headers = new HttpHeaders({ "Content-Type": "application/json" });
      const purchaseId: number = this.getBlockDeparture().pedido;
      return this.galavailRestService
        .request<UnblockDeparture>(
          purchaseId.toString(),
          "request_unblock_departure",
          { headers }
        )
        .catch((error) => {
          return this.galavailRequestsHandler(error);
        });
    } catch (error) {
      return this.galavailRequestsHandler(error);
    }
  }

  public requestBlockDeparture(): Observable<BlockDeparture> {
    try {
      const headers = new HttpHeaders({ "Content-Type": "application/json" });
      const urlParams = this.createUrlParamsForBlockDeparture();
      // tslint:disable-next-line: new-parens
      if (isNullOrUndefined(urlParams)) {
        throw new Error();
      }
      return this.galavailRestService.request<BlockDeparture>(
        urlParams,
        "requestG_blockCabin",
        { headers }
      );
    } catch (error) {
      return this.galavailRequestsHandler(error);
    }
  }
  private handleError(error: any): Observable<any> {
    console.error("purchase-requestservice-> HandleError");
    console.error(error);
    this.ngProgress.complete();
    return Observable.throw(error);
  }

  public requestStatusAvailability(
    agencyPercentObj
  ): Observable<BlockDeparture> {
    const codeUser = this._cruiseWrapper.getCruise().userCode;
    const body = this.jsonblockPurchaseCabin(codeUser, agencyPercentObj);
    try {
      const httpHeaders = new HttpHeaders({
        "Content-Type": "application/json",
      });
      this.ngProgress.start();
      return this.galavailRestService
        .request<BlockDeparture>(
          "",
          "requestG_statusOnHold",
          {
            headers: httpHeaders,
          },
          body
        )
        .do(() => this.ngProgress.complete())
        .catch((error) => {
          this.ngProgress.complete();
          return this.handleError(error);
        });
    } catch (error) {
      if (isDevMode()) {
        console.log("BarcoService.getAllcruises()");
        console.error(error);
      }
      return this.handleError(error);
    }
  }
  getValueTransaction() {
    return this.transaction;
  }

  setValueTransaction(value: number) {
    this.transaction = value;
  }
  setBlockCabinRequest(request: any) {
    this.blockCabinRequest = request;
  }
  getBlockCabinRequest() {
    return this.blockCabinRequest;
  }
  setRequestBlockCabinOnHold(agencyPercentObj: any) {
    const codeUser = this._cruiseWrapper.getCruise().userCode;
    const body = this.jsonblockPurchaseCabin(codeUser, agencyPercentObj);

    this.setBlockCabinRequest(body);
  }
  public requestBlockCabinOnHold(agencyPercentObj): Observable<BlockDeparture> {
    const codeUser = this._cruiseWrapper.getCruise().userCode;
    const body = this.jsonblockPurchaseCabin(codeUser, agencyPercentObj);
    try {
      const httpHeaders = new HttpHeaders({
        "Content-Type": "application/json",
      });
      this.ngProgress.start();
      return this.galavailRestService
        .request<BlockDeparture>(
          "",
          "requestG_blockCabin",
          {
            headers: httpHeaders,
          },
          body
        )
        .do(() => this.ngProgress.complete())
        .catch((error) => {
          this.ngProgress.complete();
          return this.handleError(error);
        });
    } catch (error) {
      if (isDevMode()) {
        console.log("BarcoService.getAllcruises()");
        console.error(error);
      }
      return this.handleError(error);
    }
  }

  private galavailRequestsHandler(error) {
    if (isDevMode()) {
      console.log("PurchaseRequest.galavailRequestsHandler()");
      console.error(error);
    }
    return Observable.throw(error);
  }

  public isRequestBlockDepartureSuccess(): boolean {
    try {
      const blockDepartureResponse: BlockDeparture = this.getBlockDeparture();
      return !(
        isNullOrUndefined(blockDepartureResponse) ||
        blockDepartureResponse.status === false ||
        blockDepartureResponse.pedido === this.BLOCK_DEPARTURE_RESPONSE_FAILED
      );
    } catch (error) {
      console.log("PurchaseRequestService.isRequestBlockDepartureSuccess()");
      console.error(error);
      return false;
    }
  }

  public isRequestUnblockDepartureSuccess(): boolean {
    try {
      const unblockDepartureResponse: UnblockDeparture =
        this.getUnblockDeparture();
      return !(
        isNullOrUndefined(unblockDepartureResponse) ||
        unblockDepartureResponse.status === false ||
        unblockDepartureResponse.pedido ===
          this.UNBLOCK_DEPARTURE_RESPONSE_FAILED
      );
    } catch (error) {
      console.log("PurchaseRequestService.isRequestUnblockDepartureSuccess()");
      console.error(error);
      return false;
    }
  }

  private createUrlParamsForBlockDeparture(): string {
    try {
      const currentCruise: CruiseWrapper = this.getCruiseWrapper();
      let urlParamsAsArray: string[] = [];
      urlParamsAsArray.push(this.createUrlCabinsParamsForBlockDeparture());
      urlParamsAsArray.push(
        currentCruise
          .getCruise()
          .availability[
            currentCruise.getCurrentItineraryIndex()
          ].code.toString()
      );
      urlParamsAsArray.push(currentCruise.getCruise().code.toString());
      urlParamsAsArray.push(this.createUrlCodeParamForBlockDeparture());
      urlParamsAsArray = urlParamsAsArray.map((urlParamElement) => {
        if (isNullOrUndefined(urlParamElement)) {
          throw new Error();
        }
        return encodeURI(urlParamElement);
      });
      return urlParamsAsArray.join("/");
    } catch (error) {
      console.log("BlockDepartureService.createUrl()");
      console.error(error);
      return null;
    }
  }
  public jsonblockPurchaseCabin(code, percent): any {
    const purchaseCabins: PurchaseCabin[] = this.getPurchaseCabins();
    const currentCruise: CruiseWrapper = this.getCruiseWrapper();
    const paramMapArray: any = [];
    purchaseCabins.forEach((purchaseCabin) => {
      if (
        isNullOrUndefined(purchaseCabin.cabinaItinerarioMenor) ||
        isNullOrUndefined(purchaseCabin.cabinaItinerarioMenor.code) ||
        isNullOrUndefined(purchaseCabin.adults) ||
        isNullOrUndefined(purchaseCabin.children) ||
        isNullOrUndefined(purchaseCabin.camaCabinaList) ||
        isNullOrUndefined(purchaseCabin.camaCabinaList.code)
      ) {
        throw new Error();
      }
      const cabinFormValuesArray: any = {
        departure: purchaseCabin.cabinaItinerarioMenor.code.toString(),
        adults: purchaseCabin.adults.toString(),
        children: purchaseCabin.children.toString(),
        acomodation: purchaseCabin.camaCabinaList.code.toString(),
      };
      paramMapArray.push(cabinFormValuesArray);
    });

    return {
      reference: this.createUrlCodeParamForBlockDeparture(),
      itinerary: currentCruise
        .getCruise()
        .availability[currentCruise.getCurrentItineraryIndex()].code.toString(),
      boat: currentCruise.getCruise().code.toString(),
      cabins: paramMapArray,
      code,
      percent,
      transaction: this.getValueTransaction(),
      type: "0",
    };
  }

  private createUrlCabinsParamsForBlockDeparture(): string {
    try {
      const purchaseCabins: PurchaseCabin[] = this.getPurchaseCabins();
      let urlCabinsParam = "";
      const paramMapArray: string[][] = [];

      if (isNullOrUndefined(purchaseCabins) || purchaseCabins.length === 0) {
        return null;
      }
      purchaseCabins.forEach((purchaseCabin) => {
        if (
          isNullOrUndefined(purchaseCabin.cabinaItinerarioMenor) ||
          isNullOrUndefined(purchaseCabin.cabinaItinerarioMenor.code) ||
          isNullOrUndefined(purchaseCabin.adults) ||
          isNullOrUndefined(purchaseCabin.children) ||
          isNullOrUndefined(purchaseCabin.camaCabinaList) ||
          isNullOrUndefined(purchaseCabin.camaCabinaList.code)
        ) {
          throw new Error();
        }
        const cabinFormValuesArray: string[] = [];
        cabinFormValuesArray.push(
          purchaseCabin.cabinaItinerarioMenor.code.toString()
        );
        cabinFormValuesArray.push(purchaseCabin.adults.toString());
        cabinFormValuesArray.push(purchaseCabin.children.toString());
        cabinFormValuesArray.push(purchaseCabin.camaCabinaList.code.toString());
        paramMapArray.push(cabinFormValuesArray);
      });

      paramMapArray.forEach((cabinFormValuesArray: string[]) => {
        cabinFormValuesArray.forEach((cabinFormValue: string) => {
          urlCabinsParam += cabinFormValue + ",";
        });
        urlCabinsParam += "@";
      });
      return urlCabinsParam;
    } catch (error) {
      console.log("PurchaseRequest.createUrlCabinsParams()");
      console.error(error);
      return null;
    }
  }

  public getPurchaseDescription(): string {
    try {
      return (
        this.getCruiseWrapper().getCruiseItineraryDuration() +
        " Galapagos cruise on the " +
        this.getCruiseWrapper().getLongName() +
        " on " +
        this.getPurchaseCabinTypeSummary() +
        ", for " +
        this.getNumberOfPassengers() +
        " passenger"
      );
    } catch (error) {
      console.log("PurchaseRequestService.getPurchaseDescription()");
      console.error(error);
      return null;
    }
  }

  public getPurchaseShortDescription(): string {
    try {
      return (
        this.getCruiseWrapper().getCruiseItineraryDuration() +
        " Galapagos cruise on the " +
        this.getCruiseWrapper().getLongName()
      );
    } catch (error) {
      console.log("PurchaseRequestService.getPurchaseShortDescription()");
      console.error(error);
      return null;
    }
  }

  public getPurchaseDescriptor(): string {
    try {
      const itineraryDuration: string =
        this.getCruiseWrapper().getCruiseItineraryDuration();
      const cruiseName: string = this.getCruiseWrapper().getLongName();
      const itineraryDepartureTime: Date =
        this.getCruiseWrapper().getItineraryDepartureTime();
      const itineraryId: number =
        this.getCruiseWrapper().getCurrentItinerary().codigo;

      return (
        itineraryDuration +
        "_" +
        cruiseName.replace(" ", "-") +
        "_" +
        itineraryDepartureTime.toDateString().replace(" ", "-") +
        "_" +
        itineraryId
      );
    } catch (error) {
      console.log("PurchaseRequestService.getPurchaseDescriptor()");
      console.error(error);
      return null;
    }
  }

  private createUrlCodeParamForBlockDeparture(): string {
    try {
      const separator = "X";
      const purchaseContactPersonCompleteName =
        this.getPurchaseContactPerson().firstName +
        this.getPurchaseContactPerson().lastName;
      const purchaseTotalPassengers = this.getNumberOfPassengers();
      if (
        isNullOrUndefined(purchaseContactPersonCompleteName) ||
        isNullOrUndefined(purchaseTotalPassengers)
      ) {
        return null;
      }
      return (
        purchaseContactPersonCompleteName + separator + purchaseTotalPassengers
      );
    } catch (error) {
      console.log("BlockDepartureService.createUrlCodeParam()");
      console.error(error);
      return null;
    }
  }

  public getCabinAndBedDescriptionsSummary(): string[] {
    try {
      if (this.purchase.cabins.length < 1) {
        return [];
      }
      const cabinAndBedDescriptionsSummary: string[] = [];
      this.purchase.cabins.forEach((purchaseCabin: PurchaseCabin) => {
        const cabinaItinerarioWrapper: CabinaItinerarioMenorWrapper =
          new CabinaItinerarioMenorWrapper(purchaseCabin.cabinaItinerarioMenor);
        const text =
          cabinaItinerarioWrapper.getCabinaFullName() +
          " - " +
          purchaseCabin.camaCabinaList.cama.nombre;
        cabinAndBedDescriptionsSummary.push(text);
      });
      return cabinAndBedDescriptionsSummary;
    } catch (error) {
      console.log("PurchaseRequestService.getCabinAndBedDescriptionsSummary()");
      console.error(error);
      return [];
    }
  }

  public isAutoPaymentAccepted(): boolean {
    try {
      return environment.PURCHASE.IS_AUTO_PAYMENT_ACCEPTED;
    } catch (error) {
      console.log("PurchaseRequiestService.isAlmostDepartureTime()");
      console.error(error);
      return false;
    }
  }

  public setPrices(): void {
    this.setPurchaseAirTicket();
    this.setTotalCabinPrice();
    this.setPurchaseTotalPrice();
  }

  public validatePurchaseRequest(): boolean {
    try {
      return true; // TODO implementar este método para validar todos y cada uno de los datos susceptibles antes de confirmar compra
    } catch (error) {
      console.log("PurchaseCabinRequest.validatePurchaseRequest()");
      console.error(error);
      return false;
    }
  }

  public executePurchase(): boolean {
    try {
      return this.validatePurchaseRequest();
    } catch (error) {
      console.log("PurchaseCabinRequest.executePurchase()");
      console.error(error);
      return false;
    }
  }

  public getPaypalItems(): PaypalPaymentItem[] {
    try {
      const resultPaypalPaymentItem: PaypalPaymentItem[] = [];
      let sku: string;
      let name: string;
      let description: string;
      sku = "...";
      name = "";
      description = "";
      const cabinOrdinalNamePipe = new CabinOrdinalNamePipe();
      this.getPurchaseCabins().forEach((purchaseCabin, index) => {
        const cabinaItinerarioWrapper: CabinaItinerarioMenorWrapper =
          new CabinaItinerarioMenorWrapper(purchaseCabin.cabinaItinerarioMenor);
        console.log("cabinaItinerarioWrapper", cabinaItinerarioWrapper);
        description =
          description.length > 990
            ? description + "..."
            : description +
              " " +
              cabinOrdinalNamePipe.transform(index + 1) +
              ": " +
              cabinaItinerarioWrapper.getCabinaFullName() +
              ".";
      });
      resultPaypalPaymentItem.push({
        name: "Purchase Details",
        description,
        quantity: "1",
        price: this.getTotalPrice(),
        currency: environment.PAYPAL_EXPRESS_CHECKOUT_CONFIG.CURRENCY,
      });
      /* this.getPurchaseAirTicket().purchaseAirTicketDetails.forEach(
        ( purchaseAirTickedDetailArray: PurchaseAirTicketDetail[] ) => {
          purchaseAirTickedDetailArray.forEach( ( purchaseAirTickedDetail: PurchaseAirTicketDetail ) => {
            resultPaypalPaymentItem.push( {
              sku: 'GALAPAGOS_AIR_TICKET',
              name: 'Purchase Air Ticket for ' + purchaseAirTickedDetail.type,
              description: '',
              quantity: '1',
              price: purchaseAirTickedDetail.price,
              currency: environment.PAYPAL_EXPRESS_CHECKOUT_CONFIG.CURRENCY
            } );
          } );
      } );*/
      return resultPaypalPaymentItem;
    } catch (error) {
      console.log("PurchaseRequestService.getPaypalItems()");
      console.error(error);
      return [];
    }
  }

  public requestPostSpaces(): Observable<string> {
    try {
      const startdate: Date = new Date(
        this.getCruiseWrapper().getCurrentItinerary().startDate
      );
      const finishdate: Date = new Date(
        this.getCruiseWrapper().getCurrentItinerary().endDate
      );
      const departureDate: number = startdate.getTime();
      const arrivalDate: number = finishdate.getTime();
      const cruiseId: number = this.getCruiseWrapper().getCruise().code;

      if (
        isNullOrUndefined(departureDate) ||
        isNullOrUndefined(arrivalDate) ||
        isNullOrUndefined(cruiseId)
      ) {
        throw new Error(
          "Invalid params in PurchaseRequestService.requestPostSpaces()"
        );
      }

      const paramsUrl: string =
        departureDate + "/" + arrivalDate + "/" + cruiseId;
      const headers: HttpHeaders = new HttpHeaders({
        "Content-Type": "application/json",
      });
      return this.galavailRestService
        .request<string>(paramsUrl, "request_post_spaces", { headers })
        .catch((error) => {
          return this.galavailRequestsHandler(error);
        });
    } catch (error) {
      return this.galavailRequestsHandler(error);
    }
  }

  public setPostSpaces(postSpaces: boolean): void {
    try {
      this.purchase.postSpaces = postSpaces;
    } catch (error) {
      console.log("PurchaseRequestService.setPostSpaces()");
      console.error(error);
    }
  }

  private getTipoPaqueteEcommerce(): number {
    try {
      const cruiseWrepper: CruiseWrapper = this.getCruiseWrapper();
      if (
        !isNullOrUndefined(cruiseWrepper) &&
        !isNullOrUndefined(cruiseWrepper.getTipoPaqueteEcommerce())
      ) {
        return this.getCruiseWrapper().getTipoPaqueteEcommerce();
      }
    } catch (error) {
      console.log("PurchaseRequestService.getTipoPaqueteEcommerce()");
      console.error(error);
    }
    return null;
  }

  public isOptionAAvailable(): boolean {
    try {
      const tipoPaqueteEcommerce: number = this.getTipoPaqueteEcommerce();
      if (
        !isNullOrUndefined(tipoPaqueteEcommerce) &&
        !isNullOrUndefined(tipoPaqueteEcommerce) &&
        (tipoPaqueteEcommerce === TipoPaqueteEcommerceEnum.TYPE_A ||
          tipoPaqueteEcommerce === TipoPaqueteEcommerceEnum.TYPE_A_B)
      ) {
        return true;
      }
    } catch (error) {
      console.log("PurchaseRequest.isOptionAAvailable()");
      console.error(error);
    }
    return false;
  }

  public isOptionBAvailable(): boolean {
    try {
      const tipoPaqueteEcommerce: number = this.getTipoPaqueteEcommerce();
      if (
        !isNullOrUndefined(tipoPaqueteEcommerce) &&
        !isNullOrUndefined(tipoPaqueteEcommerce) &&
        (tipoPaqueteEcommerce === TipoPaqueteEcommerceEnum.TYPE_B ||
          tipoPaqueteEcommerce === TipoPaqueteEcommerceEnum.TYPE_A_B)
      ) {
        return true;
      }
    } catch (error) {
      console.log("PurchaseRequest.isOptionBAvailable()");
      console.error(error);
    }
    return false;
  }

  public isPackageOption(iipoPaqueteEcommerce: number): boolean {
    try {
      return (
        !isNullOrUndefined(this.getTipoPaqueteEcommerce()) &&
        this.getTipoPaqueteEcommerce() === iipoPaqueteEcommerce
      );
    } catch (error) {
      console.log("PurchaseRequestService.isPackageOption()");
      console.error(error);
    }
    return false;
  }

  setCodePaymentMethod(codePaymentMethod: number) {
    this.purchase.codePaymenMethod = codePaymentMethod;
  }

  getDescriptionStripe() {
    return this.createUrlCodeParamForBlockDeparture();
  }

  setTypeErrorOnHold(value: boolean) {
    this.isHtttpErrorOnHold = value;
  }

  getRequestSendConfirmEmail() {
    return this.preparePurchaseBookingConfirm();
  }
}
