import { RequestCrmAvail } from "../models/request-crm-avail";

export class ReservationClass {
  formReservation: RequestCrmAvail;
  constructor() {
    this.formReservation = {
      services: "",
      product: "",
      promotion: "",
      traveldate1: "",
      traveldate2: "",
      daycruise: "",
      opccruise: "",
      numberadult: "",
      numberchild: "",
      comments: "",
      languaje: "",
      intflights: "0",
      intamazon: "0",
      intperu: "0",
      intecu: "0",
      firstname: "",
      lastname: "",
      email: "",
      phone: "",
      country: "",
      agencyname: "",
      referencename: "",
      utm: {
        utmsource: "",
        utmmedium: "",
        utmcampaign: "",
        utmterm: "",
        utmadg: "",
        utmmtp: "",
        utmcon: "",
        utmad: "",
        utmkey: "",
        utmext: '',
        utmplace: '',
        urlorigen: '',
      },
      intnews: "",
      varip: "",
      device: "",
      findus: "Not specific",
      findusother: "Not specific",
      codeType: "",
      additionalServices:[],
      pricePerPerson: ''
    };
  }
}
