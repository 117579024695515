<div class="header-options">


    <div class="line" ng-reflect-ng-style="[object Object]" style="border-bottom: 7px solid rgb(51, 122, 183);">
        <div class="nav">


            <a class="logo" href="https://galavail.com">
                <div class="avatar w-100 loading-animate" style="margin-left: 5px;"></div>

            </a>
            <div class="headtitle" style="width: 40%;">

                <div class="text w-100 loading-animate" style=" height: 40px;"></div>

            </div>
        </div>
    </div>
</div>