import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';
@Injectable({
  providedIn: 'root'
})
export class RoyalRequestService {
  // Http Options
  httpOptions = {
    headers: new HttpHeaders(
      {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'DELETE'
      }
    )
  };
  private readonly GEO_IP_2_URL = environment.geoIpServiceUrl;
  constructor(
    private http: HttpClient,
    private cookieService: CookieService
  ) { }


  /** GET: get data it is generif for all entity */
  requestGetForRoyal(url: string): Observable<any> {
    return this.http.get<any>(`${url}`).pipe(
      tap(_ => console.log(`found urls matching`)),
      catchError(err => {
        console.log('caught mapping error and rethrowing', err);
        return throwError(err);
      })
    );
  }

  public requestPost(body: any): Observable<any> {
    return this.http.post<any>(environment.pathCRMroyal, body)
    .pipe(
      tap(_ => console.log(`found urls matching`)),
      catchError(err => {
        console.log('caught mapping error and rethrowing', err);
        return throwError(err);
      })
    );
  }
  getIP(): Observable<any> {
    return this.http.get<any>(this.GEO_IP_2_URL).pipe(
      tap(_ => console.log(`found urls matching`)),
      catchError(err => {
        console.log('caught mapping error and rethrowing when getting ip', err);
        return throwError(err);
      })
    );
  }

  public getGeoIpDirection(): Observable<string> {
    return this.http.get<any>(this.GEO_IP_2_URL).map((response: any) => {
      return response.traits.ip_address as string;
    });
  }

  getNameBoatToRoyal(product): string {
    let name = "";
    switch (product) {

      case "Natural Paradise":
        name = "Natural";
        break;


      case "Reina Silvia":
        name = "Reina"
        break;
      case "Cormorant II":
        name = "Cormorant-ii"
        break;
      case "Grand Majestic":

        name = "Grand"
        break;
      default:
        name = product
        break;
    }
    return name;
  }
  public getUtm(): any {
    return {
      utm_source: (this.getValuesofUTM('source').length === 0 ? 'none' : this.getValuesofUTM('source')),
      utm_medium: (this.getValuesofUTM('medium').length === 0 ? 'none' : this.getValuesofUTM('medium')),
      utm_campaign: (this.getValuesofUTM('campaign').length === 0 ? 'none' : this.getValuesofUTM('campaign')),
      utm_term: (this.getValuesofUTM('term').length === 0 ? 'none' : this.getValuesofUTM('term')),
    }
  }

  public getValuesofUTM(inputValues: string): any[] {
    const allCookies: {} = this.cookieService.getAll();
    let values = [];
    Object.keys(allCookies).forEach((key) => {
      const num = key.match(/\d+/g);
      const letr = key.match(/[a-zA-Z]+/g);
      if (inputValues === letr[0]) {
        values.push(allCookies[key]);
      }
    });


    return values;
  }

  getCategory(codeCategory): string {
    let arrayResponse = '';
    switch (codeCategory) {
      case 5: {
        arrayResponse = 'Luxury';
        break;
      }
      case 4: {
        arrayResponse = 'First Class';
        break;
      }
      case 3: {
        arrayResponse = 'Mid Range';
        break;
      }
      case 2: {
        arrayResponse = 'Budget';
        break;
      }
      default: {
        arrayResponse = 'All';
        break;
      }
    }
    return arrayResponse;
  }
}
