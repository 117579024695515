export enum CodeProcesscrmEnum {
    RG_ECOM_BOOK = "RG.ECOM.BOOK",
    RG_NP_HOLD = "RG.NP.HOLD",
    RG_IN_HOLD = "RG.IN.HOLD",
    RG_CO_HOLD = "RG.CO.HOLD",
    RG_COII_HOLD= "RG.COII.HOLD",
    RG_GMJ_HOLD= "RG.GMJ.HOLD",
    RG_CA_HOLD="RG.CA.HOLD",
    RG_HOLD = "RG.HOLD",
    RG_NP_AV = "RG.NP.AV",
    RG_VECOM = "RG.VECOM"
  }
  