<div id="payment" style="display: flex;flex-direction: column;">
    <div style="width: 100%;">

        <app-up-slide [cruiseStartDate]="this.cruiseStartDate" [startEndDates]="this.startEndDates" [position]="this.position" [startEndDatesType]="startEndDatesType" [numberPage]="3" [priceWithAirFare]="priceWithAirFare" [urlLogoCruise]="this.createUrlLogoCruise()"
            [promotion]="this.promotion" [cruise]="this.cruise" [cruiseItineraryDuration]="this.cruiseItineraryDuration">
        </app-up-slide>
        <br>
        <app-tour-summary [asideBoolean]=true [boatImageResource]="this.boatImagesResource" [cruiseInput]="cruise">
        </app-tour-summary>
        <div class="modules-ecomm" id="startCheckoutThird">
            <div class="headmod" [ngStyle]="{ 'background-color':  this.companyParamsService.getCompanyParams() == undefined ?('blue'): ('#'+this.companyParamsService.getMainColor()) }">
                Payment Options</div>
            <div class="note-confirm confirmed" *ngIf="this.isAutoPaymentAccepted === false">
                <p><strong>We are almost there! </strong></p>
                <p>Given that your cruise will take place within the next 72 hours, one of our Travel Advisors will contact you as soon as possible via e-mail to arrange your payment method and confirm your tour. </p>
                <p>Thank you for contacting us.</p>
            </div>
            <!-- STRIPE MESSAGE ERROR HEADER -->
            <div class="row" style="padding: 1em;" *ngIf="isVisibleMessageErrorBlockDeparture()">
               <div class="col-12">
                <app-error-payment [visible]="true" [errorHeader]="'Oops! Something went wrong!'" [errorText]="stripeService.departureErrorMessageStripe(companyParamsService.getInfoEmail())"></app-error-payment>
               </div>
               <div class="row" style="margin-top: 30px;">
                  <div class="col-12" style="text-align: center;margin-top: auto;">
                    <a id="back" class="" href="javascript:void()" href="javascript:returnOrigin()"> Back to search results </a>
                  </div>
               </div>
            </div>

            <div class="mod-content active"
              [ngStyle]="{ 'visibility':  this.isAutoPaymentAccepted? 'none':'hidden'}"
              style="min-height: 45rem !important;"
              *ngIf="!isVisibleMessageErrorBlockDeparture()">
                <div class="pricesvalue">
                    <h3>Payment Options: </h3>
                    <br>
                    <div style="display: flex;">
                        <div class="left" style="flex: 1; display: flex;">


                            <label for="reserve" style="display: flex;" *ngIf="hasThirtyPercent && this.companyParamsService.getStatusPartialPayment()">

                <input type="checkbox" name="reserve" id="reserve" [checked]="!isSelectedThirtyPercent"
                  (click)="selectThirtyPercent()" style="margin-right: 4px;">
                <p class="text-partial-payment">
                  Send full amount now
                </p>
              </label>
                            <div *ngIf="hasThirtyPercent && this.companyParamsService.getStatusPartialPayment()" style="display: flex; width: 52%; justify-content: flex-end;">
                                <h3 style="margin-top: 0px;" class="text-partial-payment">US$ {{allValuePrice.toLocaleString('en', {maximumFractionDigits:2})}}</h3>
                            </div>



                        </div>
                        <div class="right" style="flex: 1; display: flex;">
                            <label for="reserve" style="display: flex;" *ngIf="hasThirtyPercent && this.companyParamsService.getStatusPartialPayment()">

                <input type="checkbox" name="reserve" id="reserve" [checked]="isSelectedThirtyPercent"
                  (click)="selectThirtyPercent()" style="margin-right: 4px;">
                <p class="text-partial-payment">
                  Reserve with a {{this.companyParamsService.getPercentPartialPayment()}}% deposit only
                </p>
              </label>
                            <div *ngIf="hasThirtyPercent && this.companyParamsService.getStatusPartialPayment()" style="display: flex; width: 38%; justify-content: flex-end;">

                                <h3 style="margin-top: 0px;" class="text-partial-payment">US$ {{onlyThirtyPrice.toLocaleString('en', {maximumFractionDigits:2})}}
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="shadowBox" *ngFor="let item of paymentMethods;  let i = index">

                    <div class="headline" style="cursor: pointer;" (click)="changePaymentMethod(i)">
                        <h4>
                            <strong>› {{item.name}}</strong>
                        </h4>
                    </div>

                    <div class="miniContainer" [collapse]="!item.selected">


                        <div class="row" style="padding-left: 5px;">
                            <p>
                                {{item.description}}
                            </p>
                            <div class="row-image" (click)="flagClick=true">
                                <img *ngFor="let icon of item?.icons" src="assets/images/payments/{{icon.img}}" alt="{{icon.value}}" height="{{icon.heightValue}}" class="mr-2 mt-2">
                                <div *ngIf="item?.objectConfiguration" id="paypal-button-payment" [ngStyle]="{ 'pointer-events':  termsConditionPaytransfer ?'all':'none'}"></div>
                            </div>

                            <br>
                            <input type="checkbox" name="terms" value="TermsCondition" [checked]="termsConditionPaytransfer" (change)="this.changeTermsPayTransfer($event.target.checked)">
                            <a style="color: blueviolet;" [href]="companyParamsService.getTermsConditions()" target="_blank">
                              I agree to the Terms and Conditions
                            </a>
                            <br>
                            <!--STRIPE PAYMENT METHOD COMPONENT -->
                            <div class="stripe-design" (click)="flagClick=true">
                              <!-- STRIPE COMPONENT -->
                                    <div class="col-12" style="padding-top: 25px;" *ngIf="item.type === paymentMethodTypeEnum.STRIPE_CHECKOUT">
                                        <app-error-payment [visible]="stripeService.viewMessageErrorPaymet(typeErrorPaymentStripe) && !stripeService.loading(paymentOption) && !stripeService.exceededAttemps(attemptPaymentStripe)" [errorHeader]="'Oops! Something went wrong!'" [errorText]="stripeService.paymentErrorMessageStripe(purchaseRequestService.getErrorPayment(),companyParamsService.getInfoEmail())"></app-error-payment>
                                        <app-error-payment [visible]="stripeService.exceededAttemps(attemptPaymentStripe)" [errorHeader]="'Oops! Payment Attempt Limit Reached'" [errorText]="stripeService.attempsErrorMessageStripe(companyParamsService.getInfoEmail())"></app-error-payment>
                                    </div>
                                    <div class="row" *ngIf="item.type === paymentMethodTypeEnum.STRIPE_CHECKOUT">
                                      <div class="col-12">
                                        <div class="text-center">
                                          <app-stripe-payment *ngIf="!stripeService.exceededAttemps(attemptPaymentStripe)" [totalToPay]="purchaseTotalPrice"
                                          [termsCondictios]="termsConditionPaytransfer"
                                          [loading]="stripeService.loading(paymentOption)"
                                          [secundaryColor]="this.companyParamsService.getSecundaryColor()"
                                          (executeStripe)="executeWithStripe()"
                                          [ngStyle]="{ 'pointer-events':  termsConditionPaytransfer ?'all':'none'}"></app-stripe-payment>
                                        </div>
                                      </div>
                                    </div>
                              <!-- STRIPE COMPONENT -->
                          </div>
                            <a href="{{item.linkInfo}}" target="_blank" rel="noopener noreferrer" style="padding-left: 5px;">
                              {{item.linkInfo}}
                            </a>
                        </div>
                        <small class="help-block" style="color: red;" *ngIf="!termsConditionPaytransfer && flagClick">
                          To continue you must agree to our Terms and Conditions.
                        </small>
                        <div class="text-center" (click)="flagClick=true" *ngIf="item?.objectConfiguration === undefined || item?.objectConfiguration === null">
                            <a *ngIf="!isProcessing && !isProcessingStripe" [ngStyle]="{ 'pointer-events':  termsConditionPaytransfer ?'all':'none'}" (click)="this.blockCabinNewjson()" href="javascript:void(0)" class="lngnext"> Accept</a>
                        </div>
                        <div class="text-center" (click)="flagClick=true" style="text-align: center;">
                            <img *ngIf="isProcessing || isProcessingStripe" src="assets/images/sending.gif" alt="sending" style="text-align: center;">
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </div>

    <app-end-slide [thirdString]="this.activeEndSlide" [numberPage]="3"></app-end-slide>
</div>
