import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-stripe-payment',
  templateUrl: './stripe.component.html',
  styleUrls: ['./stripe.component.css']
})
export class StripeComponent implements OnInit {

  @Input() secundaryColor = "#000";
  @Input() public loading = false;
  @Input() totalToPay: number = 0;
  @Input() block = false;
  @Input() stripeKey = "";
  @Input() termsCondictios = false;
  @Output() public executeStripe: EventEmitter<any> = new EventEmitter();

  paymentHandler: any = null;
  constructor() {}

  ngOnInit() {
    this.invokeStripe();
  }

  makePayment(amount: number) {
    this.executeStripe.emit();
  }

  invokeStripe() {
    if (!window.document.getElementById("stripe-script")) {
      const script = window.document.createElement("script");
      script.id = "stripe-script";
      script.type = "text/javascript";
      script.src = "https://checkout.stripe.com/checkout.js";
      script.onload = () => {
        this.paymentHandler = (<any>window).StripeCheckout.configure({
          key: this.stripeKey,
          locale: "auto",
          token: function (stripeToken: any) {
            console.log(stripeToken);
          },
        });
      };

      window.document.body.appendChild(script);
    }
  }

}
