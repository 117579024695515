import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoToTopDirective } from './directives/go-to-top/go-to-top.directive';
import { RouterModule } from '@angular/router';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
  ],
  declarations: [
    GoToTopDirective,
  ],
  exports: [
    GoToTopDirective,
    FormsModule,
  ],
})
export class SharedModule { }
