import { Injectable } from "@angular/core";
import { ResponseOnHold } from "src/app/enums/response-on-hold.enum";
import { ConfiguracionPagoTarjeta, PaymentOption } from "../commons/parameters";
import { CheckoutStripeService } from "src/app/shared/services/checkout-stripe.service";
import { PurchaseRequestService } from "src/app/reserve/services/purchase-request/purchase-request.service";
import { CommonService } from "./common.service";
import { PaymentMethod } from "src/app/models/payment-method";
import { IStripe } from "src/app/models/request-stripe";
import { Company } from "src/app/models/company";
import { PaymentMethodTypeEnum } from "src/app/enums/payment-methods-type.enum-";
import { CompanyParamsService } from "src/app/services/companyParams/company-params.service";
import { NgWebflowService } from "ngweflow";
import { WebFlowAction } from "src/app/enums/web-flow-action.enum";
import { ReservationClass } from "src/app/classes/reservation.class";


export enum AuditStep {
  START = 'START',
  PAYMENT = 'PAYMENT',
  ERROR = 'ERROR',
  ERROR_BLOCK = 'ERROR_BLOCK'
}

export interface ResultProcess{
  error: boolean;
  type?: ResponseOnHold;
  data?: any;
}

@Injectable({
  providedIn: 'root',
})
export class StripeService {
  readonly totalPaymentAttempt = ConfiguracionPagoTarjeta.intentos_pago_tarjeta;

  constructor(private checkoutStripeService: CheckoutStripeService,
              private purchaseRequestService: PurchaseRequestService,
              private commonService: CommonService,
              public companyParamsService: CompanyParamsService,
              private ngWebflowService: NgWebflowService
              ) {}


  /**
   * Obtener el metodo de pago de stripe
   * @param company empresa Ejm. companyParamsService.company.
   * @returns PaymentMethod
   */
  paymentMethodStripe = (): PaymentMethod | undefined => {
   try {
      const index = this.companyParamsService.company.paymentMethods.findIndex(
        (item) =>
          Number(item.type) === Number(PaymentMethodTypeEnum.STRIPE_CHECKOUT)
      );
      return index || index === 0
        ? this.companyParamsService.company.paymentMethods[index]
        : undefined;
    } catch (error) {}
  }

  /**
   * Generar objeto para realizar el pago con stripe
   * @param callback  Ejm.getPaymentStripe
   * @returns IStripe
   */
  paymentstripe = (paymentMethodStripe:PaymentMethod, stripeToken: any,purchaseTotalPrice:any, descriptionStripe:any): IStripe => {
        return{
          token: stripeToken,
          amount: purchaseTotalPrice * 100,
          description: descriptionStripe,
          stripeKeyServer: paymentMethodStripe
            ? paymentMethodStripe.objectConfiguration.stripeKeyServer
            : 'no working',
        }
    }

  /**
   * Procesar el pago con stripe
   * @param request
   * @returns
   */
    processPaymentstripe = async (request: any, reservationClass: ReservationClass, companyName?:String): Promise<ResultProcess> => {
          return new Promise<ResultProcess>((resolve, reject) => {
            this.checkoutStripeService.requestPayment(request).subscribe(
              async (data: { data: string; id: string }) => {
                data = JSON.parse(String(data));
                if(data.data === 'success') {
                  const isBlockDepartureSuccess = await this.commonService.blockDeparture();
                  if(isBlockDepartureSuccess){
                    await this.commonService.requestBookingConfirmation();
                    if (companyName === "Royal Galapagos") {
                      await this.commonService.requestRoyalForm(reservationClass);
                    }
                    resolve({ error: false, data: data });
                  }else {
                    this.commonService.notificationError(ResponseOnHold.RESERVED_ERROR);
                    resolve({ error: true, type: ResponseOnHold.RESERVED_ERROR, data: this.purchaseRequestService.getBlockCabinRequest()});
                  }
                }else { // el servicio retorna failed cuando hay un error en la pasarela de pago
                  this.purchaseRequestService.setErrorPayment(this.getMessageError(data));
                  this.commonService.notificationError(ResponseOnHold.PAYMENT_ERROR);
                  resolve({ error: true, type: ResponseOnHold.PAYMENT_ERROR, data: data });
                }
              },
              (error: any) => {
                resolve({ error: true });
              }
            );
          });
    }


    loading = (paymentOption: PaymentOption):boolean => {
        if(paymentOption === PaymentOption.NONE) {
          return false;
        }
        if(paymentOption === PaymentOption.STRIPE) {
          return true;
        }
        return false;
    }

    viewMessageErrorPaymet = (type: ResponseOnHold):boolean => {
      if (type === ResponseOnHold.PAYMENT_ERROR) return true
      return false;
    }

    viewMessageErrorReserved = (type: ResponseOnHold):boolean => {
      if (type === ResponseOnHold.RESERVED_ERROR) return true
      return false;
    }

    viewPaymentOptions = (paymentOption:PaymentOption ,type: ResponseOnHold): boolean => {
      if (paymentOption === PaymentOption.STRIPE && type === ResponseOnHold.RESERVED_ERROR ) return false;
      return true;
    }

    viewButtonActions = (paymentOption:PaymentOption, type: ResponseOnHold):boolean => {
      if (paymentOption === PaymentOption.STRIPE && type === ResponseOnHold.PAYMENT_ERROR ) return false;
      return true;
    }

    /**
     * Verificar si se ha superado el numero de intentos de pago
     * @param attemps
     * @returns
     */
    exceededAttemps = (attemps:number):boolean => {
      if(attemps >= this.totalPaymentAttempt){
        return true;
      }
      return false;
    }

  /**
   * Metodo que se encarga de obtener el error con el mensaje correspondiente
   * @param error Mensaje de error
   */
  public paymentErrorMessageStripe(message:string, email:string):string {
    let errorText =
        `Your booking could not be completed due to an error with your payment method (${message}).
        Your transaction has not been charged. We recommend you try a different payment method or Contact Us at (
        ${email})`;
    return errorText;
  }

  /**
   * Metodo que se encarga de obtener el error cuando supera el numero de intentos
   * @param error Mensaje de error
   */
  public attempsErrorMessageStripe(email:string):string {
    let errorText =
        `We apologize, but you have reached the maximum number of allowed attempts for this payment method.
        Your transaction has not been processed. We recommend you try a different payment method or Contact Us at (
        ${email})`;
    return errorText;
  }


    /**
   * Metodo que se encarga de obtener el error cuando no existe disponibilidad
   * @param error Mensaje de error
   */
  public departureErrorMessageStripe(email:string):string {
    let errorText =
        `Booking Failed: The selected cabin is no longer available.
        Your payment for this transaction has not been charged.
        We recommend you choose another cabin, restart your search, or Contact Us at (
        ${email})`;
    return errorText;
  }

  /**
   * Metodo que se encarga de obtener el error enviado por stripe
   * @param error Mensaje de error
   */
  getMessageError = (data:any) =>{
    return `Code: ${data.errorCode}, Message: ${data.errorMessage}, Type: ${data.errorType}`;
  }

    /**
   *  Metodo que se encarga de auditar el proceso de pago con stripe
   * @param step paso del proceso
   * @param data datos del proceso
   */
  auditPaymentStripe =(step : AuditStep, data?: any) => {
    switch(step){
      case AuditStep.START:
        this.ngWebflowService.addWebFlowAction(WebFlowAction.EXECUTING_PAYMENT_STRIPE_START, { message: 'init_process_payment_stripe' });
        break;
      case AuditStep.PAYMENT:
        this.ngWebflowService.addWebFlowAction(WebFlowAction.EXECUTING_PAYMENT_STRIPE_SUCCESS, data);
        break;
      case AuditStep.ERROR:
        this.ngWebflowService.addWebFlowAction(WebFlowAction.EXECUTING_PAYMENT_STRIPE_ERROR, data);
        break;
      case AuditStep.ERROR_BLOCK:
        this.ngWebflowService.addWebFlowAction(WebFlowAction.EXECUTING_PAYMENT_BLOCK_ERROR, data);
        break;
    }
  }

}

