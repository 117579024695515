import { PromoCode } from './../../../enums/promo-code.enum';
import { PromoService } from './../../services/promo/promo.service';
import { CompanyParamsService } from './../../../services/companyParams/company-params.service';
import { PurchaseRequestStatus } from './../../../enums/purchase-request-status.enum';
import { BookingConfirmationResponse } from './../../../models/purchase/booking-confirmation-response';
import { Component, OnInit, Input, AfterViewInit, ElementRef } from '@angular/core';
import { PurchaseCabin } from 'src/app/models/purchase-cabin';
import { CruiseService } from '../../services/cruise/cruise.service';
import { Cruise } from 'src/app/models/cruise';
import { GalavailServiceEcommer } from 'clientwsGalavail';
import {
  RequestFindAvailabilityByCode
} from 'clientwsGalavail/src/app/modules/webservices/services/ws/galavail-ws/models/requestFindAvailabilityByCode';
import { PurchaseRequestService } from '../../services/purchase-request/purchase-request.service';
import * as $ from 'jquery';
import { isNullOrUndefined } from 'util';
import { environment } from 'src/environments/environment';
import { NgWebflowService } from 'ngweflow';
import { WebFlowAction } from 'src/app/enums/web-flow-action.enum';


@Component({
  selector: 'app-checkout-fourth',
  templateUrl: './checkout-fourth.component.html',
  styleUrls: ['./checkout-fourth.component.css']
})
export class CheckoutFourthComponent implements OnInit, AfterViewInit {
  @Input() public asideBoolean: boolean;
  public itineraries: any;
  public cruises: Cruise[];
  requestFindAvailabilityByCode: RequestFindAvailabilityByCode;
  public cruise: any;
  public adults: number;
  public children: number;
  public totalPassengers: number;
  public cabinsNumber: PurchaseCabin[];
  public totalCabinPrice: number;
  public totalPrice: number;
  public includesFreeTransfers: boolean;
  public includesGalapagosAirTickets: boolean;
  public includeAirFare: boolean;
  public tourLength: number;
  public cruiseStartDate: string;
  public activeEndSlide: string;
  public startEndDates: string;
  public startEndDatesType: string;
  public promotion: string;
  public priceWithAirFare: number;
  public itineraryIndex: number;
  public itinerariesImagesResource: string;
  public boatImagesResource: string;
  public purchaseCabinInformation: string[];
  public position: boolean;
  public cruiseItineraryDuration: string;
  public isCalipso: boolean;
  PROMO_RAC = PromoCode.PROMO_RAC;
  TRANSFERTEXT = "All the information for wire transfer was successfully sent to your registered email address."
  WITHOUTTRANSFERTEXT = "One of our Trip Advisors is going to contact you as soon as possible to confirm your payment and booking."


  constructor(
    public purchaseRequestService: PurchaseRequestService,
    private cruiseService: CruiseService,
    private galavailServiceEcommer: GalavailServiceEcommer,
    public companyParamsService: CompanyParamsService,
    public promoService: PromoService,
    private elementRef: ElementRef,
    private ngWebflowService: NgWebflowService,
  ) {
    $(document).ready(function () {
      $('html, body').animate({
        scrollTop: $('#startCheckoutFourth').offset().top
      }, 1000);
    });
  }


  ngOnInit() {
    if (!isNullOrUndefined(this.purchaseRequestService.purchase.cruise)) {
      const selecteIndex = 0;
      // this.itineraries = this.cruiseService.createIterDay(this.cruises[0]);
      this.activeEndSlide = 'active';
      this.cruise = this.purchaseRequestService.purchase.cruise;
      this.isCalipso = this.cruiseService.isCalipso(this.cruise);
      this.itineraries = this.cruiseService.createIterDay(this.cruise);
      this.adults = this.purchaseRequestService.getNumberRequestAdults();
      this.children = this.purchaseRequestService.getNumberRequestChildren();
      this.totalPassengers = this.purchaseRequestService.getNumberRequestPassengers()
      this.cabinsNumber = this.purchaseRequestService.purchase.cabins;
      this.totalCabinPrice = this.purchaseRequestService.purchase.totalCabinPrice;
      this.totalPrice = this.purchaseRequestService.purchase.totalPrice;
      this.includesFreeTransfers = this.purchaseRequestService.purchase.includesFreeTransfers;
      this.includesGalapagosAirTickets = this.purchaseRequestService.purchase.includesGalapagosAirTickets;
      this.includeAirFare = this.purchaseRequestService.purchase.includeAirFare;
      this.purchaseCabinInformation = this.getPurchaseCabinInformation();
      this.cruiseStartDate = this.cruise.availability[this.cruise.iterIndex].date;
      this.startEndDates = this.cruise.availability[this.cruise.iterIndex].startEndDates;
      this.startEndDatesType = this.cruise.availability[this.cruise.iterIndex].name;
      this.promotion = this.cruise.availability[this.cruise.iterIndex].promotion;
      this.itineraryIndex = selecteIndex;
      this.priceWithAirFare = this.cruiseService.getCruisePriceinNewFormat(this.cruise, this.itineraryIndex, true);
      this.initializeItinerariesImagesResource();
      this.initializeBoatImageResource();
      this.position = true;
      this.cruiseItineraryDuration = this.purchaseRequestService.getCruiseWrapper().getCruiseItineraryDuration();

      if (this.adults) {
        this.asideBoolean = true;
      }
      //this.requestBookingConfirmation();
    } else {
      window.location.href = window.location.href.substring(0, window.location.href.length - 1);
    }

  }
  hasIncludes(): boolean {
    return  (this.includesGalapagosAirTickets || this.includeAirFare)
  }
  ngAfterViewInit() {
    this.setScripts()
  }
  public setScripts() {
    this.companyParamsService.getScriptsForThankYouPage().forEach((item) => {
      var head = document.createElement("script");
      head.type = "text/javascript";
      head.src = item.srcLinks;
      document.head.appendChild(head);
    });
  }
  createUrlLogoCruise() {
    return environment.app_resources.logoCruise + this.cruise.logo
  }


  private getPurchaseCabinInformation(): string[] {
    try {
      return this.purchaseRequestService.getPurchaseCabinObjectSummary().map(
        (purchaseCabinObjectSummary: { cabinaItinerarioNombre: string }) => {
          return purchaseCabinObjectSummary.cabinaItinerarioNombre;
        });
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  private initializeItinerariesImagesResource(): void {
    for (let i = 0; i < (this.cruise.itineraries.length); i++) {
      if (this.cruise.itineraries[i].code === this.cruise.availability[0].itinerary) {
        this.itinerariesImagesResource = environment.app_resources.itinerario + this.cruise.itineraries[i].images[0];
        break;
      }
    }
  }

  private initializeBoatImageResource(): void {
    this.boatImagesResource = environment.app_resources.logoCruise+ this.cruise.image;
  }

  public hasTextFree(service: string) {
    const textArray = service.split(' ');
    let auxString = '<P>';
    textArray.forEach(text => {
      if (text === 'FREE') {
        auxString = auxString + ' ' + `<strong>${text}</strong>`
      } else {
        auxString = auxString + ' ' + text;
      }
    })
    auxString = auxString + '</p>'
    return auxString;
  }
  getThanYouText() {
    let textValue = this.purchaseRequestService.getPaymentMethod().transfer ? this.TRANSFERTEXT : this.WITHOUTTRANSFERTEXT;

    return textValue;
  }

  addLogPrintReservation(){
    this.ngWebflowService.addWebFlowAction(
      WebFlowAction.PRINT_RESERVATION_INVOICE,
      {}
    );
  }
}
