<div id="checkout" style="height: auto;">
  <app-up-slide [cruiseStartDate]="this.cruiseStartDate" [startEndDates]="this.startEndDates" [position]="this.position"
    [startEndDatesType]="startEndDatesType" [priceWithAirFare]="priceWithAirFare" [numberPage]="4"
    [urlLogoCruise]="this.createUrlLogoCruise()" [promotion]="this.promotion" [cruise]="this.cruise"
    [cruiseItineraryDuration]="this.cruiseItineraryDuration">
  </app-up-slide>
  <br>
  <app-tour-summary [asideBoolean]=true [boatImageResource]="this.boatImagesResource" [unActiveTourIncludes]=true
    [cruiseInput]="cruise" (purchaseCabinInformation)="this.purchaseCabinInformation"></app-tour-summary>
  <div class="modules-ecomm" id="startCheckoutFourth">
    <div [ngClass]="companyParamsService.getVisiblepago() ? 'headmodbuy' : 'headmod'"
      [ngStyle]="{ 'background-color':  this.companyParamsService.getCompanyParams() == undefined ?('blue'): ('#'+this.companyParamsService.getMainColor()) }">
      {{companyParamsService.getVisiblepago()? "Booking Summary":"Tour Summary"}}</div>
    <div class="mod-content active">
      <div *ngIf="!companyParamsService.getVisiblepago()">

        <div class="note-confirm confirmed">
          <!--

      <p *ngIf="this.purchaseRequestService.getPurchaseContactPerson().email">
        Thank you for your reservation request, an email has been sent to

        {{this.purchaseRequestService.getPurchaseContactPerson().email}}.
        A member of our sales team will contact you shortly!.</p>
    -->
          <p>Thank you for your booking request. A member of our sales team will be in touch shorty to finalize your
            reservation.</p>
        </div>
        <h3>Reservation details: </h3>
        <p>&nbsp;</p>
      </div>
      <div *ngIf="companyParamsService.getVisiblepago()">
        <app-table-payment-summary></app-table-payment-summary>
      </div>



      <h4 *ngIf="this.cruise">{{this.cruise.availability[this.cruise.iterIndex].length}}- Cruise on the
        {{this.cruise.name}} {{this.cruise.type}} on: </h4>
      <ul class="inclusions" style="margin-top: 15px;margin-bottom: 30px;" *ngIf="this.cruise">
        <div *ngFor="let numberCabins of this.cabinsNumber; let i = index">
          <li>{{this.purchaseCabinInformation[i]}}</li>
        </div>
      </ul>


      <p style="text-decoration: underline; margin-top: 15px;"
        *ngIf="this.cruise.availability[this.cruise.iterIndex].promotionType!==PROMO_RAC || hasIncludes()">Tour
        Includes:</p>
      <ul class="inclusions" *ngIf="this.cruise">
        <!--
          <li *ngIf="this.cruise.availability[this.cruise.iterIndex].promotionType===PROMO_RAC">
            {{this.cruise.availability[this.cruise.iterIndex].length}}-Galapagos Cruise
          </li>
        -->
        <li *ngIf="this.cruise.availability[this.cruise.iterIndex].promotionType!==PROMO_RAC">
          {{this.cruise.availability[this.cruise.iterIndex].length}}- Itinerary
          ( {{this.cruise.availability[this.cruise.iterIndex].promotionLabel}})</li>
        <div *ngIf="hasIncludes()">
          <li>
            Cruise Flight Tickets*</li>


          <li *ngFor="let aServices of this.purchaseRequestService.getAditionalServices()">
            <!--
              <strong style="color: #a30000;">FREE</strong>
            -->
            <p [innerHTML]="hasTextFree(aServices)">**</p>
          </li>
        </div>


      </ul>
      <p *ngIf="hasIncludes()" style="margin-left: 45px;margin-bottom: 0px;">
        <span _ngcontent-c13="" style="font-size: 11px;">* Flights are subject to availability</span>
      </p>
      <p style="margin-left: 45px;  margin-bottom: 10px;"
        *ngIf="this.purchaseRequestService.getAditionalServices().length>0">
        <span _ngcontent-c13="" style="font-size: 11px;">** Free services apply only when the flights are on
          the
          same dates of the
          cruise.
        </span>
      </p>
      <h3 style="margin-top: 25px;">
        <strong>
          Itinerary:
        </strong>
      </h3>


      <app-itinerary-details [itineraries]="this.itineraries" [className]="'tab-itinerary'"
        [isIncludeTableHeader]="false"></app-itinerary-details>

      <div id="default"><img [src]="this.itinerariesImagesResource" width="500" height="295" alt=""></div>

      <div class="dprint">
        <a onclick="window.print()" (click)="this.addLogPrintReservation()"class="print"><img src="assets/images/pdf.png" width="35" height="50"
            alt="Print this page" title="Print this page" /></a>
        </div>
    </div>
  </div>
  <div class="endslide">

    <app-end-slide [fourthString]="this.activeEndSlide" [numberPage]="4"></app-end-slide>
  </div>
</div>
