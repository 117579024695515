<div *ngIf="this.isSearching == true || this.companyParamsService.getCompanyParams()==undefined">
    <app-loading></app-loading>
</div>

<div
    [ngStyle]="{'display': this.isserch() == true || this.companyParamsService.getCompanyParams()==undefined? 'none': 'inherit'}">
    <app-up-slide [cruiseStartDate]="this.cruiseStartDate" [startEndDates]="this.startEndDates" [cruise]="this.cruise"
        [startEndDatesType]="this.startEndDatesType" [cruiseItineraryDuration]="this.cruiseItineraryDuration"
        [priceWithAirFare]=" this.priceWithoutAirFare" [urlLogoCruise]="this.createUrlLogoCruise()" [isFirstPage]="true"
        [wasPriceWithAirFare]="this.wasPriceWithoutAirFare" [numberPage]="1" [hastPromo]="this.hasPromo"
        [promotion]="this.promotion" *ngIf="this.cruise !== undefined" [position]="false">
    </app-up-slide>
    <!-- [priceWithAirFare]="this.priceWithAirFare" -->
    <app-tour-summary [asideBoolean]=false [boatImageResource]="this.boatImagesResource" [cruiseInput]="cruise"
        *ngIf="this.cruise !== undefined">
    </app-tour-summary>
    <div *ngIf="this.cruise !== undefined" class="intro-ecomm" style="padding-bottom: 20px;">
        <h2><strong>
                {{this.cruise.name}} Cruise
            </strong>
            <span class="stars"
                [ngStyle]="{ 'color':  this.companyParamsService.getCompanyParams() == undefined ?('black'): ('#'+this.companyParamsService.getMainColor()) }">{{this.cruise.categoryII}}</span>
        </h2>

        <p>{{this.cruise.description}}<br></p>
        <br>
        <!--
    <a href="javascript:void()" target="_blank">[More cruise info]</a>
    -->

        <a href="javascript:void()" (click)="shareBoat()" style="padding-right: 18px;"> share</a>


    </div>
    <div id="calendar" *ngIf="this.cruise !== undefined">
        <aside class="pt1">
            <h3
                [ngStyle]="{ 'color':  this.companyParamsService.getCompanyParams() == undefined ?('black'): ('#'+this.companyParamsService.getSecundaryColor()) }">
                Dates &amp; Itinerary

                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
                    class="bi bi-caret-right-fill"
                    [ngStyle]="{ 'color':  this.companyParamsService.getCompanyParams() == undefined ?('black'): ('#'+this.companyParamsService.getSecundaryColor()) }"
                    viewBox="0 0 16 16">
                    <path
                        d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                </svg>
            </h3>
        </aside>
        <div class="modules-ecomm">
            <div class="headmod" id="idHeader"
                [ngStyle]="{ 'background-color':  this.companyParamsService.getCompanyParams() == undefined ?('white'): ('#'+this.companyParamsService.getMainColor()) }">
                {{this.cruise.availability[this.cruise.iterIndex].length}} - Itinerary: {{startEndDates}}
                ({{startEndDatesType}})
            </div>
            <div class="mod-content active">
                <h4>{{this.cruise.availability[this.cruise.iterIndex].dateLong}}</h4>
                <app-itinerary-details [itineraries]="this.itineraries" [className]="'tab-itinerary'"
                    [isIncludeTableHeader]="false"></app-itinerary-details>
                <div id="default"><img [src]="this.itinerariesImagesResource" width="500" height="295" alt=""></div>
            </div>
            <div style="padding: 0 35px;">
                <a (click)="cruiseDatesSelection()" href="javascript:void(0)" class="next" id="pax">Next</a>
                <div style="width: 350px; margin: 0px;">
                    <p style="text-align: justify; font-size: 12px;">*Kindly note that itinerary activities and
                        visiting points may be adjusted in response to destination-specific regulations, weather
                        conditions, or unexpected events.
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div id="packages">
        <aside class="pt1">
            <h3
                [ngStyle]="{ 'color':  this.companyParamsService.getCompanyParams() == undefined ?('black'): ('#'+this.companyParamsService.getSecundaryColor()) }">
                Package Selection

                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
                    class="bi bi-caret-right-fill"
                    [ngStyle]="{ 'color':  this.companyParamsService.getCompanyParams() == undefined ?('black'): ('#'+this.companyParamsService.getSecundaryColor()) }"
                    viewBox="0 0 16 16">
                    <path
                        d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                </svg>
            </h3>
        </aside>
        <div class="modules-ecomm">
            <div class="headmod" id="idHeader"
                [ngStyle]="{ 'background-color':  this.companyParamsService.getCompanyParams() == undefined ?('white'): ('#'+this.companyParamsService.getMainColor()) }">
                Choose any of our tour options below</div>
            <div *ngIf="this.isActive">
                <div class="mod-content option"
                    *ngIf="this.isDefaultOptions === true || this.isShowOptionA === true || this.purchaseRequestService.isOptionAAvailable()">

                    <div class="prices">
                        from <b>US$ {{ this.priceWithAirFare| number:'3.0-0' }}</b> p/person
                        <u *ngIf="this.hasPromo">US$ {{ this.wasPriceWithAirFare| number:'3.0-0' }}</u>
                    </div>
                    <h4 *ngIf="!isRoyal()"><span>Option A:</span> Super Savings Package</h4>
                    <h4 *ngIf="isRoyal()"><span>Option A:</span> Galapagos Package</h4>


                    <p style="padding-top: 10px;">
                        <img src="assets/images/search-pages/icons/yacht.png" title="Cruise">
                        <img src="assets/images/search-pages/icons/plus.png" alt="+"
                            style="padding-right: 5px; padding-left: 5px;" title="+">
                        <img src="assets/images/search-pages/icons/plane.png" title="Airfare">

                        <img src="assets/images/search-pages/icons/plus.png" alt="+"
                            style="padding-right: 5px; padding-left: 5px;" title="+"
                            *ngIf="this.hasWordInSA('hotel')||this.hasWordInSA('Hotel')||this.hasWordInSA('Hotels')||this.hasWordInSA('hotels')">
                        <img src="assets/images/search-pages/icons/hotel.png" title="Hotel"
                            *ngIf="this.hasWordInSA('hotel')||this.hasWordInSA('Hotel')||this.hasWordInSA('Hotels')||this.hasWordInSA('hotels')">
                        <img src="assets/images/search-pages/icons/plus.png" alt="+"
                            style="padding-right: 5px; padding-left: 5px;" title="+"
                            *ngIf="this.hasWordInSA('transfers')||this.hasWordInSA('Transfers')||this.hasWordInSA('Transfer') ||this.hasWordInSA('transfer')">
                        <img src="assets/images/search-pages/icons/van.png" title="Transfers"
                            *ngIf="this.hasWordInSA('transfers')||this.hasWordInSA('Transfers')||this.hasWordInSA('Transfer') ||this.hasWordInSA('transfer')">


                    </p>
                    <ul>
                        <li *ngIf="this.cruise">{{this.cruise.availability[this.cruise.iterIndex].length}} Itinerary
                            <i>({{this.cruise.availability[this.cruise.iterIndex].promotionLabel}})</i>
                            <ul>
                                <b>Includes:</b>
                                <li>All meals during the cruise</li>
                                <li>Transfers between the islands</li>
                                <li>Excursions</li>
                                <li>Non-alcoholic drinks</li>
                            </ul>
                        </li>
                        <li>Cruise Flight Tickets*</li>

                        <div *ngIf="this.cruise.availability[this.itineraryIndex]?.aditionalServices?.length">
                            <li
                                *ngFor="let aServices of this.cruise.availability[this.itineraryIndex].aditionalServices">
                                <!--
                   <strong style="color: #a30000;">FREE</strong>
                 -->
                                <p [innerHTML]="hasTextFree(aServices)" class="test"></p>
                            </li>
                        </div>

                    </ul>


                    <p style="
          margin-left: 15px;
      " *ngIf="this.isShowOptionA ||
                this.cruise.availability[itineraryIndex].aditionalServices.length>0 ||
                 this.includeGalapagosAirTickets">
                        <span *ngIf="this.cruise.availability[this.itineraryIndex].aditionalServices.length>0"
                            _ngcontent-c13="">**
                            Free services apply only when the
                            flights are on the same dates of the
                            cruise.</span> <br>
                    </p>
                    <a *ngIf="!this.isShowOptionA" href="javascript:void(0)" class="next" (click)="toggleCabins('A')"
                        (click)="select()" [ngStyle]="{'opacity':this.isShowOptionA? '1': '0.5'}">Select</a>
                    <a *ngIf="this.isShowOptionA" href="javascript:void(0)" class="next" (click)="toggleCabins('A')"
                        (click)="selected()" [ngStyle]="{'opacity':this.isShowOptionA? '1': '0.5'}">Selected</a>
                </div>
                <div class="mod-content option" id="packagesOptions"
                    *ngIf="this.isDefaultOptions === true || this.isShowOptionB === true || this.purchaseRequestService.isOptionBAvailable()">
                    <div class="prices">
                        from <b>US$ {{ this.priceWithoutAirFare| number:'3.0-0' }}</b> p/person
                        <u *ngIf="this.hasPromo">US$ {{ this.wasPriceWithoutAirFare| number:'3.0-0' }}</u>
                    </div>
                    <h4><span>Option B:</span> Cruise Only</h4>
                    <p style="padding-top: 10px;">
                        <img src="assets/images/search-pages/icons/yacht.png" title="Cruise">
                        <img src="assets/images/search-pages/icons/plus.png" alt="+"
                            style="padding-right: 5px; padding-left: 5px;" title="+"
                            *ngIf="this.isOptionBIncludesFreeTransfers">
                        <img src="assets/images/search-pages/icons/hotel.png" title="Hotel"
                            *ngIf="this.isOptionBIncludesFreeTransfers">
                        <img src="assets/images/search-pages/icons/plus.png" alt="+"
                            style="padding-right: 5px; padding-left: 5px;" title="+"
                            *ngIf="this.isOptionBIncludesFreeTransfers">
                        <img src="assets/images/search-pages/icons/van.png" title="Transfers"
                            *ngIf="this.isOptionBIncludesFreeTransfers">
                    </p>
                    <ul>
                        <li *ngIf="this.cruise">{{this.cruise.availability[this.cruise.iterIndex].length}} Itinerary

                            <i>({{this.cruise.availability[this.cruise.iterIndex].promotionLabel}})</i>
                            <ul>
                                <b>Includes:</b>
                                <li>All meals during the cruise</li>
                                <li>Transfers between the islands</li>
                                <li>Excursions</li>
                                <li>Non-alcoholic drinks</li>
                            </ul>
                        </li>
                        <li *ngIf="this.isOptionBIncludesFreeTransfers"><strong>FREE</strong> airport transfers for
                            Mainland - Galapagos flights</li>
                        <li *ngIf="this.isOptionBIncludesFreeTransfers"><strong>FREE</strong> Hotel accommodation in
                            Quito or Guayaquil the night before the cruise</li>
                    </ul>
                    <a *ngIf="!this.isShowOptionB" href="javascript:void(0)" class="next" (click)="toggleCabins('B')"
                        (click)="select()" [ngStyle]="{'opacity':this.isShowOptionB? '1': '0.5'}">Select</a>
                    <a *ngIf="this.isShowOptionB" href="javascript:void(0)" class="next" (click)="toggleCabins('B')"
                        (click)="selected()" [ngStyle]="{'opacity':this.isShowOptionB? '1': '0.5'}">Selected</a>
                </div>
            </div>
        </div>
    </div>
    <div id="cabins">
        <aside class="pt1">
            <h3
                [ngStyle]="{ 'color':  this.companyParamsService.getCompanyParams() == undefined ?('black'): ('#'+this.companyParamsService.getSecundaryColor()) }">
                Cabin Selection
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
                    class="bi bi-caret-right-fill"
                    [ngStyle]="{ 'color':  this.companyParamsService.getCompanyParams() == undefined ?('black'): ('#'+this.companyParamsService.getSecundaryColor()) }"
                    viewBox="0 0 16 16">
                    <path
                        d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                </svg>
            </h3>
        </aside>
        <div class="modules-ecomm">
            <div class="headmod" id="idHeader"
                [ngStyle]="{ 'background-color':  this.companyParamsService.getCompanyParams() == undefined ?('white'): ('#'+this.companyParamsService.getMainColor()) }">
                Select cabin(s) and accommodation type</div>
            <div class="mod-content cabs" id="cabinsOptions"
                [ngStyle]="{'hidden':this.isShowOptionB||this.isShowOptionB? false: true}">
                <div *ngIf="this.isShowOptionB || this.isShowOptionA">
                    <h4>Please choose type of cabin and bed. Cabins required: <b class="cabs">{{this.requestCabins}}</b>
                    </h4>
                    <br>
                    <div class="cabin-select">
                        <figure *ngFor="let item of this.cabinasItinerarioMenorWrapper" (click)="onSelectCabin(item)"
                            [ngStyle]="{'opacity': item.getIsSelected()? '1': '0.5'}">
                            <div class="check" [ngStyle]="{'color': !item.getIsSelected()? '#ccc': 'green'}">✔</div>
                            <img [src]="this.getPictureCabin(item.getFotoCabinaListImageNames()[0])">
                            <figcaption *ngIf="this.isShowOptionA">
                                <p><strong>{{item.getCabinaFullName()}}</strong></p>
                                <b>Capacity:</b> {{item.getCapacidad()}} guests
                                <br><span>Price:</span>
                                <u *ngIf="this.hasPromo">(US$ {{getFloor(item.getTarifa()+getAdultAirFare())|
                                    number:'3.0-0' }})</u> US$
                                {{getFloor(item.getValorTarifaPromocion())+getAdultAirFare()| number:'3.0-0' }} p/person
                            </figcaption>
                            <figcaption *ngIf="!this.isShowOptionA">
                                <p><strong>{{item.getCabinaFullName()}}</strong></p>
                                <b>Capacity:</b> {{item.getCapacidad()}} guests
                                <br><span>Price:</span>
                                <u *ngIf="this.hasPromo">(US$ {{getFloor(item.getTarifa())}})</u> US$
                                {{getFloor(item.getValorTarifaPromocion())| number:'3.0-0' }} p/person
                            </figcaption>
                        </figure>

                    </div>
                    <a *ngIf="this.isValidCabin" (click)="cabinSelection()" href="javascript:void(0)" class="next"
                        id="pack">Next</a>
                    <a *ngIf="!this.isValidCabin" (click)="cabinSelection()" href="javascript:void(0)"
                        class="next inactive" id="pack">Next</a>
                </div>
            </div>
        </div>
    </div>
    <div id="passengers">
        <aside class="pt1">
            <h3
                [ngStyle]="{ 'color':  this.companyParamsService.getCompanyParams() == undefined ?('black'): ('#'+this.companyParamsService.getSecundaryColor()) }">
                Number of Passengers
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor"
                    class="bi bi-caret-right-fill"
                    [ngStyle]="{ 'color':  this.companyParamsService.getCompanyParams() == undefined ?('black'): ('#'+this.companyParamsService.getSecundaryColor()) }"
                    viewBox="0 0 16 16">
                    <path
                        d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
                </svg>
            </h3>
        </aside>
        <div class="modules-ecomm">
            <div class="headmod"
                [ngStyle]="{ 'background-color':  this.companyParamsService.getCompanyParams() == undefined ?('white'): ('#'+this.companyParamsService.getMainColor()) }"
                id="idHeader">Confirm number of passengers and bed type</div>
            <div class="mod-content pax" id="passengersOptions">
                <div *ngIf="(this.isShowOptionB || this.isShowOptionA)&&this.cabinsSelected.length>0 ">
                    <div class="edit-cabin" *ngFor="let item of this.cabinsSelected; let i = index">
                        <div class="cabselect">
                            <h2>{{ ( i + 1 ) | cabinOrdinalName }}:</h2>
                            <p *ngIf="this.isShowOptionA">
                                <strong>• Type: </strong>{{item.getCabinaFullName()}}
                                <br>
                                <strong>• Capacity:</strong> 2 guests
                                <br>
                                <strong>• Price:</strong> US$
                                {{(getFloor(item.getValorTarifaPromocion())+getAdultAirFare())| number:'3.0-0'
                                }}p/person
                            </p>
                            <p *ngIf="!this.isShowOptionA">
                                <strong>• Type: </strong>{{item.getCabinaFullName()}}
                                <br>
                                <strong>• Capacity:</strong> 2 guests
                                <br>
                                <strong>• Price:</strong> US$ {{getFloor(item.getValorTarifaPromocion())| number:'3.0-0'
                                }}p/person
                            </p>
                        </div>
                        <form method="post">
                            <div class="numbers-row">
                                <label>Adults*</label>
                                <input type="text" *ngIf="this.passengers[i]" id="paxs" [value]="this.passengers[i]"
                                    disabled="">
                                <input type="text" *ngIf="!this.passengers[i]" id="paxs" [value]="1" disabled="">
                                <div class="dec button" (click)="onClickDecr(this.passengers[i], true, i)">-</div>
                                <div class="inc button" (click)="onClickIncr(this.passengers[i], true, i)">+</div>
                            </div>
                            <div class="numbers-row" *ngIf="this.disableChildren">
                                <label>Children*</label>
                                <input type="text" *ngIf="this.children[i]" id="paxs" [value]="this.children[i]"
                                    disabled="">
                                <input type="text" *ngIf="!this.children[i]" id="paxs" [value]="0" disabled="">
                                <div class="dec button" (click)="onClickDecr(this.children[i], false, i)">-</div>
                                <div class="inc button" (click)="onClickIncr(this.children[i], false, i)">+</div>
                            </div>
                            <div class="edit-cabin bdtype-row" id="bdtype-row" style="padding-bottom: 0px;">
                                <label>Bed type:</label>
                                <select [(ngModel)]="this.bedTypesSelected[i]" name="bdtype-row"
                                    (change)="this.onselectBed(i)">
                                    <option *ngFor="let bed of item.getCamaCabinaList() ;  let index = index "
                                        [ngValue]="index">
                                        {{bed.description}}</option>
                                </select>
                            </div>
                        </form>
                    </div>
                    <p class="note" *ngIf="this.cruise">*Age from {{this.cruise.minimumAgeChild}} to
                        {{this.cruise.maximumAgeChild}} years old. Children airfare may apply.
                    </p>
                    <a class="{{this.classNextPassenger}}" href="javascript:void(0)" (click)="passengersSelection()"
                        id="acco">Next</a>
                </div>
            </div>
        </div>
    </div>
    <div id="summaryFinal">
        <div class="summary" [ngStyle]="{'display':!this.isValidSummarFinal? 'none': 'block'}">
            <h2>Book Summary</h2>
            <table width="100%">
                <tbody>
                    <tr>
                        <td colspan="2" class="summ">
                            <h4><span><strong>

                                    </strong>
                                </span> Super Savings Package</h4>
                            <ul>
                                <li *ngIf="this.cruise">{{this.cruise.availability[this.cruise.iterIndex].length}}-
                                    Itinerary
                                    <i>({{this.cruise.availability[this.cruise.iterIndex].promotionLabel}})</i>
                                    <ul>
                                        <b>Includes:</b>
                                        <li>All meals during the cruise</li>
                                        <li>Transfers between the islands</li>
                                        <li>Excursions</li>
                                        <li>Non-alcoholic drinks</li>
                                    </ul>
                                </li>
                                <div *ngIf="this.isShowOptionA || this.includeGalapagosAirTickets">
                                    <li>Cruise Flight Tickets*</li>

                                    <div
                                        *ngIf="this.cruise.availability[this.itineraryIndex]?.aditionalServices?.length">
                                        <li
                                            *ngFor="let aServices of this.cruise.availability[this.itineraryIndex].aditionalServices">
                                            <!--
                       <strong style="color: #a30000;">FREE</strong>
                     -->
                                            <p [innerHTML]="hasTextFree(aServices)"></p>
                                        </li>
                                    </div>
                                </div>

                            </ul>
                            <p style="margin-left: 15px;margin-bottom: 4px !important;"
                                *ngIf="this.isShowOptionA || this.includeGalapagosAirTickets ">
                                <span _ngcontent-c13="">* Flights are subject to availability</span> <br>
                            </p>
                            <p style="margin-left: 15px;"
                                *ngIf="(this.isShowOptionA &&
                this.cruise.availability[itineraryIndex].aditionalServices.length>0) || (
                 this.includeGalapagosAirTickets&&this.cruise.availability[itineraryIndex].aditionalServices.length>0)">
                                <span _ngcontent-c13="">** Free services apply only when the flights
                                    are on the same dates of the
                                    cruise.</span>
                            </p>
                            <h4><span><strong>
                                        Accommodation:
                                    </strong>
                                </span> </h4>
                            <ul>
                                <ng-template ngFor let-itemCabin [ngForOf]="this.cabinsSelected" let-i="index">
                                    <li>{{ ( i + 1 ) | cabinOrdinalName }}
                                        <span>{{itemCabin.getCabinaFullName()}}</span>
                                    </li>
                                    <li>Cabin Bed Type:
                                        <span>{{itemCabin.getCamaCabinaList()[this.bedTypesSelected[i]]?.description}}</span>
                                    </li>
                                </ng-template>
                            </ul>
                        </td>
                    </tr>
                    <ng-template ngFor let-itemPassengerArray [ngForOf]="this.purchaseCabinPassengers" let-i="index">
                        <tr *ngFor="let itemPassenger of this.itemPassengerArray; let z = index">
                            <th>Passenger {{ ( z | transform2dArrayIndex:i:this.purchaseAirTicketDetail ) }}:
                                {{itemPassenger.type}}
                            </th>
                            <td>US$ {{itemPassenger.price| number:'3.0-0' }}</td>
                        </tr>
                    </ng-template>

                </tbody>
            </table>

            <div class="airtkt" *ngIf="this.isShowOptionB && this.isAirTicketsView()">
                <h4> {{this.company.airTicketsTitle}}</h4>
                <!--div style="white-space: pre-line;"-->
                <!-- p style="text-align: justify;">{{this.company.airTicketsContent}}</!-->
                <div>
                    <span [innerHTML]="this.company.airTicketsContent"></span>
                </div>
                <div style="text-align: center">
                    <label>
                        <input type="checkbox" [checked]="this.includeGalapagosAirTickets"
                            (change)="this.toggleIncludeGalapagosAirTickets()">
                        {{this.company.airTicketsConditions}}</label>
                </div>
            </div>
            <table width="100%" [@openClose]="includeGalapagosAirTickets ? 'open' : 'closed'">
                <tbody>
                    <ng-template ngFor let-itemPassengerArray [ngForOf]="this.purchaseCabinPassengers" let-i="index"
                        *ngIf="this.includeGalapagosAirTickets && this.isShowOptionB">
                        <tr class="tkt-hide" style="display: table-row;"
                            *ngFor="let itemPassenger of this.itemPassengerArray; let z = index">
                            <th>Ticket ({{itemPassenger.type}}) {{ ( z |
                                transform2dArrayIndex:i:this.purchaseAirTicketDetail ) }} :
                            </th>
                            <td *ngIf="itemPassenger.type==='Adult'">US$ {{getAdultAirFare()| number:'3.0-0' }}</td>
                            <td *ngIf="itemPassenger.type==='Child'">US$ {{getChildrenAirFare()| number:'3.0-0' }}</td>
                        </tr>
                    </ng-template>
                    <tr>
                        <th class="total"
                            [ngStyle]="{ 'background-color':  this.companyParamsService.getCompanyParams() == undefined ?('white'): ('#'+this.companyParamsService.getMainColor()) }">
                            Total price:</th>
                        <td class="price">US$ {{totalPurchase| number:'3.0-0' }}</td>
                    </tr>
                </tbody>
            </table>
            <table width="100%" [@openClose]="!includeGalapagosAirTickets ? 'open' : 'closed'">
                <tbody>
                    <tr>
                        <th class="total"
                            [ngStyle]="{ 'background-color':  this.companyParamsService.getCompanyParams() == undefined ?('white'): ('#'+this.companyParamsService.getMainColor()) }">
                            Total price:</th>
                        <td class="price">US$ {{totalPurchase| number:'3.0-0' }}</td>
                    </tr>
                </tbody>
            </table>

            <a (click)="this.reset()" href="javascript:void(0)" class="reset">[Reset options]</a>
            <a (click)="this.nextSecondPart()" href="javascript:void(0)" class="lngnext">Continue</a>
        </div>
    </div>
    <app-end-slide [firstString]="this.activeEndSlide" [numberPage]="1"
        *ngIf="this.cruise !== undefined"></app-end-slide>
</div>
<app-share-modal [isOpenShareDialog]="isOpenShareDialog" [urlShare]="urlShare"
    (eventChangeVisibility)="isOpenShareDialog=false">
</app-share-modal>